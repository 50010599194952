import React from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { useTranslation } from "react-i18next";
import { Navbarr, AccountMenu, Footer } from "../../../components";
import styles from "../../../styles/Account.module.css";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import userPrefService from "../../../services/user-preferences";

const ChangePass = () => {
  useDocumentTitle("Change Password");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const { t, i18n } = useTranslation();

  // const Field = ({ label, type, placeholder, htmlFor }) => (
  //   <div className={styles.addNew_input}>
  //     <div className={styles.addNew_label} htmlFor={htmlFor}>
  //       {label}
  //     </div>
  //     <input placeholder={placeholder} type={type} id={type} />
  //   </div>
  // );
  const formik = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    onSubmit: (values) => {
      const { old_password, password, confirm_password } = values;
      setIsLoading(true);
      console.log(values);

      userPrefService
        .changePassword({ old_password, password, confirm_password })
        .then((res) => {
          setIsLoading(false);
          console.log(res);
          setMessage(res.message);
          console.log(res.message);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    },
    validationSchema: Yup.object().shape({
      old_password: Yup.string().required(
        document.body.dir === "rtl"
          ? "كلمة المرور القديمة مطلوبة"
          : "Old Password is required"
      ),
      password: Yup.string().required(
        document.body.dir === "rtl"
          ? "كلمة المرور مطلوبة"
          : "Password is required"
      ),
      confirm_password: Yup.string()
        .required(
          document.body.dir === "rtl"
            ? "تأكيد كلمة المرور مطلوب"
            : "Confirm Password is required"
        )
        .oneOf(
          [Yup.ref("password"), null],
          document.body.dir === "rtl"
            ? "كلمة المرور غير متطابقة"
            : "Passwords don't match"
        ),
    }),
    enableReinitialize: true,
  });
  const { old_password, password, confirm_password } = formik.values;

  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.account_main}>
        <AccountMenu active={"Account Settings"} />
        <form
          onSubmit={formik.handleSubmit}
          className={styles.account_body}
          style={{ width: "48%" }}
        >
          <div className={styles.account_body}>
            <div className={styles.account_body_title}>
              <h1>{t("Change Password")}</h1>
            </div>
            <div className={styles.settings_content}>
              <div className={styles.settings_content__fields}>
                <div className={styles.addNew_input}>
                  <div
                    className={styles.addNew_label}
                    htmlFor="currentPassword"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("Current Password")}
                  </div>
                  <input
                    placeholder=""
                    type="password"
                    id="old_password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={old_password}
                    style={{ width: "unset" }}
                  />
                  {formik.touched.current_password &&
                    formik.errors.current_password && (
                      <div className={styles.error}>
                        {formik.errors.current_password}
                      </div>
                    )}
                </div>

                <div className={styles.addNew_input}>
                  <div
                    className={styles.addNew_label}
                    htmlFor="newPassword"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("New Password")}
                  </div>
                  <input
                    placeholder=""
                    type="password"
                    id="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={password}
                    style={{ width: "unset" }}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className={styles.error}>{formik.errors.password}</div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.settings_content}>
              <div
                className={styles.settings_content__fields}
                style={{ width: "48%" }}
              >
                <div className={styles.addNew_input}>
                  <div
                    className={styles.addNew_label}
                    htmlFor="confirmNewPassword"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("Confirm New Password")}
                  </div>
                  <input
                    placeholder=""
                    type="password"
                    id="confirm_password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={confirm_password}
                    style={{ width: "unset" }}
                  />
                  {formik.touched.confirm_password &&
                    formik.errors.confirm_password && (
                      <div className={styles.error}>
                        {formik.errors.confirm_password}
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className={styles.addNew_fields__content__buttons}>
              <button
                type="submit"
                className={styles.addNewCard}
                disabled={isLoading}
                onClick={formik.handleSubmit}
                style={{ margin: "0", whiteSpace: "nowrap" }}
              >
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <div className={styles.addNewCard_Btn__Text}>
                    {t("Save password")}
                  </div>
                )}
              </button>
              <div
                className={styles.addNewCardCancel}
                onClick={() => {
                  //go back and don't submit the form
                  navigate(-1);

                  console.log("cancel");
                }}
                style={{
                  cursor: "pointer",
                  color: "#000",
                  border: "unset",
                  fontWeight: "bold",
                }}
              >
                {t("Cancel")}
              </div>
            </div>
            {message ? (
              <Box sx={{ width: "50%", marginTop: "1rem" }}>
                <Collapse in={open}>
                  <Alert
                    severity={message.includes("Success") ? "success" : "error"}
                    sx={{ mb: 2 }}
                  >
                    {message}
                  </Alert>
                </Collapse>
              </Box>
            ) : null}
          </div>
        </form>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ChangePass;
