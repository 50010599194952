import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { Footer, Navbarr } from "../../components";
import { baseUrl } from "../../constants/apiURL";
import { useTranslation } from "react-i18next";
import { forgotPswrdValidationSchema } from "../../Validations/forgotPswrdValidation";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";

import styles from "./../../styles/SignInSignUp.module.css";

const ForgotPassword = () => {
  const config = JSON?.parse(localStorage.getItem("config"));

  useDocumentTitle("Forgot Password");
  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [verify, setVerify] = useState(false);
  const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      rtl: true,
    });
  };

  const formikForgotPswrd = useFormik({
    initialValues: {
      email: "",
      phone: "",
    },
    validationSchema: yup.object().shape({
      [config?.enablePhoneLogin ? "phone" : "email"]: config?.enablePhoneLogin
        ? yup
            .string()
            .typeError(
              document.body.dir === "rtl"
                ? "يجب أن يكون الهاتف رقمًا"
                : "Phone must be a number"
            )
            //matches regex for phone 10 digits or 11 digits
            .matches(/^\d+$/, {
              message:
                document.body.dir === "rtl"
                  ? "يجب أن يكون الهاتف رقمًا"
                  : "Phone must be a number",
            })
            // the number must be 10 or 11 digits long without hyphens
            .required(
              document.body.dir === "rtl"
                ? "يجب إدخال رقم الهاتف"
                : "Phone is required"
            )
            .min(
              10,
              document.body.dir === "rtl"
                ? "يجب أن يتكون الهاتف من 10 رقمًا"
                : "Phone must be 10 digits long"
            )
            .max(
              11,
              document.body.dir === "rtl"
                ? "يجب أن يتكون الهاتف من 11 رقمًا"
                : "Phone must be 11 digits long"
            )
            .matches(/^[0-9]{10,11}$/, {
              message:
                document.body.dir === "rtl"
                  ? "يجب أن يكون الهاتف رقمًا"
                  : "Phone must be a number",
            })
            .required(
              document.body.dir === "rtl" ? "الهاتف مطلوب" : "Phone is required"
            )
        : yup
            .string()
            .required(
              document.body.dir === "rtl"
                ? "البريد الالكتروني مطلوب"
                : "Email is required"
            )
            //matches regex for email
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              document.body.dir === "rtl"
                ? "يجب أن يكون البريد الإلكتروني صالحًا"
                : "Must be a valid email"
            )
            .email(
              document.body.dir === "rtl"
                ? "يجب أن يكون البريد الإلكتروني صالحًا"
                : "Must be a valid email"
            ),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const { email, phone } = values;
      setEmail(email);
      setPhone(phone);
      axios
        .post(`${baseUrl}/auth/forget_password_v2`, { email, phone })
        .then((res) => {
          console.log(res);
          setLoading(false);
          if (res?.data?.code === 200) {
            setVerify(true);
          } else {
            errorToast(res?.data?.message);
          }

          setMsg(res.message);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setVerify(false);
        });
    },
  });
  if (verify) {
    return (
      <>
        <form onSubmit={formikForgotPswrd.handleSubmit}>
          <div className={styles.forgotPasswordContainer}>
            <div className={styles.forgotPasswordContainer_header}>
              <h2 className={styles.forgotPasswordContainer_title}>
                {t("Forgot Password")}
              </h2>
              <div
                style={{ marginBottom: "16vh" }}
                className={styles.forgotPasswordContainer_desc}
              >
                {msg ? msg : ""} {t("please check your")}{" "}
                {config?.enablePhoneLogin ? t("Phone") : t("Email")}{" "}
                {t("for a link to reset your password")}.
              </div>
            </div>
          </div>
        </form>
      </>
    );
  }
  return (
    <>
      {/* <Navbarr /> */}
      <form onSubmit={formikForgotPswrd.handleSubmit}>
        <div className={styles.forgotPasswordContainer}>
          <div className={styles.forgotPasswordContainer_header}>
            <h2 className={styles.forgotPasswordContainer_title}>
              {t("Forgot Password")}
            </h2>
            <div className={styles.forgotPasswordContainer_desc}>
              {t("Please enter your")}{" "}
              {config?.enablePhoneLogin ? t("Phone") : t("Email")}.{" "}
              {t("You will receive a link to create a new password via email")}.
            </div>
          </div>
          <div className={styles.SignForm_input}>
            <div className={styles.SignForm_label}>
              {config?.enablePhoneLogin ? t("Phone") : t("Email")}
            </div>
            <input
              style={{ padding: "1vh 1vw 1vh 2vw", width: "100%" }}
              ref={emailRef}
              placeholder={
                config?.enablePhoneLogin ? "01XXXXXXXX" : "Example@email.com"
              }
              type={config?.enablePhoneLogin ? "number" : "email"}
              id={config?.enablePhoneLogin ? "phone" : "email"}
              onChange={formikForgotPswrd.handleChange}
              value={
                config?.enablePhoneLogin
                  ? formikForgotPswrd.values.phone
                  : formikForgotPswrd.values.email
              }
              onBlur={formikForgotPswrd.handleBlur}
            />
            {config?.enablePhoneLogin
              ? formikForgotPswrd.touched.phone &&
                formikForgotPswrd.errors.phone && (
                  <div style={{ color: "red" }}>
                    {formikForgotPswrd.errors.phone}
                  </div>
                )
              : formikForgotPswrd.touched.email &&
                formikForgotPswrd.errors.email && (
                  <div style={{ color: "red" }}>
                    {formikForgotPswrd.errors.email}
                  </div>
                )}
            <div className={styles.SignForm_button}>
              <button type="submit" style={{ marginTop: "5vh" }}>
                {loading ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  <span>{t("Send Reset Email")}</span>
                )}
              </button>
              <ToastContainer closeButton={false} />
            </div>
          </div>
        </div>
      </form>
      {/* <Footer /> */}
    </>
  );
};

export default ForgotPassword;
