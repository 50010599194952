import * as yup from "yup";
const config = JSON?.parse(localStorage.getItem("config"));

export const forgotPswrdValidationSchema = yup.object().shape({
  [config?.enablePhoneLogin ? "phone" : "email"]: config?.enablePhoneLogin
    ? yup
        .number()
        .required(document.body.dir === "rtl" ? "مطلوب" : "Required")
        .min(
          11,
          document.body.dir === "rtl"
            ? "يجب أن يتكون الهاتف من 11 رقمًا"
            : "Phone must be 11 digits long"
        )
        .typeError(
          document.body.dir === "rtl"
            ? "يجب أن يكون الهاتف رقمًا"
            : "Phone must be a number"
        )
    : yup
        .string()
        .required(
          document.body.dir === "rtl"
            ? "البريد الالكتروني مطلوب"
            : "Email is required"
        )
        //matches regex for email
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          document.body.dir === "rtl"
            ? "يجب أن يكون البريد الإلكتروني صالحًا"
            : "Must be a valid email"
        )
        .email(
          document.body.dir === "rtl"
            ? "يجب أن يكون البريد الإلكتروني صالحًا"
            : "Must be a valid email"
        ),
});
