import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import styles from "../../styles/Account.module.css";
import {
  Navbarr,
  Footer,
  AccountMenu,
  OrderRecipt,
  AddressBox,
} from "../../components";

import userPrefService from "../../services/user-preferences";
import { useTranslation } from "react-i18next";
import edit from "../../assets/edit.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import arrow from "../../assets/arrow.svg";
import { Skeleton } from "@mui/material";
import useScrollTop from "../../hooks/useScrollTop";

const AccountOverview = () => {
  useDocumentTitle("Account Overview");
  useScrollTop();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { addresses, profile } = useSelector((state) => state.init);
  console.log(addresses);

  const [address, setAddress] = React.useState([]);
  const [order, setOrder] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  useEffect(() => {
    userPrefService
      .getOrders(1, profile?.phone)
      .then((res) => {
        console.log(res.order);
        setOrder(res.order);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(order);

  useEffect(() => {
    userPrefService
      .getAddress()
      .then((res) => {
        console.log(res);
        setAddress(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(address);

  //get the last item in array and map over it
  const lastOrder = order.length > 0 ? order[0] : {};

  console.log(lastOrder.items);
  // setInit(localStorage.getItem("init"));
  const init = JSON.parse(localStorage.getItem("init"));
  // console.log(JSON.parse(localStorage.getItem("init")).data.profile.addresses);

  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.account_main}>
        <AccountMenu active={"Overview"} />
        <div className={styles.account_body}>
          <div className={styles.account_body_title}>
            <h1>{t("Account Overview")}</h1>
            <div className={styles.account_overview_body_content}>
              <div className={styles.account_overview_body_content_block}>
                <div
                  className={styles.account_overview_body_content_block__title}
                >
                  {t("Account Details")}
                </div>
                <div
                  className={
                    styles.account_overview_body_content_block__content
                  }
                >
                  <div
                    className={
                      styles.account_overview_body_content_block__content_data
                    }
                  >
                    <div
                      className={
                        styles.account_overview_body_content_block__content_data_label
                      }
                    >
                      {t("Name")}:
                    </div>
                    <span
                      className={
                        styles.account_overview_body_content_block__content_data_value
                      }
                    >
                      {profile?.is_guest === false ? profile?.name : null}
                    </span>
                  </div>
                  <div
                    className={
                      styles.account_overview_body_content_block__content_data
                    }
                  >
                    <div
                      className={
                        styles.account_overview_body_content_block__content_data_label
                      }
                    >
                      {t("Email")}
                    </div>
                    <span
                      className={
                        styles.account_overview_body_content_block__content_data_value
                      }
                    >
                      {profile?.is_guest === false ? profile?.email : null}
                    </span>
                  </div>
                </div>
                <Link
                  to="/customer/account/settings"
                  className={
                    styles.account_overview_body_content_block__email_btn
                  }
                >
                  <img src={edit} alt="edit Address" />
                  <span
                    className={
                      styles.account_overview_body_content_block__email_btn_text
                    }
                  >
                    {t("Edit Account")}
                  </span>
                </Link>
              </div>
              <div className={styles.account_overview_body_content_block}>
                <div
                  className={styles.account_overview_body_content_block__title}
                >
                  {t("Latest Order")}
                </div>
                <div
                  className={
                    styles.account_overview_body_content_block__content
                  }
                  style={{ gridTemplateColumns: "unset" }}
                >
                  {loading ? (
                    <div>
                      <Skeleton
                        variant="rect"
                        width={800}
                        height={200}
                        animation="wave"
                        style={{ borderRadius: "20px" }}
                      />
                    </div>
                  ) : order.length === 0 ? (
                    <div
                      className={
                        styles.account_overview_body_content_block__content_empty
                      }
                    >
                      {t("no_orders_message")}
                    </div>
                  ) : (
                    <OrderRecipt
                      id={lastOrder?.id}
                      product_id={lastOrder?.items.map((item) => item.id)}
                      SingleOrder={lastOrder?.items}
                      status={
                        document.body.dir === "rtl"
                          ? lastOrder?.state.name_ar
                          : lastOrder?.state.name
                      }
                      key={lastOrder?.id}
                      price={lastOrder?.total}
                      date={lastOrder?.date}
                      customer={
                        lastOrder?.address.customer_first_name
                          ? lastOrder?.address.customer_first_name
                          : lastOrder?.user.name
                      }
                      orderNumber={lastOrder?.id}
                      status_id={lastOrder?.state_id}
                      canceled={true}
                    />
                  )}
                </div>
                <Link
                  to="/productslist"
                  className={styles.account_overview_body_content_block__btn}
                >
                  <span
                    className={
                      styles.account_overview_body_content_block__btn_text
                    }
                  >
                    {t("Browse products")}
                  </span>
                  <img
                    src={arrow}
                    alt="To shopping"
                    style={
                      document.body.dir === "rtl"
                        ? { transform: "rotate(180deg)" }
                        : null
                    }
                  />
                </Link>
              </div>
              <div className={styles.account_overview_body_content_block}>
                <div
                  className={styles.account_overview_body_content_block__title}
                >
                  {t("Saved Addresses")}
                </div>
                <div
                  className={
                    styles.account_overview_body_content_block__content
                  }
                >
                  {addresses && addresses.length === 0 ? (
                    <div
                      className={
                        styles.account_overview_body_content_block__content_empty
                      }
                    >
                      {t("no_addresses_message")}
                    </div>
                  ) : (
                    addresses.map((address, index) => (
                      <AddressBox
                        key={index}
                        address={address.formatted_address}
                        phone={address.phone ? address.phone : "unset"}
                        name={
                          address.customer_first_name
                            ? address.customer_first_name
                            : "unset"
                        }
                        address_id={address.id}
                      />
                    ))
                  )}
                </div>
                <Link
                  to="/customer/addresses/addnew"
                  className={styles.account_overview_body_content_block__btn}
                >
                  <span
                    className={
                      styles.account_overview_body_content_block__btn_text
                    }
                  >
                    {t("Add New Addresses")}
                  </span>
                  <img
                    src={arrow}
                    alt="To shopping"
                    style={
                      document.body.dir === "rtl"
                        ? { transform: "rotate(180deg)" }
                        : {}
                    }
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AccountOverview;
