import React from "react";
import { Footer, Navbarr, SignInSignUp } from "../../components";
import  useDocumentTitle  from "../../hooks/useDocumentTitle";


const SignUp = () => {
  
  useDocumentTitle('Sign Up')
  return (
    <>
      {/* <Navbarr /> */}
      <div className='signup-styles' style={{backgroundColor:"#f8f8f6",padding:"2vh 20vw"}}>
        <SignInSignUp />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SignUp;
