import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

import axios from "axios";
import styles from "../styles/Footer.module.css";
import logo_1 from "../assets/logo-footer.svg";

import {
  filterByCategoryId,
  resetPageNumber,
  resetTotalFilteredProducts,
} from "../store/products/productsSlice";
import { fetchProducts } from "../services/products.service";
import { Skeleton } from "@mui/material";
import userPrefService from "../services/user-preferences";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import useScrollTop from "../hooks/useScrollTop";
import { FaFacebook } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import AppStore from "../assets/app-store-logo.svg";
import PlayStore from "../assets/google-play-logo.svg";

const Footer = () => {
  const [isLoadingPages, setIsLoadingPages] = useState(true);
  const { t, i18n } = useTranslation();
  useScrollTop();
  const [error, setError] = useState(null);
  const [pages, setPages] = useState([]);
  const [newsLetterLoading, setNewsLetterLoading] = useState(false);
  const [newsLetterMessage, setNewsLetterMessage] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const config = JSON?.parse(localStorage.getItem("config"));
  // const [ footerData, setFooterData ] = useState([]);

  const { categories, isLoading } = useSelector((state) => state.init);
  useEffect(() => {
    setIsLoadingPages(true);
    userPrefService.GetFooter().then((res) => {
      setPages(res);
      setIsLoadingPages(false);
    });
  }, []);
  const signUpFooter = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      const { email } = values;
      setNewsLetterLoading(true);
      userPrefService
        .newsLetter({ email: email })
        .then((res) => {
          console.log(res);
          setNewsLetterLoading(false);
          setNewsLetterMessage(res);
          console.log(values);
        })
        .catch((err) => {
          setNewsLetterLoading(false);
          setNewsLetterMessage(err.response);
        });
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(
          document.body.dir === "rtl"
            ? "البريد الالكتروني مطلوب"
            : "Email is required"
        )
        //matches regex for email
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          document.body.dir === "rtl"
            ? "البريد الالكتروني غير صحيح"
            : "Invalid email address"
        )
        .email(
          document.body.dir === "rtl"
            ? "البريد الالكتروني غير صحيح"
            : "Invalid email address"
        ),
    }),
  });

  const handleClickCategory = (id) => {
    dispatch(filterByCategoryId(id));
    dispatch(resetPageNumber());
    dispatch(resetTotalFilteredProducts());

    dispatch(fetchProducts(1, {}, id, {}, {}, {}, 12));
    console.log(id);
    navigate({
      pathname: "/products",
      search: `?category_id=${id}`,
    });
  };

  return (
    <>
      <footer className={styles.Footer}>
        <div className={styles.Footer_container}>
          <form onSubmit={signUpFooter.handleSubmit}>
            <div className={styles.Footer_1}>
              <div className={styles.Footer_1_logo}>
                <img src={logo_1} alt="" style={{ mixBlendMode: "darken" }} />
              </div>
              <div className={styles.Footer_1_signUP}>
                <input
                  placeholder={t("email_placeholder")}
                  type="email"
                  id="email"
                  name="email"
                  className={styles.Footer_1_input}
                  onChange={signUpFooter.handleChange}
                  onBlur={signUpFooter.handleBlur}
                  value={signUpFooter.values.email}
                  style={
                    document.body.dir === "rtl"
                      ? {
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }
                      : {
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }
                  }
                />

                <button
                  type="submit"
                  className={styles.Footer_1_btn}
                  disabled={newsLetterLoading}
                  style={
                    document.body.dir === "rtl"
                      ? {
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }
                      : {
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }
                  }
                >
                  {newsLetterLoading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : newsLetterMessage?.code === 200 ? (
                    t("subscribed")
                  ) : //if response is anything of 400ish
                  newsLetterMessage?.code >= 400 ? (
                    t("Error")
                  ) : (
                    t("Subscribe")
                  )}
                </button>
              </div>
              {signUpFooter.touched.email && signUpFooter.errors.email && (
                <div className={styles.error} style={{ color: "red" }}>
                  {signUpFooter.errors.email}
                </div>
              )}
              <div className={styles.Footer_1_contact}>{t("Contact Us")}</div>
              <div className={styles.Footer_1_contact_data}>
                {t("company_address")}
              </div>
              <div className={styles.Footer_1_social__media}>
                <div
                  className={styles.Footer_1_social__media_icons}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <FaFacebook
                    onClick={() => {
                      window.location.href = config?.SOCIAL_URL_FACEBOOK;
                    }}
                    className={styles.Footer_1_social__media_icon}
                  />
                  <FaTwitter
                    onClick={() => {
                      window.location.href = config?.SOCIAL_URL_TWITTER;
                    }}
                    className={styles.Footer_1_social__media_icon}
                  />
                  <FaInstagram
                    onClick={() => {
                      window.location.href = config?.SOCIAL_URL_INSTAGRAM;
                    }}
                    className={styles.Footer_1_social__media_icon}
                  />
                </div>
              </div>
            </div>
          </form>
          {isLoadingPages
            ? [1, 2, 3, 4].map((index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  animation="wave"
                  width={200}
                  height={30}
                  style={{
                    borderRadius: 20,
                    marginBottom: "3px",
                    marginTop: "4vh",
                  }}
                  sx={{ bgcolor: "grey.400" }}
                />
              ))
            : pages && (
                <>
                  <div className={styles.Footer_static}>
                    {pages.map((footerPage) => (
                      <div
                        key={footerPage.id}
                        className={styles.Footer_2}
                        onClick={() =>
                          navigate(`/static-pages/${footerPage.slug}`)
                        }
                      >
                        <div className={styles.Footer_2_data_item}>
                          {document.body.dir === "rtl"
                            ? footerPage.title_ar
                            : footerPage.title_en}
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* <div className={styles.Footer_2_mobileAppsLinks}>
                    <div className={styles.Footer_2_mobileAppsLinks_data}>
                      <div
                        className={styles.Footer_2_mobileAppsLinks_data_item}
                      >
                        <a
                          href={config?.APP_STORE_LINK}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={AppStore}
                            alt="App Store"
                            className={
                              styles.Footer_2_mobileAppsLinks_data_item_img
                            }
                          />
                        </a>
                      </div>
                      <div
                        className={styles.Footer_2_mobileAppsLinks_data_item}
                      >
                        <a
                          href={config?.PLAY_STORE_LINK}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={PlayStore}
                            alt="Play Store"
                            className={
                              styles.Footer_2_mobileAppsLinks_data_item_img
                            }
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.Footer_2_rights}>
                    <div className={styles.Footer_2_rights_data}>
                      {t("All rights reserved")} ©{" "}
                      {document.body.dir === "rtl"
                        ? config?.APP_NAME_AR
                        : config?.APP_NAME}{" "}
                      2022 -{" "}
                      {document.body.dir === "rtl"
                        ? config?.APP_NAME_AR
                        : config?.APP_NAME}
                    </div>
                  </div> */}
                </>
              )}
        </div>
      </footer>
    </>
  );
};

export default Footer;
