import { configureStore } from '@reduxjs/toolkit'

import authReducer from "../auth/authSlice";
import messageReducer from "../auth/message";
import productsReducer from "../store/products/productsSlice";
import cartReducer from "../store/cart/cartSlice";
import wishlistReducer from "../store/wishlist/wishlistSlice";
import initReducer from './init/initSlice';
// import categoriesMenuReducer from "./categoryMenu/categoriesMenuSlice";

const rootReducer = {
  auth: authReducer,
  message: messageReducer,
  products: productsReducer,
  cartProducts: cartReducer,
  wishlist: wishlistReducer,
  init: initReducer,
} 

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
}) 

export default store;
