import React from "react";
import { Accordion } from "react-bootstrap";
import { Navbarr, Footer } from "../components";
import  useDocumentTitle  from "../hooks/useDocumentTitle";

import styles from "../styles/Faq.module.css";

const Faq = () => {
  useDocumentTitle("Faq");
  const answer = `Sed quis nunc efficitur, gravida orci sed, gravida felis. Quisque non euismod felis. Suspendisse consectetur, tellus in condimentum fringilla, turpis massa facilisis augue, eget tempor nibh dui in dolor. Donec sagittis scelerisque est. Nam tristique porta ligula, vel viverra sem eleifend nec. Nulla sed purus augue, eu euismod tellus. Nam mattis eros nec mi sagittis sagittis. Vestibulum suscipit cursus bibendum. Integer at justo eget sem auctor auctor eget vitae arcu. Nam tempor malesuada porttitor. Nulla quis dignissim ipsum. Aliquam pulvinar iaculis justo, sit amet interdum sem hendrerit vitae. Vivamus vel erat tortor. Nulla facilisi. In nulla quam, lacinia eu aliquam ac, aliquam in nisl.
    1. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    2. Maecenas ullamcorper est et massa mattis condimentum.
    3. Vestibulum sed massa vel ipsum imperdiet malesuada id tempus nisl.
    4. Etiam nec massa et lectus faucibus ornare congue in nunc.
    5. Mauris eget diam magna, in blandit turpis.
    
    In cursus faucibus tortor eu vestibulum. Ut eget turpis ac justo porta varius. Donec vel felis ante, ac vehicula ipsum. Quisque sed diam metus. Quisque eget leo sit amet erat varius rutrum vitae dapibus lectus. Vivamus et sapien ante. Suspendisse potenti. Fusce in tellus est, ac consequat lacus. Nulla risus massa, commodo in imperdiet ut, ornare in leo. Duis pellentesque sagittis lorem, sed mollis lorem venenatis id.`;
  const faqs = [
    {
      question: "What is the difference between a product and a service?",
      ans: answer,
      id: 1,
    },
    {
      question: "What is the difference between a product and a service?",
      ans: answer,
      id: 2,
    },
    {
      question: "What is the difference between a product and a service?",
      ans: answer,
      id: 3,
    },
    {
        question: "What is the difference between a product and a service?",
        ans: answer,
        id: 4,
      },
      {
        question: "What is the difference between a product and a service?",
        ans: answer,
        id: 5,
      },
      {
        question: "What is the difference between a product and a service?",
        ans: answer,
        id: 6,
      },
      {
        question: "What is the difference between a product and a service?",
        ans: answer,
        id: 7,
      },
      {
        question: "What is the difference between a product and a service?",
        ans: answer,
        id: 8,
      },
      {
        question: "What is the difference between a product and a service?",
        ans: answer,
        id: 9,
      },
  ];

  return (
    <>
      <Navbarr />
      <div className={styles.Faq_all}>
        <div className={styles.FaqHeader}>
          <div className={styles.FaqHeader_title}>How can we help you?</div>
          <div className={styles.FaqHeader_desc}>
            Below are answers to our most commonly asked questions. If you
            cannot find an answer here, please contact us.
          </div>
        </div>
        <div className={styles.FaqBody}>
          {faqs.map((faqq) => (
            <Accordion key={faqq.id}>
              <Accordion.Header className={styles.faq_question}>
                <div>Question</div>
                <span>{faqq.question}</span>
              </Accordion.Header>
              <Accordion.Body>{faqq.ans}</Accordion.Body>
            </Accordion>
          ))}
          <div className={styles.faq_btns}>
            <span className={styles.faq_txt}> Unable to find satisfactory answers ? </span>
            <div className={styles.faq_btn}>Contact Us</div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
