import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FacebookLogin from "@greatsumini/react-facebook-login";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./../styles/SignInSignUp.module.css";
import { useTranslation } from "react-i18next";
import { Signup, Signin } from "../components";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import shopBags from "../assets/shopBag-signin-yalla.svg";
import facebook from "../assets/facebook.svg";
import google from "../assets/google.svg";
import divider from "../assets/divider.svg";
import { loginWithSocial } from "../auth/authSlice";
import { clearMessage } from "../auth/message";
import { initialize } from "../services/initialize";

const SignInSignUp = () => {
  const { t } = useTranslation();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const { SocialMessage } = useSelector((state) => state.message);
  const { profile } = useSelector((state) => state.init);
  const userData = JSON.parse(localStorage.getItem("user"));

  const location = window.location.pathname;
  const isSignIn = location.includes("signin");
  const isSignUp = location.includes("signup");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [social, setSocial] = useState(false);
  const [socialLogin, setSocialLogin] = useState(false);
  const [open, setOpen] = useState(true);

  const facebookLogin = "621725712685891";
  const googleLogin =
    "607768917707-l3sqni77g8neql99jaihpp0qgo8orn95.apps.googleusercontent.com";

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: googleLogin,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onClick = () => {
    setClicked(!clicked);
  };
  const [clicked, setClicked] = useState(false);

  // if auth success at social sign, redirect to signupSocial

  // useEffect(() => {
  //   // check if user is logged in and has name, last_name, email, phone
  //   socialLogin &&
  //     (user?.phone
  //       ? navigate("/")
  //       : navigate("/customer/account/signup/social"));
  // }, [socialLogin]);
  // function to check if user is logged in and has name, last_name, email, phone to redirect to home page or signupSocial page with async await to wait for the response
  useEffect(() => {
    // check if user is logged in and has name, last_name, email, phone
    console.log(userData);
    socialLogin &&
      (userData?.name &&
      userData?.last_name &&
      userData?.email &&
      userData?.phone &&
      userData?.is_guest === false
        ? navigate("/")
        : navigate("/customer/account/signup/social"));
  }, [socialLogin]);

  return (
    <>
      <div className={styles.signPage}>
        <div className={styles.signHeader}>
          <div
            onClick={onClick}
            className={
              isSignIn
                ? styles.signInHeaderTitle_clicked
                : styles.signInHeaderTitle
            }
          >
            <Link to="/customer/account/signin">{t("Sign In")}</Link>
          </div>
          <div
            onClick={onClick}
            className={
              isSignUp
                ? styles.signUpHeaderTitle_clicked
                : styles.signUpHeaderTitle
            }
          >
            <Link to="/customer/account/signup">{t("Sign Up")}</Link>
          </div>
        </div>

        <div className={styles.signContainer}>
          <div className={styles.SignForm}>
            {isSignIn && <Signin />}
            {isSignUp && <Signup />}
          </div>
          <img alt="divider" src={divider} className={styles.signDivider} />

          <div className={styles.SignSocial}>
            <div className={styles.socials}>
              <p className={styles.SignSocial_title}>{t("or Sign In with")}</p>
              <button className={styles.SignSocial_button}>
                <img src={facebook} alt="facebook" />
                <FacebookLogin
                  appId={facebookLogin}
                  onSuccess={(res) => {
                    console.log(res);
                    console.log(res.accessToken);
                    dispatch(
                      loginWithSocial({
                        access_token: res.accessToken,
                        provider: "facebook",
                      })
                    );
                    setSocialLogin(true);
                    dispatch(initialize());
                    // checkSocial();
                    (user?.phone || profile?.phone) &&
                    (user?.name || profile?.name) &&
                    (user?.last_name || profile?.last_name) &&
                    (user?.email || profile?.email) &&
                    (user?.is_guest || profile?.is_guest) === false
                      ? navigate("/")
                      : navigate("/customer/account/signup/social");
                  }}
                  // onProfileSuccess={() => {
                  //   navigate("/");
                  // }}
                  onFail={(err) => {
                    console.error(err);
                  }}
                  style={
                    document.body.dir === "rtl"
                      ? {
                          fontFamily: "unset",
                        }
                      : {}
                  }
                >
                  {t("Facebook")}
                </FacebookLogin>
              </button>
              <button className={styles.SignSocial_button}>
                <img src={google} alt="google" />
                <GoogleLogin
                  clientId={googleLogin}
                  onSuccess={(res) => {
                    dispatch(
                      loginWithSocial({
                        access_token: res.accessToken,
                        provider: "google",
                      })
                    );
                    dispatch(initialize());
                    (user?.phone || profile?.phone) &&
                    (user?.name || profile?.name) &&
                    (user?.last_name || profile?.last_name) &&
                    (user?.email || profile?.email) &&
                    (user?.is_guest || profile?.is_guest) === false
                      ? navigate("/")
                      : navigate("/customer/account/signup/social");
                  }}
                  onFailure={(err) => {
                    console.error(err);
                  }}
                  // redirectUri="/"

                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      style={
                        document.body.dir === "rtl"
                          ? {
                              fontFamily: "unset",
                            }
                          : {}
                      }
                    >
                      {t("Google")}
                    </button>
                  )}
                  className={styles.SignSocial_buttonText}
                ></GoogleLogin>
              </button>
            </div>
            {SocialMessage ? (
              SocialMessage !== "Success" ? (
                <Box sx={{ width: "100%" }}>
                  <Collapse in={open}>
                    <Alert severity="error" sx={{ mb: 2 }}>
                      {SocialMessage}
                    </Alert>
                  </Collapse>
                </Box>
              ) : (
                isLoggedIn && navigate("/", { replace: true })
              )
            ) : null}
            <div className={styles.SignSocial_terms}>
              <p>
                {t("By clicking, you agree to")}{" "}
                <span>
                  <a href="/static-pages/PRIVACY_POLICY">
                    {t("our Terms of Service and Privacy Policy")}
                  </a>
                </span>
              </p>
            </div>
            <div className={styles.SignSocial_img}>
              <img src={shopBags} alt="shop" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInSignUp;
