import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import authHeader from "../services/auth-header";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Skeleton } from "@mui/material";
import ArrowLeft from "../assets/yallaFood-arrowLeft.svg";

import styles from "../styles/ProductsCarousal.module.css";
import ProductCard from "./ProductCard";

const ProductCarousal = ({ title }) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [carousal, setCarousal] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(baseUrl + "/home/sections", {
          headers: authHeader(),
        });
        // console.log(res.data);
        console.log(res?.data?.data);
        setCarousal(res?.data?.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const sliderSettings = {
    infinite: false,
    accessibility: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    lazyLoad: true,
    scrollX: true,

    nextArrow: (
      <div className={styles.Arrow}>
        <img
          src={ArrowLeft}
          alt="arrow"
          style={
            document.body.dir === "rtl" ? { transform: "rotate(180deg)" } : {}
          }
          onClick={() =>
            document.body.dir === "rtl"
              ? sliderRef.slickPrev()
              : sliderRef.slickNext()
          }
        />
      </div>
    ),
    prevArrow: (
      <div className={styles.Arrow}>
        <img
          src={ArrowLeft}
          alt="arrow"
          style={
            document.body.dir === "rtl" ? {} : { transform: "rotate(180deg)" }
          }
          onClick={() =>
            document.body.dir === "rtl"
              ? sliderRef.slickNext()
              : sliderRef.slickPrev()
          }
        />
      </div>
    ),

    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },

      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (error) {
    return <div>Error {error}</div>;
  }

  return (
    <div className={styles.ProductsCarousal}>
      {carousal?.map((item, index) => (
        <div key={index}>
          {isLoading ? (
            [1, 2, 3, 4].map((x) => (
              <Skeleton
                key={x}
                variant="rect"
                width={200}
                height={200}
                borderRadius={10}
              />
            ))
          ) : item.products.length > 0 ? (
            <div key={index}>
              <div className={styles.ProductsTitle}>
                {title?.length > 0
                  ? title
                  : document.body.dir === "rtl"
                  ? item.name_ar
                  : item.name}
              </div>
              <Slider ref={setSliderRef} {...sliderSettings}>
                {item?.products?.map((productItem, index) => (
                  <ProductCard
                    key={index}
                    product={productItem}
                    loading={isLoading}
                  />
                ))}
              </Slider>
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default ProductCarousal;
