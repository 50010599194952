import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import authHeader from "./auth-header";

const register = async (
  name,
  last_name,
  phone,
  email,
  password,
) => {
  const response = await axios.post(
    baseUrl + "/auth/signup",
    {
      name,
      last_name,
      phone,
      email,
      password,
    },
    { headers: authHeader() }
  );
  return response.data;
};

const login = async (email, phone, password) => {
  const response = await axios.post(baseUrl + "/auth", {
    email,
    phone,
    password,
  });
  if (response.data.token) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};

const loginGuest = async (email, phone, password , token) => {
  const response = await axios.post(baseUrl + "/auth", {
    email,
    phone,
    password,
    token
  });
  if (response.data.token) {
    localStorage.setItem("user", JSON.stringify(response.data));
  }
  return response.data;
};


const loginSocial = async (access_token, provider) => {
  const response = await axios.post(baseUrl + "/auth/social", {
    access_token,
    provider,
  });
  return response;
};
const registerSocial = async (email, last_name, name, phone, token) => {
  const response = await axios.post(baseUrl + "/auth/social_signup", {
    email,
    last_name,
    name,
    phone,
    token,
  });
  console.log(response, "response");
  return response;
};

////// update guest profile
const updateGuestProfile = async (name, last_name, phone, email , token) => {
  const response = await axios.post(
    baseUrl + "/profile/full-edit",
    {
      name,
      last_name,
      phone,
      email,
      token
    },
    {
      headers: authHeader(),
    }
  );
  console.log(response.data);
  return response.data;
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  register,
  login,
  loginGuest,
  logout,
  loginSocial,
  registerSocial,
  updateGuestProfile,
};

export default authService;
