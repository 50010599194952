import React from "react";
import { Link } from "react-router-dom";

import { Footer, Navbarr, TimelineBar } from "../../components";

import styles from "../../styles/Checkout.module.css";
import product from "../../assets/product.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const OrderConfirmation = () => {
  useDocumentTitle("Order Confirmation");
  const confirmation_fields = [
    {
      id: 1,
      label: "Contact :",
      value: "elsalehsalah@gmail.com",
    },
    {
      id: 2,
      label: "Ship To :",
      value: "Cairo,Maadi,Plastine Square,300,21Building",
    },
    {
      id: 3,
      label: "Method :",
      value: "Free(3-4 Days Delivery Time)",
    },
  ];

  const cartItems = [
    {
      id: 1,
      name: "Product 1",
      price: "100.00",
      oldPrice: "200.00",
      quantity: 5,
      image: product,
    },
    {
      id: 2,
      name: "Product 2",
      price: "200.00",
      oldPrice: "300.00",
      quantity: 10,
      image: product,
    },
    {
      id: 3,
      name: "Product 3",
      price: "300.00",
      oldPrice: "400.00",
      quantity: 15,
      image: product,
    },
  ];
  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.checkout_all}>
        <div className={styles.all_container}>
          <div className={styles.checkout_container}>
            <div className={styles.delivery_header}>
              <TimelineBar />
            </div>

            <div className={styles.checkout}>
              <div className={styles.checkout__header}>
                <div className={styles.checkout__header__title}>
                  Order Details
                </div>
              </div>
              <div className={styles.checkout__body}>
                {confirmation_fields && confirmation_fields.length > 0 ? (
                  <div>
                    {confirmation_fields.map((field) => (
                      <div
                        className={styles.checkout__body__fields}
                        key={field.id}
                      >
                        <div className={styles.checkout_orderConfirmation}>
                          <div
                            className={styles.checkout_orderConfirmation_title}
                          >
                            {field.label}
                          </div>
                          <div
                            className={styles.checkout_orderConfirmation_value}
                          >
                            {field.value}
                          </div>
                        </div>
                        <div className={styles.checkout_orderConfirmation_edit}>
                          <Link to="/checkout/delivery">Edit</Link>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>No orders found</div>
                )}
                <div className={styles.checkout_btns_group}>
                  <Link
                    to="/cart"
                    className={styles.checkout_btns_group_btn_none}
                  >
                    &#60; Back to cart
                  </Link>
                  <Link
                    to="/checkout/order-placed"
                    className={styles.checkout_btns_group_btn}
                  >
                    Place Order
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.cart_body__right_products}
            style={{ height: "50%" }}
          >
            <div className={styles.cart_body__right_total__header}>
              Products Summary
            </div>
            {cartItems.map((item) => (
              <>
                <div className={styles.cart_body__right_products_product}>
                  <div className={styles.products__img}>
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className={styles.products__details}>
                    <div className={styles.products__details_title}>
                      {item.name}
                    </div>
                    <div className={styles.products__details_qty}>
                      Qty: {item.quantity}
                    </div>
                    <div className={styles.products__details_prices}>
                      <div className={styles.products__details_prices_newPrice}>
                        {item.price} EUR
                      </div>
                      <div className={styles.products__details_prices_oldPrice}>
                        {item.oldPrice} EUR
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.cart_divider}></div>
              </>
            ))}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default OrderConfirmation;
