import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  filteredProducts: [],
  totalFilteredProducts: [],
  isLoading: false,
  error: "",
  search: "",
  category_id: [],
  sub_categories: [],
  group_id: [],
  totalNumber: 0,
  categoriesSearch: [],
  subCategoriesSearch: [],
  brandsSearch: [],
  maxPriceSeach: 0,
  minPriceSeach: 0,
  promotionSearch: false,
  in_stock: false,
  page_size: 12,
  page_number: 1,
  sort: "",
  brands: [],
  showMoreEnable: false,
};
export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    startFetching(state) {
      state.isLoading = true;
    },
    successFetching(state, action) {
      let promotionBoolean = window.location.search
        .split("promotions=")[1]
        ?.split("&")[0]
        ?.replace(/%20/g, " ");
      state.isLoading = false;
      state.error = "";
      state.products = action.payload.products;
      state.categoriesSearch = action.payload.categories;
      state.subCategoriesSearch = action.payload.sub_categories;
      state.brandsSearch = action.payload.brands;
      state.maxPriceSeach = action.payload.max_price;
      state.minPriceSeach = action.payload.min_price;
      // state.page_number += 1;
      state.promotionSearch =
        action.payload.promotion?.length > 0
          ? action.payload.promotion
          : promotionBoolean === "true"
          ? true
          : promotionBoolean === "false"
          ? false
          : false;

      state.totalFilteredProducts = [
        ...state.totalFilteredProducts,
        ...state.products,
      ];
    },
    filterOrSortProducts(state, action) {
      //reset products and totalFilteredProducts and set the new products and totalFilteredProducts
      state.totalFilteredProducts = [];
      state.sort = action.payload?.sort;
      state.showMoreEnable = action.payload?.showMoreEnable;
    },
    resetTotalFilteredProducts(state) {
      state.totalFilteredProducts = [];
    },
    resetPageNumber(state) {
      state.page_number = 1;
    },
    increasePageNumber(state) {
      state.page_number += 1;
    },
    errorFetching(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    searchFilter(state, action) {
      //clear the filtered products array before searching
      // state.totalFilteredProducts = [];
      state.search = action.payload;
      state.totalFilteredProducts = state.products;
    },
    getByCategoryId(state, action) {
      state.category_id = action.payload;
    },
    filterByPriceLowToHigh(state, action) {
      if (state.totalFilteredProducts.length > 0) {
        state.totalFilteredProducts = state.totalFilteredProducts.sort(
          (a, b) => a.price - b.price
        );
      }
    },
    filterByPriceHighToLow(state, action) {
      state.totalFilteredProducts = state.totalFilteredProducts.sort(
        (a, b) => b.price - a.price
      );
    },

    filterByCategory(state, action) {
      if (action.payload !== "All") {
        state.totalFilteredProducts = state.products.filter(
          (product) => product.category.name === action.payload
        );
      }
    },
    filterBypriceRangeBetweenMinAndMax(state, action) {
      state.totalFilteredProducts = state.products.filter(
        (product) => product.price >= action.payload.min
      );
      state.totalFilteredProducts = state.totalFilteredProducts.filter(
        (product) => product.price <= action.payload.max
      );
    },
    filterByCategoryId(state, action) {
      state.category_id = action.payload;
      // state.totalFilteredProducts = state.totalFilteredProducts.filter(
      //   (product) => product.category.link === action.payload
      // );
    },
    filterBySubCategoryId(state, action) {
      state.sub_categories = action.payload;
      // pass id to an array
      // state.totalFilteredProducts = state.totalFilteredProducts.filter(
    },
    filterByGroupId(state, action) {
      state.group_id = action.payload;
    },

    filterBySortingFromAtoZ(state, action) {
      state.totalFilteredProducts = state.totalFilteredProducts.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },
    filterBySortingFromZtoA(state, action) {
      state.totalFilteredProducts = state.totalFilteredProducts.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? 1 : -1
      );
    },
    getTotal(state, action) {
      state.totalNumber = action.payload;
    },
  },
});

export const {
  startFetching,
  successFetching,
  errorFetching,
  searchFilter,
  filterByPriceLowToHigh,
  filterByPriceHighToLow,
  filterByCategory,
  filterBySortingFromAtoZ,
  filterBySortingFromZtoA,
  getTotal,
  filterBySubCategory,
  filterBySubCategoryId,
  filterByCategoryId,
  filterByGroupId,
  filterOrSortProducts,
  resetTotalFilteredProducts,
  resetPageNumber,
  increasePageNumber,
} = productsSlice.actions;

export default productsSlice.reducer;
