import * as yup from "yup";
const config = JSON?.parse(localStorage.getItem("config"));

export const userValidationSignInSchema = yup.object().shape({
  // if config is true then phone is required and must be a number and must be 11 digits long
  // else email is required and must be a valid email
  [config?.enablePhoneLogin ? "phone" : "email"]: config?.enablePhoneLogin
    ? yup
        .string()
        .typeError(
          document.body.dir === "rtl"
            ? "يجب أن يكون الهاتف رقمًا"
            : "Phone must be a number"
        )
        //matches regex for phone 10 digits or 11 digits
        .matches(/^\d+$/, {
          message:
            document.body.dir === "rtl"
              ? "يجب أن يكون الهاتف رقمًا"
              : "Phone must be a number",
        })
        .min(
          10,
          document.body.dir === "rtl"
            ? "يجب أن يتكون الهاتف من 10 رقمًا"
            : "Phone must be 10 digits long"
        )
        .max(
          11,
          document.body.dir === "rtl"
            ? "يجب أن يتكون الهاتف من 11 رقمًا"
            : "Phone must be 11 digits long"
        )
        .required(document.body.dir === "rtl" ? "الهاتف مطلوب" : "Phone is required")
    : yup
        .string()
        .required(
          document.body.dir === "rtl"
            ? "البريد الالكتروني مطلوب"
            : "Email is required"
        )
        //matches regex for email
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          document.body.dir === "rtl"
            ? "يجب أن يكون البريد الإلكتروني صالحًا"
            : "Must be a valid email"
        )
        .email(
          document.body.dir === "rtl"
            ? "يجب أن يكون البريد الإلكتروني صالحًا"
            : "Must be a valid email"
        ),
  password: yup
    .string()
    .required(
      document.body.dir === "rtl"
        ? "كلمة المرور مطلوبة"
        : "Password is required"
    )
    .min(
      8,
      document.body.dir === "rtl"
        ? "يجب أن تكون كلمة المرور 8 أحرف على الأقل"
        : "Password must be at least 8 characters long"
    ),
});

//     password: yup.string().min(4).max(50).required("Password is required"),
//     // and doesn't accept characters other than 0-9
//     // .matches(/^[0-9]+$/, "Phone number is not valid")
// }
// );
