import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories: [],
  profile: [],
  addresses: [],
  localization: [],
  cart: [],
  compare: [],
  favourites: [],
  gifts: [],
  notifications: [],
  unreadNotifications: 0,
  user_active: true,
  isLoading: false,
  error: "",
  deliveryAddress: [],
  phone: "",
  name: "",
  email: "",
};

export const initSlice = createSlice({
  name: "init",
  initialState,
  reducers: {
    startFetching(state) {
      state.isLoading = true;
    },
    categoriesFetching(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
      localStorage.setItem("categories", JSON.stringify(state.categories));
    },
    profileFetching(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },
    addressesFetching(state, action) {
      state.isLoading = false;
      state.addresses = action.payload;
    },
    localizationFetching(state, action) {
      state.isLoading = false;
      state.localization = action.payload;
    },
    cartFetching(state, action) {
      state.isLoading = false;
      state.cart = action.payload;
      localStorage.setItem("cartProducts", JSON.stringify(state.cart));
    },
    compareFetching(state, action) {
      state.isLoading = false;
      state.compare = action.payload;
    },
    favouritesFetching(state, action) {
      state.isLoading = false;
      state.favourites = action.payload;
    },
    giftsFetching(state, action) {
      state.isLoading = false;
      state.gifts = action.payload;
    },
    notificationsFetching(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    unreadNotificationsFetching(state, action) {
      state.isLoading = false;
      state.unreadNotifications = action.payload;
    },
    user_activeFetching(state, action) {
      state.isLoading = false;
      state.user_active = action.payload;
    },
    errorFetching(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    deliveryAddressFetching(state, action) {
      state.isLoading = false;
      state.deliveryAddress = action.payload;
      localStorage.setItem(
        "deliveryAddress",
        JSON.stringify(state.deliveryAddress)
      );
    },
    /////////////////////////////////////////////////////////////////////
    // Edit Profile

    editProfileData(state, action) {
      state.profile = action.payload;
    },
  },
});
export const {
  startFetching,
  categoriesFetching,
  profileFetching,
  addressesFetching,
  localizationFetching,
  cartFetching,
  compareFetching,
  favouritesFetching,
  giftsFetching,
  notificationsFetching,
  unreadNotificationsFetching,
  user_activeFetching,
  errorFetching,
  deliveryAddressFetching,
  editProfileData,
} = initSlice.actions;

export default initSlice.reducer;
