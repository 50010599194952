import { initialize } from "./initialize";

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem("user")); //json.parse
  if (user && user.token) {
    return {
      Authorization: "Bearer " + user.token,
      lang: document.body.dir === "rtl" ? "2" : "1",
    };
  } else {
    return {
      Authorization: localStorage.getItem("init_token")
        ? "Bearer " + localStorage.getItem("init_token")
        : null,
      lang: document.body.dir === "rtl" ? "2" : "1",
    };
  }
}
