import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { baseUrl } from "../constants/apiURL";

import { Skeleton } from "@mui/material";

const HeroSlider = () => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const getAds = async () => {
    const res = await axios.get(`${baseUrl}/home/ads`);
    try {
      setLoading(false);
      setImages(res?.data?.data);
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    getAds();
  }, []);

  return (
    <Carousel>
      {loading ? (
        <Skeleton
          style={{ margin: "auto", borderRadius: "10px" }}
          variant="rectangular"
          width="100%"
          height={window.innerWidth < 769 ? "250px" : "500px"}
        />
      ) : (
        images.map((image, index) => (
          <Carousel.Item key={index}>
            <img
              width="100%"
              height={window.innerWidth < 769 ? "250px" : "500px"}
              className="d-block w-100"
              src={image.image_web}
              alt="First slide"
            />
            <Carousel.Caption>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
        ))
      )}
    </Carousel>
  );
};

export default HeroSlider;
