import React from "react";
import { Link } from "react-router-dom";

import styles from "../styles/Account.module.css";

import arrow from "../assets/arrow.svg";

const EmptyCart = ({ imgSrc, link, linkTxt }) => {
  return (
    <div className={styles.AccountFavourite_empty}>
      <div className={styles.AccountFavourite_empty__img}>
        <img src={imgSrc} alt="emptyWishlist" />
      </div>
      <Link to={link} className={styles.AccountFavourite_empty__linkToShopping}>
        <div className={styles.AccountFavourite_empty__linkToShopping_txt}>
          {linkTxt}
        </div>
        <div className={styles.AccountFavourite_empty__linkToShopping_icon}>
          <img
            src={arrow}
            alt="arrow"
            style={
              document.body.dir === "rtl" ? { transform: "rotate(180deg)" } : {}
            }
          />
        </div>
      </Link>
    </div>
  );
};

export default EmptyCart;
