import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {
  cart: false,
  userProfile: false,
};

export const ContextProvider = ({ children }) => {
  const [activeMenuMobile, setActiveMenuMobile] = useState(true);
  const [isClicked, setisClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [activeStatusMobile, setActiveStatusMobile] = useState(false);
  const [isAdded, setIsAdded] = useState(false);

  const handleClick = (clicked) => {
    setisClicked({ ...initialState, [clicked]: !isClicked[clicked] });
  };
  return (
    <StateContext.Provider
      value={{
        activeMenuMobile,
        setActiveMenuMobile,
        isClicked,
        setisClicked,
        handleClick,
        screenSize,
        setScreenSize,
        activeStatusMobile,
        setActiveStatusMobile,
        isAdded,
        setIsAdded,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
