import React, { useEffect } from "react";

import styles from "../../../styles/Account.module.css";
import {
  Navbarr,
  Footer,
  AccountMenu,
  EmptyCart,
  OrderRecipt,
} from "../../../components";
import userPrefService from "../../../services/user-preferences";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import emptyProduct from "../../../assets/empty_orders.svg";
import product from "../../../assets/product.svg";

const AccountOrders = () => {
  useDocumentTitle("Account Orders");
  const { t, i18n } = useTranslation();
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    userPrefService
      .getOrders() //get orders from the server
      .then((res) => {
        console.log(res);
        setOrders(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  console.log(orders.order);
  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.account_main}>
        <AccountMenu active={"My Orders"} />
        <div className={styles.account_body}>
          <div className={styles.account_body_title}>
            <h1>{t("My Orders")}</h1>
          </div>
          {loading ? (
            [1, 2, 3, 4, 5, 6].map((i) => (
              <div>
                <Skeleton
                  key={i}
                  variant="rect"
                  width={800}
                  height={200}
                  animation="wave"
                  style={{ borderRadius: "20px", marginBottom: "10px" }}
                />
              </div>
            ))
          ) : orders.total > 0 ? (
            orders.order.map((order) => (
              <OrderRecipt
                id={order.id}
                product_id={order.items.map((item) => item.id)}
                SingleOrder={order.items}
                status={
                  document.body.dir === "rtl"
                    ? order.state.name_ar
                    : order.state.name
                }
                key={order.id}
                price={order.total}
                date={order.date}
                customer={
                  order.address.customer_first_name
                    ? order.address.customer_first_name
                    : order.user.name
                }
                orderNumber={order.id}
                status_id={order.state_id}
                canceled={true}
              />
            ))
          ) : (
            <EmptyCart
              imgSrc={emptyProduct}
              link="/"
              linkTxt={t("Browse Products")}
            />
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AccountOrders;
