import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { placeholderImg } from "../constants/placeholderImg";
import { useDispatch , useSelector} from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "../styles/BrandsCarousal.module.css";
import { fetchProducts } from "../services/products.service";

const BrandsCarousal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [brands, setBrands] = useState([]);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    axios
      .get(`${baseUrl}/brands`)
      .then((res) => {
        console.log(res);
        setBrands(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  console.log(brands);
  //if length of array of brands is lower than 3 items then make it 8 items

  const [sliderRef, setSliderRef] = useState(null);

  const sliderSettings = {
    infinite: true,
    accessibility: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    swipeToSlide: true,
    lazyLoad: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  const handleBrandClick = (brand) => {
    dispatch(fetchProducts({}, brand));
    localStorage.setItem("products", JSON.stringify(products));
    navigate("/products?brand=" + brand);
  };


  return (
    <div className={styles.BrandsCarousal}>
      {brands?.length > 0 ? (
        <Slider ref={setSliderRef} {...sliderSettings}>
          {brands.map((brand, index) => (
            <div key={index}>
              <img
                className={styles.brandImg}
                src={brand.image}
                onClick={() => {
                  handleBrandClick(brand.name);
                }}
                alt="brand"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = placeholderImg;
                }}
              />
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
};

export default BrandsCarousal;
