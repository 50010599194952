import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Accordion from "@mui/material/Accordion";
// import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import salePercentImg from "../assets/salePercent.svg";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { fetchProducts } from "../services/products.service";
import Box from "@mui/material/Box";
import ImageGallery from "react-image-gallery";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { baseUrl } from "../constants/apiURL";
import {
  addProduct,
  toggleItemAdded,
  increaseCount,
  decreaseCount,
  removeProduct,
  setTotalSum,
  cartItemsFetching,
} from "../store/cart/cartSlice";
import {
  resetTotalFilteredProducts,
  resetPageNumber,
} from "../store/products/productsSlice";
import {
  addToFavourite,
  removeFromFavourite,
  addToFavouriteSingleProduct,
  removeFromFavouriteSingleProduct,
  favSingleProduct,
} from "../store/wishlist/wishlistSlice";
import styles from "../styles/ProductDetails.module.css";
import cart from "../assets/cart.svg";
import wishlist from "../assets/wishList.svg";
import filledWishlist from "../assets/filled-heart.svg";
import shareIcon from "../assets/share-icon.svg";
import calender from "../assets/calender.svg";
import deliveryIcon from "../assets/delivery-icon.svg";
import infoIcon from "../assets/info-icon.svg";
import giftIcon from "../assets/gift.svg";
import star from "../assets/star.svg";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { placeholderImg } from "../constants/placeholderImg";
import userPrefService from "../services/user-preferences";
import Modal from "@mui/material/Modal";
import { searchFilter } from "../store/products/productsSlice";
import useScrollTop from "../hooks/useScrollTop";
import { FaFacebook, FaTwitter, FaWhatsapp } from "react-icons/fa";
import authHeader from "../services/auth-header";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const ProductDetails = () => {
  const { user } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const variantId = Number(window.location.search.split("variant=")[1]);
  const navigate = useNavigate();
  useScrollTop();
  //make routing for product details page
  //get the number after variant= in the url
  const { parent_id } = useParams();

  const placeholderImage =
    "https://nasijagaltangsel.websites.co.in/dummytemplate/img/product-placeholder.png";
  // const product = useSelector(state => state.products.products.find(product => product.id === id));
  const [product, setProduct] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingFav, setLoadingFav] = useState(false);
  const [fav, setFav] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [emailNotify, setEmailNotify] = useState(user ? user.email : "");
  const [show, setShow] = useState(false);
  const [expanded, setExpanded] = useState("panel1");
  const [count, setCount] = useState(1);
  const [clicked, setClicked] = useState(false);
  const [favSingle, setFavSingle] = useState(null);
  const [loadingCount, setLoadingCount] = useState(null);
  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  /// Share ToolTip
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const url = window.location.href;
  const title = document.title;
  const text = "Check out this product";
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}&text=${text}&hashtags=ecommerce`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const whatsappUrl = `https://wa.me/?text=${title} ${url}`;

  /////// Share ToolTip
  useEffect(() => {
    const response = axios
      .get(`${baseUrl}/products/${parent_id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        console.log(res.data.data.product);
        setProduct(res.data.data.product);
        setCount(res.data.data.product.amount);
        setClicked(res.data.data.product.is_favourite);
        dispatch(favSingleProduct(res.data.data.product.is_favourite));
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    console.log(response);
  }, [parent_id]);

  console.log(product);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const notifyMe = () => {
    userPrefService
      .NotifyMe({ id: variantId, email: emailNotify })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useDocumentTitle(product ? product.name : "Product Details");

  const [productError, setProductError] = useState("");
  // const cartProducts = useSelector((state) => state.cartProducts);
  const { cartItems, totalSum } = useSelector((state) => state.cartProducts);
  const { singleProductFavourite, isFavourite, favouritedProducts } =
    useSelector((state) => state.wishlist);

  const addToCart = () => {
    userPrefService
      .addCartItems({
        items: [
          {
            id: product?.product_variants?.find((item) => item.id === variantId)
              ?.id,
            amount: 1,
          },
        ],
        reset: false,
      })
      .then((res) => {
        if (res.code === 200) {
          dispatch(setTotalSum(res?.grand_total));
        } else {
          dispatch(setTotalSum(0));
        }

        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
    setCount(1);
    dispatch(addProduct(product));
    dispatch(toggleItemAdded(true));

    localStorage.setItem("cartProducts", JSON.stringify(cartItems));
    setIsAdded(true);
  };

  const increase = () => {
    setCount((count) => count + 1);
    dispatch(increaseCount(product, count));
    setLoadingCount(variantId);

    userPrefService
      .addCartItems({
        items: [
          {
            id: product?.product_variants?.find((item) => item.id === variantId)
              ?.id,
            amount: count + 1,
          },
        ],
        reset: false,
      })
      .then((res) => {
        dispatch(cartItemsFetching(res));
        setLoadingCount(null);

        dispatch(setTotalSum(res?.grand_total));

        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const decrease = () => {
    if (count > 1) {
      setCount((count) => count - 1);
      dispatch(decreaseCount(product, count));
      setLoadingCount(variantId);

      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.product_variants?.find(
                (item) => item.id === variantId
              )?.id,
              amount: count - 1,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));
          setLoadingCount(null);
          dispatch(setTotalSum(res?.grand_total));

          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      dispatch(removeProduct(product));
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.product_variants?.find(
                (item) => item.id === variantId
              )?.id,
              amount: 0,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));

          dispatch(setTotalSum(res.grand_total));

          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setIsAdded(false);
    toggleItemAdded(false);
  };

  const addToWishlist = () => {
    setClicked(true);
    setLoadingFav(true);
    userPrefService
      .addToFavourites(variantId)
      .then((res) => {
        setLoadingFav(false);
        setFav(res);
        setClicked(true);
        dispatch(addToFavourite(product));
        dispatch(addToFavouriteSingleProduct(true));
        dispatch(
          favSingleProduct(
            res.find((item) => item.id === variantId)?.is_favourite
          )
        );
      })
      .catch((err) => {
        console.log(err);
        setLoadingFav(false);
        setClicked(true);
      });

    console.log(fav.includes(product));
  };
  const removeFromWishlist = () => {
    setLoadingFav(true);
    setClicked(false);
    userPrefService
      .removeFromFavourites(variantId)
      .then((res) => {
        setLoadingFav(false);
        setClicked(false);
        dispatch(removeFromFavourite(product));
        removeFromFavouriteSingleProduct(false);
        product.isFavourite = false;
        dispatch(favSingleProduct(false));
      })
      .catch((err) => {
        setLoadingFav(false);
        console.log(err);
      });
    setFav([...fav.filter((item) => item.id !== variantId)]);

    console.log(fav.includes(product));
  };

  if (!product) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "800px",
          margin: "auto",
        }}
      >
        <h1>{t("Product not found")}</h1>
      </div>
    );
  }
  // useDocumentTitle(product.name && product.name.length > 0 ? product.name : "Product");
  const images = [
    {
      //on image error, show placeholder image
      original: product.image ? product.image : placeholderImage,
    },
  ];

  const ratings = [
    {
      rating: "5",
      number: "20",
    },
    {
      rating: "4",
      number: "40",
    },
    {
      rating: "3",
      number: "20",
    },
    {
      rating: "2",
      number: "50",
    },
    {
      rating: "1",
      number: "10",
    },
  ];
  const all = 140;

  // const gradPercent = (number) => (parseInt(number) / all) * 100;

  const allRatings = ratings.map((rating) => (
    <li className={styles.product_top__deals_ratings_starList}>
      {" "}
      <img src={star} alt="star" style={{ marginInlineEnd: "10px" }} />
      {rating.rating}
      <div
        style={{
          backgroundImage:
            "linear-gradient(to right, #FBB03B 60%, #DBDBDB 60%)",
          width: "100px",
          height: "6px",
          borderRadius: "10px",
          marginInlineStart: "7px",
        }}
        className={styles.product_top__deals_ratings_stars_list_item_meter}
      ></div>
    </li>
  ));
  // if product has thumbnail images then add them to images array else don't add anything
  if (product.images) {
    product.images.map((image) => {
      images.push({
        original: image.url ? image.url : placeholderImage,
      });
    });
  }

  // when user goes back go to product list page then it will show the product list page

  // //build a table of product attributes and values coming from the api
  // const productAttributes = product?.details?.attributes?.map((attribute) => (
  //   <tr style={{ border: "1px solid rgb(221, 221, 221)" }}>
  //     <th style={{ padding: "1rem", border: "1px solid rgb(221, 221, 221)" }}>
  //       {attribute.option}
  //     </th>
  //     <td
  //       dangerouslySetInnerHTML={{ __html: attribute.value }}
  //       style={{ padding: "1rem" }}
  //     ></td>
  //   </tr>
  // ));

  const viewBrandProducts = () => {
    dispatch(
      fetchProducts({}, {}, [], {}, {}, {}, {}, {}, {}, {}, {}, [
        product?.brand?.id,
      ])
    );
    navigate({
      pathname: "/products",
      search: `?q=${product?.brand?.name}`,
    });
  };
  console.log(
    cartItems?.items?.find(
      (item) => item.id === product?.product_variants?.[0]?.id
    )
  );
  console.log(product?.product_variants?.[0]);
  console.log(
    cartItems?.items?.find(
      (item) => item?.id === product?.product_variants?.[0]?.id
    )
      ? true
      : false
  );
  console.log(variantId, typeof variantId);

  const productPromotions = () => {
    if (
      product?.product_variants?.find((item) => item.id === variantId)
        .promotion &&
      product?.product_variants?.find((item) => item.id === variantId)
        .discount_price !== null
    ) {
      return true;
    } else {
      return false;
    }
  };
  console.log(productPromotions());

  const handleClickCategory = (id) => {
    dispatch(resetPageNumber());
    dispatch(resetTotalFilteredProducts());
    dispatch(fetchProducts(1, {}, id, {}, {}, {}, 12));

    console.log(id);
  };

  const productMaxPerOrder = cartItems?.items?.find(
    (item) => item.id === variantId
  )?.max_per_order;

  const productCanBuy = cartItems?.items?.find(
    (item) => item.id === variantId
  )?.can_buy;

  const productAmount = cartItems?.items?.find(
    (item) => item.id === variantId
  )?.amount;

  const productStock = cartItems?.items?.find(
    (item) => item.id === variantId
  )?.stock;

  const productID = cartItems?.items?.find((item) => item.id === variantId)?.id;

  return (
    <>
      {/* <Navbarr /> */}
      <div
        style={{ backgroundColor: "rgb(248, 248, 246)", padding: "2vh 7vw" }}
      >
        <main className={styles.product}>
          {isLoading ? (
            <Skeleton
              style={{
                borderRadius: "10px",
                marginBottom: "20px",
              }}
              count={1}
              height={window.innerWidth > 768 ? 200 : 150}
              width={window.innerWidth > 768 ? 400 : 300}
            />
          ) : (
            <div className={styles.product_breadcrumbs}>
              <a href="/">{t("Home")}</a>
              <a
                href={`/products?category_id=${product?.category?.id}`}
                onClick={() => handleClickCategory(product?.category?.id)}
                style={{ cursor: "pointer" }}
              >
                <span> &#10095; </span>
                {document.body.dir === "rtl"
                  ? product?.category?.name_ar
                  : product?.category?.name}{" "}
                <span> &#10095; </span>
              </a>
              <a active>
                {document.body.dir === "rtl" ? product?.name_ar : product?.name}
              </a>
            </div>
          )}
          <section className={styles.product_details}>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                animation="wave"
                style={{
                  borderRadius: "20px",
                  margin: " 0 1rem",
                  width: "90%",
                  height: "40%",
                }}
              />
            ) : (
              <div className={styles.product_details_img}>
                <div className={styles.Product_labels}>
                  {productPromotions() ? (
                    <div
                      className={styles.ProductCardSalePercent}
                      style={
                        document.body.dir === "rtl"
                          ? { marginTop: "2vh" }
                          : { marginTop: "4vh" }
                      }
                    >
                      <img
                        src={salePercentImg}
                        alt="salePercentage"
                        className={styles.ProductCardPercentImg}
                      />
                      <span className={styles.ProductCardPercentText}>
                        <span>
                          {Math.round(
                            ((product.price - product.discount_price) /
                              product.price) *
                              100
                          )}
                          % {t("OFF")}
                        </span>
                      </span>
                    </div>
                  ) : null}
                  {!product?.product_variants?.find(
                    (item) => item.id === variantId
                  )?.in_stock ? (
                    <div
                      className={styles.ProductCardSalePercent}
                      style={
                        document.body.dir === "rtl"
                          ? { marginTop: "2vh" }
                          : { marginTop: "4vh" }
                      }
                    >
                      <span className={styles.ProductCardPercentText}>
                        {t("Out of Stock")}
                      </span>
                    </div>
                  ) : null}
                  {product?.product_variants?.find(
                    (item) => item.id === variantId
                  )?.preorder && (
                    <div
                      className={styles.ProductCardSalePercent}
                      style={
                        document.body.dir === "rtl"
                          ? { marginTop: "2vh" }
                          : { marginTop: "4vh" }
                      }
                    >
                      <span className={styles.ProductCardPercentText}>
                        {t("Preorder")}
                      </span>
                    </div>
                  )}
                  {productPromotions() ? (
                    <div
                      className={styles.ProductCardSalePercent}
                      style={
                        document.body.dir === "rtl"
                          ? { marginTop: "2vh" }
                          : { marginTop: "4vh" }
                      }
                    >
                      <span className={styles.ProductCardPercentText}>
                        {document.body.dir === "rtl"
                          ? product?.promotion_ar +
                            ` OR ` +
                            Math.round(
                              ((product.price - product.discount_price) /
                                product.price) *
                                100
                            )
                          : product?.promotion_en +
                            ` OR ` +
                            Math.round(
                              ((product.price - product.discount_price) /
                                product.price) *
                                100
                            )}
                        {t(" % OFF")}
                      </span>
                    </div>
                  ) : null}
                  {product?.product_variants?.find(
                    (item) => item.id === variantId
                  )?.promotion && (
                    <div
                      className={styles.ProductCardSalePercent}
                      style={
                        document.body.dir === "rtl"
                          ? { marginTop: "2vh" }
                          : { marginTop: "4vh" }
                      }
                    >
                      <span className={styles.ProductCardPercentText}>
                        {document.body.dir === "rtl"
                          ? product?.product_variants?.find(
                              (item) =>
                                item.id ===
                                Number(
                                  window.location.search.split("variant=")[1]
                                )
                            )?.promotion_ar
                          : product?.product_variants?.find(
                              (item) =>
                                item.id ===
                                Number(
                                  window.location.search.split("variant=")[1]
                                )
                            )?.promotion_en}
                      </span>
                    </div>
                  )}
                </div>
                <ImageGallery
                  items={images}
                  autoPlay={false}
                  onImageError={(e) => {
                    e.target.src = placeholderImg;
                  }}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  showNav={false}
                  showBullets={true}
                  //change the color of the bullets
                  bulletClass={styles.bulletClass}
                  onErrorImageURL={placeholderImg}
                  showThumbnails={false}
                  isRTL={document.body.dir === "rtl" ? true : false}
                  swipingTransitionDuration={1}
                  swipeThreshold={1}
                  disableSwipe={false}
                />
              </div>
            )}
            <div className={styles.product_details_info}>
              <div className={styles.product_details_info__titleInfo}>
                <div className={styles.product_details_info__titleInfo_logo}>
                  {isLoading ? (
                    <Skeleton
                      variant="rectangular"
                      width={100}
                      height={100}
                      animation="wave"
                      style={{ borderRadius: "20px", margin: " 0 1rem" }}
                    />
                  ) : (
                    <img
                      width="80px"
                      height="80px"
                      src={
                        product?.brand?.image
                          ? product?.brand?.image
                          : placeholderImage
                      }
                      alt={product.name}
                      onError={(e) => {
                        e.target.src = placeholderImage;
                      }}
                    />
                  )}
                </div>
                <div
                  onClick={viewBrandProducts}
                  className={styles.product_details_info__titleInfo_link}
                >
                  <div>{t("view all products")}</div>
                </div>
              </div>
              <div className={styles.product_details_info__productInfo}>
                <div className={styles.product_details_info__productInfo_name}>
                  {isLoading ? (
                    <Skeleton
                      width={200}
                      height={30}
                      style={{ borderRadius: "10px", margin: " 0 1rem" }}
                    />
                  ) : document.body.dir === "rtl" ? (
                    product?.name_ar
                  ) : (
                    product?.name
                  )}
                </div>
                {isLoading ? (
                  <Skeleton
                    width={200}
                    height={30}
                    style={{ borderRadius: "10px", margin: " 0 1rem" }}
                  />
                ) : (
                  <>
                    <span
                      className={styles.product_details_info__productInfo_link}
                    >
                      <Grid item>
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                          <div>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={
                                //add social media share links
                                <div className={styles.shareLinks}>
                                  <div className={styles.shareLinks__item}>
                                    <a
                                      href={facebookUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FaFacebook color="white" />
                                    </a>
                                  </div>
                                  <div className={styles.shareLinks__item}>
                                    <a
                                      href={twitterUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FaTwitter color="white" />
                                    </a>
                                  </div>
                                  <div className={styles.shareLinks__item}>
                                    <a
                                      href={whatsappUrl}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FaWhatsapp color="white" />
                                    </a>
                                  </div>
                                </div>
                              }
                            >
                              <Button
                                onClick={handleTooltipOpen}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  whiteSpace: "nowrap",
                                  marginInlineStart: 0,
                                  borderRadius: "20px",
                                  alignSelf: "center",
                                  fontWeight: "600",
                                  padding: "1rem",
                                }}
                                sx={{
                                  ".MuiButtonBase-root:hover": {
                                    backgroundColor: "transparent",
                                  },
                                  ".MuiButtonBase-root": {
                                    color: "#ff1d52",
                                  },
                                  ":hover": {
                                    backgroundColor: "transparent",
                                  },
                                }}
                                className={
                                  styles.product_details_info__productInfo_social_btn
                                }
                              >
                                <span
                                  className={
                                    styles.product_details_info__productInfo_link
                                  }
                                >
                                  <img src={shareIcon} alt="share-icon" />
                                  <div
                                    className={
                                      styles.product_details_info__titleInfo_link_text
                                    }
                                  >
                                    {t("Share")}
                                  </div>
                                </span>
                              </Button>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      </Grid>
                    </span>
                  </>
                )}
              </div>
              <div className={styles.product_details_info__prices}>
                {isLoading ? (
                  <Skeleton
                    width={100}
                    height={30}
                    style={{ borderRadius: "10px", margin: " 0 1rem" }}
                  />
                ) : (
                  <div className={styles.product_details_info__prices_new}>
                    {product.discount_price
                      ? product.discount_price
                      : product.price}{" "}
                    {t("EUR")}
                  </div>
                )}
                {product.price && (
                  <div className={styles.product_details_info__prices_old}>
                    {isLoading ? (
                      <Skeleton
                        width={100}
                        height={30}
                        style={{ borderRadius: "10px", margin: " 0 1rem" }}
                      />
                    ) : product.discount_price ? (
                      `${product.price + t("EUR")}`
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {/* {isLoading ? (
                  <Skeleton
                    width={100}
                    height={30}
                    style={{ borderRadius: "10px", margin: " 0 1rem" }}
                  />
                ) : (
                  <div className={styles.product_details_info__prices_txt}>
                    ({t("inclusive of VAT")})
                  </div>
                )} */}
              </div>
              {isLoading ? (
                <Skeleton
                  width={100}
                  height={30}
                  style={{ borderRadius: "10px", margin: " 0 1rem" }}
                />
              ) : (
                <div className={styles.product_details_info__shipping_all}>
                  {product.free_delivery && (
                    <div className={styles.product_details_info__shipping}>
                      <img
                        className={
                          styles.product_details_info__shipping_calender
                        }
                        src={calender}
                        alt="calender"
                      />
                      <div
                        className={
                          styles.product_details_info__shipping_quickDelivery
                        }
                      >
                        {t("Free shipping, Delivery within 48 hours")}
                      </div>
                    </div>
                  )}

                  {/* <div
                    className={styles.product_details_info__shipping_delivery}
                  >
                    <img
                      src={deliveryIcon}
                      className={
                        styles.product_details_info__shipping_delivery_icon
                      }
                      alt="delivery-icon"
                    />
                    <span
                      className={
                        styles.product_details_info__shipping_delivery_txt
                      }
                    >
                      {t("Quick Delivery")}
                    </span>
                    <img
                      src={infoIcon}
                      className={
                        styles.product_details_info__shipping_delivery_info
                      }
                      alt="info-icon"
                    />
                  </div> */}
                </div>
              )}
              <div className={styles.divider}></div>

              {/* <div className={styles.product_details_info__gift_container}>
                {isLoading ? (
                  <Skeleton
                    width={100}
                    height={30}
                    style={{ borderRadius: "10px" }}
                  />
                ) : (
                  <div className={styles.product_details_info__gift}>
                    <img
                      className={styles.product_details_info__gift_icon}
                      src={giftIcon}
                      alt="gift-icon"
                    />
                    <div className={styles.product_details_info__gift_txt}>
                      Wrap ot as a gift for free,
                      <span
                        className={styles.product_details_info__gift_moreInfo}
                      >
                        <a href="/#" aria-label="more info">
                          {" "}
                          What is gift wrapping?
                        </a>
                      </span>
                    </div>
                  </div>
                )}
                <input
                  type="checkbox"
                  className={styles.product_details_info__gift_checkbox}
                />
              </div> */}
              {isLoading ? (
                <Skeleton
                  width={100}
                  height={30}
                  style={{ borderRadius: "10px", margin: " 0 1rem" }}
                />
              ) : (
                <div className={styles.product_details_info__addToCartBtns}>
                  <div className={styles.product_details_info__CardBuy}>
                    {cartItems?.items?.find((item) => item.id === variantId) ? (
                      <div className={styles.addRemove}>
                        <div
                          onClick={decrease}
                          className={styles.removeProduct}
                          style={
                            cartItems?.items?.find(
                              (item) =>
                                item.id ===
                                Number(
                                  window.location.search.split("variant=")[1]
                                )
                            )?.id === loadingCount
                              ? {
                                  cursor: "not-allowed",
                                  opacity: "0.5",
                                  color: "white",
                                }
                              : { color: "white" }
                          }
                          disabled={
                            cartItems?.items?.find(
                              (item) =>
                                item.id ===
                                Number(
                                  window.location.search.split("variant=")[1]
                                )
                            )?.id === loadingCount
                              ? true
                              : false
                          }
                        >
                          <RemoveIcon />
                        </div>
                        <div className={styles.productCount}>
                          {cartItems?.items?.find(
                            (item) =>
                              item.id ===
                              Number(
                                window.location.search.split("variant=")[1]
                              )
                          )?.amount &&
                          cartItems?.items?.find(
                            (item) =>
                              item.id ===
                              Number(
                                window.location.search.split("variant=")[1]
                              )
                          )?.id === loadingCount ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>
                              {cartItems?.items?.find(
                                (item) =>
                                  item.id ===
                                  Number(
                                    window.location.search.split("variant=")[1]
                                  )
                              ).amount &&
                                cartItems?.items?.find(
                                  (item) =>
                                    item.id ===
                                    Number(
                                      window.location.search.split(
                                        "variant="
                                      )[1]
                                    )
                                ).amount}
                            </span>
                          )}
                        </div>
                        <div
                          onClick={increase}
                          className={styles.addProduct}
                          style={
                            productMaxPerOrder
                              ? productAmount === productMaxPerOrder ||
                                productAmount > productMaxPerOrder
                              : productAmount === productStock ||
                                productAmount > productStock ||
                                productID === loadingCount
                              ? { cursor: "not-allowed", opacity: "0.5" }
                              : null
                          }
                        >
                          <AddIcon />
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={product.in_stock ? addToCart : handleShow}
                        className={styles.ProductCardBuy}
                      >
                        <button className={styles.ProductCardBuyBtn}>
                          {product.in_stock ? (
                            <img
                              src={cart}
                              alt="cart"
                              className={styles.ProductCardBuyIcon}
                            />
                          ) : null}

                          <span className={styles.ProductCardBuyText}>
                            {product.in_stock
                              ? t("Add to cart")
                              : product.preorder
                              ? t("Preorder")
                              : t("Notify me")}
                          </span>
                        </button>
                      </div>
                    )}
                    <Modal
                      className={styles.ModalLocationAll}
                      open={show}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          boxShadow: 24,
                          borderRadius: "20px",
                          p: 4,
                          width: "fit-content",
                          margin: "2rem auto",
                        }}
                      >
                        <h6 style={{ fontWeight: "600" }}>
                          {t("out_of_stock__message")}
                        </h6>
                        <div style={{ borderRadius: "20px" }}>
                          <div className={styles.NavLocationModal}>
                            <div className={styles.NavLocationModalDetails}>
                              <div className={styles.GovernLocation}>
                                <div className={styles.GovernLocationTitle}>
                                  {t("Email")}
                                </div>
                                <input
                                  className={styles.locationInput}
                                  type="text"
                                  onChange={(e) =>
                                    setEmailNotify(e.target.value)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.ModalFooter}>
                          <button
                            className={styles.checkout_btns_group_btn}
                            onClick={() => {
                              notifyMe();
                              console.log("email");
                            }}
                          >
                            {t("Notify me")}
                          </button>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  <div
                    className={styles.product_details_info__CardBuy_wishlist}
                    onClick={() => {
                      clicked ? removeFromWishlist() : addToWishlist();
                      console.log(
                        fav.find((item) => item.id === variantId),
                        variantId
                      );
                    }}
                  >
                    {loadingFav ? (
                      <Skeleton variant="circular" width={25} height={25} animation="pulse"/>
                    ) : (
                      <img
                        className={
                          styles.product_details_info__CardBuy_wishlist_icon
                        }
                        src={clicked ? filledWishlist : wishlist}
                        alt="wishlist"
                      />
                    )}
                  </div>
                </div>
              )}
              {/* {product.description && (
                <div className={styles.product_details_info__container}>
                  <div className={styles.product_details_info__title}>
                    {t("Key Features")}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: document.body.rtl
                        ? product.description_ar
                        : product.description_en,
                    }}
                    className={styles.product_details_info__description}
                  ></div>
                </div>
              )} */}
            </div>
          </section>
          {isLoading ? (
            <Skeleton
              width={800}
              height={800}
              style={{ borderRadius: "20px" }}
            />
          ) : (
            <section className={styles.product_more__details}>
              <div className={styles.product_more__details_container}>
                <div className={styles.product_more__details_title}>
                  {t("product details")}
                </div>
                <div className={styles.product_more__details_info}>
                  <div className={styles.product_more__details_info_accordions}>
                    <div
                      className={
                        styles.product_more__details_info_accordions_one
                      }
                    >
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChangeAccordion("panel1")}
                        style={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            paddingInlineStart: "0px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "clamp(14px, 24px, 28px)",
                              fontWeight: "700",
                              marginBottom: "3vh",
                              color: "#425466",
                              fontFamily: "unset",
                            }}
                          >
                            {t("Description")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          dangerouslySetInnerHTML={{
                            __html: product.long_description
                              ? document.body.dir === "rtl"
                                ? product.long_description_ar
                                : product.long_description
                              : product.description,
                          }}
                          style={{
                            padding: "8px 0px 16px",
                          }}
                        />
                      </Accordion>
                      {/* <Accordion
                        expanded={expanded === "panel2"}
                        onChange={handleChangeAccordion("panel2")}
                        style={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                            paddingInlineStart: "0px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "clamp(14px, 24px, 28px)",
                              fontWeight: "700",
                              marginBottom: "3vh",
                              color: "#425466",
                              fontFamily: "unset",
                            }}
                          >
                            {t("Features")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <table
                            style={{
                              border: "1px solid #ddd",
                              borderCollapse: "collapse",
                              width: "100%",
                            }}
                          >
                            {productAttributes}
                          </table>
                        </AccordionDetails>
                      </Accordion> */}
                    </div>
                  </div>
                  {/* <div
                    className={styles.product_more__details_info_returnPolicy}
                  >
                    <div
                      className={
                        styles.product_more__details_info_returnPolicy_title
                      }
                    >
                      {t("Return Policy")}
                    </div>
                    <div
                      className={
                        styles.product_more__details_info_returnPolicy_info
                      }
                    >
                      {t(
                        "Returns within 14 days of delivery, Learn more about the"
                      )}{" "}
                      <span>
                        <a
                          href="/static-pages/GENERAL"
                          style={{
                            color: "#FF1D52",
                            fontWeight: "100",
                          }}
                          className={
                            styles.product_more__details_info_returnPolicy_info_anchor
                          }
                        >
                          {t("return and Refund Policy")}
                        </a>
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className={styles.product_more__details_media}>
                <iframe
                  src={product.video}
                  style={{ borderRadius: "25px" }}
                  className={styles.product_more__details_media_iframe}
                  title="product-video"
                ></iframe>
              </div> */}
            </section>
          )}
          {/* <div className={styles.product_top__deals}>
            <div className={styles.product_more__details_title_centered}>
              {t("Reviews")}
            </div>
            <div className={styles.product_top__deals_ratings}>
              <button className={styles.product_top__deals_ratings_btn}>
                {t("Rate this product")}
              </button>
              <div className={styles.product_top__deals_ratings_stars}>
                <ul className={styles.product_top__deals_ratings_stars_list}>
                  {allRatings}
                </ul>
              </div>
            </div>
          </div> */}
        </main>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ProductDetails;
