import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/Checkout.module.css";

import placedOrder from "../../assets/placeImg-yalla.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { clearCart } from "../../store/cart/cartSlice";
const OrderPlaced = () => {
  const config = JSON?.parse(localStorage.getItem("config"));
  useDocumentTitle(
    document.body.dir === "rtl"
      ? ` تم الطلب | ${config?.APP_NAME_AR}`
      : `Order Placed | ${config?.APP_NAME}`
  );
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);
  const [placingOrder, setPlacingOrder] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const deliveryAdd = localStorage.getItem("deliveryAddress");
  const { user } = useSelector((state) => state.auth);
  const promo = localStorage.getItem("promo");
  const verificationCode = localStorage.getItem("verification_code");
  const planId = localStorage.getItem("plan_id");

  const { profile } = useSelector((state) => state.init);
  const order_placed_response = JSON.parse(
    localStorage.getItem("order_placed")
  );
  const orderFields = [
    {
      label: "Contact :",
      label_ar: "الاتصال :",
      value: order_placed_response?.data?.phone,
    },
    {
      label: "Ship To :",
      label_ar: "الشحن إلى :",
      value:
        document.body.dir === "rtl"
          ? order_placed_response?.data?.address?.formatted_address_ar
          : order_placed_response?.data?.address?.formatted_address,
    },
    {
      label: "Payment Method :",
      label_ar: "طريقة الدفع :",
      value:
        document.body.dir === "rtl"
          ? order_placed_response?.data?.payment_method_object?.name_ar
          : order_placed_response?.data?.payment_method_object?.name,
    },
  ];

  // if user goes back , go to cart page
  useEffect(() => {
    // setLoading(true);
    // dispatch(initialize());
    // userPrefService
    //   .placeOrder(
    //     false,
    //     JSON.parse(deliveryAdd).id,
    //     1,
    //     "",
    //     JSON.parse(localStorage.getItem("payment_type")),
    //     profile?.phone,
    //     promo,
    //     verificationCode,
    //     planId
    //   )
    //   .then((res) => {
    //     setLoading(false);
    //     console.log(res);
    //     setPlacingOrder(res);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });
    dispatch(clearCart());
    window.onpopstate = () => {
      localStorage.removeItem("order_placed");
      localStorage.removeItem("deliveryAdd");
      localStorage.removeItem("payment_type");

      window.location.href = "/cart";
    };
  }, [deliveryAdd, planId, promo, verificationCode]);

  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.checkout_all}>
        <div className={styles.all_container}>
          <div className={styles.checkout_container}>
            {/* <div className={styles.delivery_header}>
              <TimelineBar />
            </div> */}

            <div className={styles.order_placed}>
              <div className={styles.order_placed__header}></div>
              <div className={styles.order_placed__body}>
                <img src={placedOrder} alt="placed order" />
                <div className={styles.order_placed__body_details}>
                  <div className={styles.order_placed__body__title}>
                    {document.body.dir === "rtl"
                      ? "شكرا لتسوقك معنا"
                      : order_placed_response?.message}
                  </div>
                </div>
                <div className={styles.order_placed_body__orderCard}>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={400}
                      height={50}
                      style={{ borderRadius: "20px", marginBottom: "20px" }}
                    />
                  ) : (
                    <div
                      className={styles.order_placed_body__orderCard__numebr}
                    >
                      {`${t("Order No. :")} ${order_placed_response?.data?.id}`}
                    </div>
                  )}
                  <div className={styles.order_placed_body__orderCard__title}>
                    {t("Shipping Details")}
                  </div>
                  {loading
                    ? [1, 2, 3].map((item) => (
                        <Skeleton
                          variant="rectangular"
                          width={400}
                          height={50}
                          key={item}
                          style={{ borderRadius: "20px", marginBottom: "10px" }}
                        />
                      ))
                    : orderFields.map((field, index) => (
                        <div
                          key={index}
                          className={styles.order_placed_body__orderCard__field}
                        >
                          <div
                            className={
                              styles.order_placed_body__orderCard__field__label
                            }
                          >
                            {document.body.dir === "rtl"
                              ? field.label_ar
                              : field.label}
                          </div>
                          <span
                            className={
                              styles.order_placed_body__orderCard__field__value
                            }
                          >
                            {field.value}
                          </span>
                        </div>
                      ))}
                  {profile.is_guest ? null : loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={200}
                      height={50}
                      style={{ borderRadius: "20px" }}
                    />
                  ) : (
                    <Link
                      to={`/customer/orders/details/${
                        order_placed_response?.data?.id ||
                        placingOrder?.data?.id
                      }`}
                      className={styles.order_placed_body__orderCard__btn}
                      onClick={() => setClicked(true)}
                      disabled={clicked}
                    >
                      {t("View Order")}
                    </Link>
                    // {{clicked && localStorage.removeItem("order_placed")}
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default OrderPlaced;
