import React from "react";
import { Link } from "react-router-dom";

import styles from "./../styles/PaymentBox.module.css";

import edit from "../assets/edit.svg";
import basket from "../assets/basket.svg";
import masterCard from "../assets/masterCard.svg";

const PaymentBox = ({ name, address, expiryDate, cardNumber }) => {
  // const actions = [
  //   {
  //     name: "Edit",
  //     link: "/customer/addresses/edit",
  //     icon: edit,
  //   },
  //   {
  //     name: "Delete",
  //     link: "/",
  //     icon: basket,
  //   },
  // ];

  return (
    <>
      <div className={styles.Payment_box}>
        <div className={styles.Payment_box__title_content}>
          <div className={styles.Payment_box__title_content__creditCard}>
            <img
              src={masterCard}
              className={
                styles.Payment_box__title_content__creditCard__cardLogo
              }
            />
            <span
              className={styles.Payment_box__title_content__creditCard__number}
            >
              MasterCard{" "}
              {cardNumber}
            </span>
          </div>
          <div className={styles.Payment_box__title_content__expirydate}>
            <div className={styles.Payment_box__title_content__label}>
              Expiry Date
            </div>
            <span className={styles.Payment_box__title_content__value}>
              {expiryDate}
            </span>
          </div>
        </div>
        <div className={styles.Payment_box__content}>
          <div className={styles.Payment_box__content__data}>
            <div className={styles.Payment_box__content__data__label}>
              Name on Card:
            </div>
            <div className={styles.Payment_box__content__data__value}>
              {name}
            </div>
          </div>
          <div className={styles.Payment_box__content__data}>
            <div className={styles.Payment_box__content__data__label}>
              Billing Address:
            </div>
            <div className={styles.Payment_box__content__data__value}>
              {address}
            </div>
          </div>
          <div className={styles.Payment_box__content__btns}>
            <div className={styles.Payment_box__content__checkbox}>
              <input type="checkbox" />
              <span>Set as a default payment method</span>
            </div>
            <div className={styles.Payment_box__content__actions}>
              <div className={styles.Payment_box__content__actions__action}>
                <img
                  src={edit}
                  alt="Edit"
                  className={styles.Payment_box__content__actions__action__icon}
                />
                <Link
                  to="/customer/addresses/edit"
                  className={styles.Payment_box__content__actions__action__name}
                >
                  Edit
                </Link>
              </div>
              <div className={styles.Payment_box__content__actions__action}>
                <img
                  src={basket}
                  alt="Delete"
                  className={
                    styles.Payment_box__content__actions__action__icon_delete
                  }
                />
                <Link
                  to="/"
                  className={
                    styles.Payment_box__content__actions__action__name_delete
                  }
                >
                  Delete
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentBox;
