import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerWithSocial } from "../auth/authSlice";
import { clearMessage } from "../auth/message";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
// import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import Collapse from "@mui/material/Collapse";
// import CloseIcon from "@mui/icons-material/Close";
import styles from "./../styles/SignInSignUp.module.css";
import { Skeleton } from "@mui/material";
import * as Yup from "yup";
import { initialize } from "../services/initialize";

const Signup_Social = () => {
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const userRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);

  const { message } = useSelector((state) => state.message);

  useEffect(() => {
    dispatch(initialize());
    dispatch(clearMessage());
  }, [dispatch]);
  const { profile, localization, isLoading } = useSelector(
    (state) => state.init
  );
  const userToken = user?.token;

  useEffect(() => {
    userRef.current.focus();
    console.log(message);
  }, []);

  console.log(user);
  const formikSignupSocial = useFormik({
    initialValues: {
      name: profile?.name,
      last_name: profile?.last_name,
      email: profile?.email,
      phone: profile?.phone,
    },

    onSubmit: (values) => {
      const { name_social, last_name_social, email_social, phone_social } =
        values;
      setLoading(true);
      dispatch(
        registerWithSocial(
          name_social,
          last_name_social,
          phone_social,
          email_social,
          userToken
        )
      )
        .then(() => {
          setLoading(false);
          navigate("/");
          window.location.reload();
        })
        .catch(() => {
          setLoading(false);
        });
    },
    validationSchema: Yup.object().shape({
      name_social: Yup.string()
        .required(
          document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is required"
        )
        .max(
          20,
          document.body.dir === "rtl" ? "الاسم طويل" : "Name is too long"
        ),
      last_name_social: Yup.string()
        .required(
          document.body.dir === "rtl"
            ? "اسم العائلة مطلوب"
            : "Last Name is required"
        )
        .max(
          20,
          document.body.dir === "rtl"
            ? "اسم العائلة طويل"
            : "Last Name is too long"
        ),
      email_social: Yup.string()
        .required(
          document.body.dir === "rtl"
            ? "البريد الالكتروني مطلوب"
            : "Email is required"
        )
        //matches regex for email
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          document.body.dir === "rtl"
            ? "يجب أن يكون البريد الإلكتروني صالحًا"
            : "Must be a valid email"
        )
        .email(
          document.body.dir === "rtl"
            ? "يجب أن يكون البريد الإلكتروني صالحًا"
            : "Must be a valid email"
        ),
      phone_social: Yup.number()
        .typeError(
          document.body.dir === "rtl"
            ? "رقم الهاتف غير صحيح"
            : "Phone number is invalid"
        )
        .min(
          10,
          document.body.dir === "rtl"
            ? "رقم الهاتف غير صحيح"
            : "Phone number is invalid"
        )
        .required(
          document.body.dir === "rtl"
            ? "رقم الهاتف مطلوب"
            : "Phone number is required"
        ),
    }),
  });
  useEffect(() => {
    formikSignupSocial.setFieldValue("name", user?.name);
    formikSignupSocial.setFieldValue("last_name", user?.last_name);
    formikSignupSocial.setFieldValue("email", user?.email);
    formikSignupSocial.setFieldValue("phone", user?.phone);
  }, [user]);

  return (
    <>
      <form
        onSubmit={formikSignupSocial.handleSubmit}
        className={styles.SignUpForm}
      >
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="name">
            {t("First Name")}
          </div>
          <input
            type="text"
            name="name_social"
            id="name_social"
            placeholder={t("First Name")}
            value={formikSignupSocial.values.name}
            onChange={formikSignupSocial.handleChange}
            onBlur={formikSignupSocial.handleBlur}
            ref={userRef}
            className={styles.signForm_phone_input}
          />
          {formikSignupSocial.touched.name &&
            formikSignupSocial.errors.name && (
              <div style={{ color: "red" }}>
                {formikSignupSocial.errors.name}
              </div>
            )}
        </div>
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="email">
            {t("Last Name")}
          </div>
          <input
            placeholder={t("Last Name")}
            name="last_name_social"
            type="text"
            id="last_name_social"
            onChange={formikSignupSocial.handleChange}
            onBlur={formikSignupSocial.handleBlur}
            value={formikSignupSocial.values.last_name}
            key="last_name_social"
            className={styles.signForm_phone_input}
          />
          {formikSignupSocial.touched.last_name &&
            formikSignupSocial.errors.last_name && (
              <div style={{ color: "red" }}>
                {formikSignupSocial.errors.last_name}
              </div>
            )}
        </div>
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="phone">
            {t("Phone Number")}
          </div>
          <div className={styles.signForm_phone}>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width={50}
                height={20}
                style={{
                  borderRadius: "20px",
                  alignSelf: "center",
                  marginInlineEnd: "1rem",
                }}
              />
            ) : (
              <div className={styles.countryCode}>
                <span>{localization?.phone_prefix}</span>
                <img
                  src={localization?.flag}
                  alt="flag"
                  width="30px"
                  height="20px"
                />
              </div>
            )}
            <input
              name="phone_social"
              placeholder="01234567890"
              inputMode="numeric"
              id="phone_social"
              onChange={formikSignupSocial.handleChange}
              onBlur={formikSignupSocial.handleBlur}
              value={formikSignupSocial.values.phone}
              key="phone_social"
              style={{ width: "100%" }}
              className={styles.signForm_phone_input}
            />
            {formikSignupSocial.touched.phone &&
              formikSignupSocial.errors.phone && (
                <div style={{ color: "red" }}>
                  {formikSignupSocial.errors.phone}
                </div>
              )}
          </div>
        </div>
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="email">
            {t("Email")}
          </div>
          <input
            name="email_social"
            placeholder="Example@gmail.com"
            type="email"
            id="email_social"
            onChange={formikSignupSocial.handleChange}
            onBlur={formikSignupSocial.handleBlur}
            value={formikSignupSocial.values.email}
            key="email_social"
            className={styles.signForm_phone_input}
          />
          {formikSignupSocial.touched.email &&
            formikSignupSocial.errors.email && (
              <div style={{ color: "red" }}>
                {formikSignupSocial.errors.email}
              </div>
            )}
        </div>

        <div className={styles.SignForm_button}>
          <button
            type="submit"
            disabled={loading}
            style={{ padding: "2vh 10vw" }}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              <span>{t("Complete Signing in")}</span>
            )}
          </button>{" "}
        </div>
      </form>
      {message ? (
        message !== "Success" ? (
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert severity="error" sx={{ mb: 2 }}>
                {message}
              </Alert>
            </Collapse>
          </Box>
        ) : (
          navigate("/", { replace: true })
        )
      ) : null}
    </>
  );
};

export default Signup_Social;

//  initialValues: {
//   name_social: user?.name ? user?.name : "",
//   last_name_social: user?.last_name ? user?.last_name : "",
//   email_social: user?.email ? user?.email : "",
//   phone_social: user?.phone ? user?.phone : "",
// },
// validationSchema: userValidationSignUpSchema,

// onSubmit: (values) => {
//   const { name, last_name, email, password, confirm_password, phone } =
//     values;
//   setLoading(true);

//   dispatch(
//     register({
//       name,
//       last_name,
//       phone,
//       email,
//       password,
//       confirm_password,
//     })
//   )
//     .unwrap()
//     .then(() => {
//       console.log("Registered");
//       setLoading(false);
//       console.log(message);

//       // empty the form
//     })
//     .catch(() => {
//       setLoading(false);
//       console.log("error");
//       console.log(message);
//       formikSignup.resetForm();
//     });
// },
