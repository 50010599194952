import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../auth/authSlice";
import { clearMessage } from "../auth/message";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";

import styles from "./../styles/SignInSignUp.module.css";
import { userValidationSignUpSchema } from "../Validations/UserValidationSignUp";
import { Skeleton } from "@mui/material";

const Signup = () => {
  const userRef = useRef();
  const { t } = useTranslation();
  const location = window.location.pathname;
  const config = JSON?.parse(localStorage.getItem("config"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { message } = useSelector((state) => state.message);
  const { localization, isLoading } = useSelector((state) => state.init);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    userRef.current.focus();
    console.log(message);
  }, []);

  const togglePassWordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPassWordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // console.log(user.name);
  const formikSignup = useFormik({
    initialValues: {
      name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      phone: "",
    },
    validationSchema: Yup.object().shape({
      //just accept 3 characters on min and doesn't accept symbols except -
      name: Yup.string()
        .min(3)
        .max(20)
        .matches(
          /^[a-zA-Z\u0600-\u06FF]+$/,

          document.body.dir === "rtl"
            ? "يجب أن يكون الاسم حروفًا فقط"
            : "Name must be letters only"
        )
        .required(
          document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is required"
        ),
      last_name: Yup.string()
        .min(3)
        .max(20)
        .matches(
          /^[a-zA-Z\u0600-\u06FF]+$/,
          document.body.dir === "rtl"
            ? "يجب أن يكون الاسم حروفًا فقط"
            : "Name must be letters only"
        )
        .required(
          document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is required"
        ),
      email: Yup.string()
        .required(
          document.body.dir === "rtl"
            ? "البريد الالكتروني مطلوب"
            : "Email is required"
        )
        //matches regex for email
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          document.body.dir === "rtl"
            ? "يجب أن يكون البريد الإلكتروني صالحًا"
            : "Must be a valid email"
        )
        .email(
          document.body.dir === "rtl"
            ? "يجب أن يكون البريد الإلكتروني صالحًا"
            : "Must be a valid email"
        ),
      phone: Yup.string()
        .required(
          document.body.dir === "rtl" ? "رقم الهاتف مطلوب" : "Phone is required"
        )
        .matches(
          /^[0-9]+$/,
          document.body.dir === "rtl"
            ? "يجب أن يكون رقم الهاتف أرقامًا فقط"
            : "Phone must be numbers only"
        )
        .min(
          10,
          document.body.dir === "rtl"
            ? "يجب أن يكون رقم الهاتف 10 أرقام"
            : "Phone must be 10 numbers"
        )
        .max(
          11,
          document.body.dir === "rtl"
            ? "يجب أن يكون رقم الهاتف 11 أرقام"
            : "Phone must be 11 numbers"
        ),

      password: Yup.string()
        .required(
          document.body.dir === "rtl"
            ? "كلمة المرور مطلوبة"
            : "Password is required"
        )
        .min(
          6,
          document.body.dir === "rtl"
            ? "يجب أن تكون كلمة المرور 6 أحرف على الأقل"
            : "Password must be at least 6 characters long"
        )
        .max(
          50,
          document.body.dir === "rtl"
            ? "يجب أن تكون كلمة المرور 50 حرفًا كحد أقصى"
            : "Password must be at most 50 characters long"
        ),
    }),

    onSubmit: (values) => {
      const { name, last_name, email, password, phone } = values;
      setLoading(true);

      dispatch(
        register({
          name,
          last_name,
          phone,
          email,
          password,
        })
      )
        .unwrap()
        .then(() => {
          console.log("Registered");
          setLoading(false);
          console.log(message);

          // empty the form
        })
        .catch(() => {
          setLoading(false);
          console.log("error");
          console.log(message);
          formikSignup.resetForm();
        });
    },
  });
  const { name, last_name, email, password, phone } = formikSignup.values;

  return (
    <>
      <form onSubmit={formikSignup.handleSubmit} className={styles.SignUpForm}>
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="name">
            {t("First Name")}
          </div>
          <input
            placeholder={t("First Name")}
            ref={userRef}
            name="name"
            type="text"
            id="name"
            onChange={formikSignup.handleChange}
            onBlur={formikSignup.handleBlur}
            value={name}
            key="name"
          />
          {formikSignup.touched.name && formikSignup.errors.name && (
            <div className={styles.error} style={{ color: "red" }}>
              {formikSignup.errors.name}
            </div>
          )}
        </div>
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="email">
            {t("Last Name")}
          </div>
          <input
            placeholder={t("Last Name")}
            name="last_name"
            type="text"
            id="last_name"
            onChange={formikSignup.handleChange}
            onBlur={formikSignup.handleBlur}
            value={last_name}
            key="last_name"
          />
          {formikSignup.touched.last_name && formikSignup.errors.last_name && (
            <div className={styles.error} style={{ color: "red" }}>
              {formikSignup.errors.last_name}
            </div>
          )}
        </div>
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="phone">
            {t("Phone Number")}
          </div>
          <div className={styles.signForm_phone}>
            {isLoading ? (
              <Skeleton
                variant="rectangular"
                width={50}
                height={20}
                style={{
                  borderRadius: "20px",
                  alignSelf: "center",
                  marginInlineEnd: "1rem",
                }}
              />
            ) : (
              <div className={styles.countryCode}>
                <span>{localization?.phone_prefix}</span>
                <img
                  src={localization?.flag}
                  alt="flag"
                  width="20px"
                  height="15px"
                />
              </div>
            )}
            <input
              name="phone"
              placeholder="01234567890"
              inputMode={config?.enablePhoneLogin ? "numeric" : "email"}
              type={config?.enablePhoneLogin ? "numeric" : "email"}
              id={config?.enablePhoneLogin ? "phone" : "email"}
              // just accept numbers
              pattern="\d*"
              //put regex to accept only 11 numbers
              maxlength={
                config?.enablePhoneLogin ? localization?.phone_length : "50"
              }
              minlength={
                config?.enablePhoneLogin ? localization?.phone_length : "1"
              }
              onChange={formikSignup.handleChange}
              onBlur={formikSignup.handleBlur}
              value={formikSignup.values.phone}
              key={config?.enablePhoneLogin ? "phone" : "email"}
            />
          </div>
          {formikSignup.touched.phone && formikSignup.errors.phone && (
            <div className={styles.error} style={{ color: "red" }}>
              {formikSignup.errors.phone}
            </div>
          )}
        </div>
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="email">
            {t("Email")}
          </div>
          <input
            name="email"
            placeholder="Example@gmail.com"
            type="email"
            id="email"
            onChange={formikSignup.handleChange}
            onBlur={formikSignup.handleBlur}
            value={email}
            key="email"
          />
          {formikSignup.touched.email && formikSignup.errors.email && (
            <div style={{ color: "red" }}>{formikSignup.errors.email}</div>
          )}
        </div>

        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="password">
            {t("Create Password")}
          </div>
          <div style={{ display: "flex", position: "relative" }}>
            <input
              placeholder={t("Password")}
              name="password"
              type={showPassword ? "text" : "password"}
              id="password"
              onChange={formikSignup.handleChange}
              onBlur={formikSignup.handleBlur}
              value={password}
              key="password"
            />
            {showPassword ? (
              <VisibilityIcon
                onClick={togglePassWordVisibility}
                style={{
                  cursor: "pointer",
                  marginInlineStart: "1rem",
                  alignSelf: "center",
                  position: "absolute",
                  right: document.body.dir === "rtl" ? "unset" : "3%",
                  left: document.body.dir === "rtl" ? "3%" : "unset",
                }}
                color="disabled"
              />
            ) : (
              <VisibilityOffIcon
                onClick={togglePassWordVisibility}
                style={{
                  cursor: "pointer",
                  marginInlineStart: "1rem",
                  alignSelf: "center",
                  position: "absolute",
                  right: document.body.dir === "rtl" ? "unset" : "3%",
                  left: document.body.dir === "rtl" ? "3%" : "unset",
                }}
                color="disabled"
              />
            )}
          </div>
          {/* add eye icon for password here  */}
          {formikSignup.touched.password && formikSignup.errors.password && (
            <div className={styles.error} style={{ color: "red" }}>
              {formikSignup.errors.password}
            </div>
          )}
        </div>

        <div className={styles.SignForm_button}>
          <button
            type="submit"
            disabled={loading}
            style={{ padding: "2vh 10vw" }}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              <span>{t("Sign Up")}</span>
            )}
          </button>{" "}
        </div>
        <div className={styles.SignInForm_signUp}>
          <p>
            {t("Already have an account?")}
            <a href="/customer/account/signin">{t("Sign In")}</a>
          </p>
        </div>
      </form>
      {message ? (
        message !== "Success" ? (
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert severity="error" sx={{ mb: 2 }}>
                {message}
              </Alert>
            </Collapse>
          </Box>
        ) : (
          navigate("/", { replace: true })
        )
      ) : null}
    </>
  );
};
export default Signup;
