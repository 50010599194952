import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./../../../styles/Account.module.css";
import { useSelector, useDispatch } from "react-redux";

import {
  Navbarr,
  AccountMenu,
  Footer,
  AddressBox,
  EmptyCart,
  Signin,
} from "../../../components";
import { useTranslation } from "react-i18next";
import emptyAddress from "../../../assets/emptyaddress_yallafood.svg";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import userPrefService from "../../../services/user-preferences";
import { addressesFetching } from "../../../store/init/initSlice";
import { Skeleton } from "@mui/material";
import { initialize } from "../../../services/initialize";

const AccountAddresses = () => {
  useDocumentTitle("Account Addresses");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { addresses, profile } = useSelector((state) => state.init);
  const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(true);

  console.log(user);
  useEffect(() => {
    userPrefService
      .getAddress()
      .then((res) => {
        setLoading(false);
        console.log(res);
        setAddress(res.addresses);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    dispatch(initialize());
  }, []);

  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.account_main}>
        <AccountMenu active={"My Address Book"} />
        <div className={styles.account_body}>
          <div className={styles.account_body_title}>
            <h1>{t("My Addresses Book")}</h1>
          </div>
          {loading ? (
            [1, 2, 3, 4, 5, 6].map((i) => (
              <div>
                <Skeleton
                  key={i}
                  variant="rect"
                  width={800}
                  height={200}
                  animation="wave"
                  style={{ borderRadius: "20px", marginBottom: "10px" }}
                />
              </div>
            ))
          ) : address.length > 0 ? (
            <>
              <div classNme={styles.addresses_body_title}>
                <h6 style={{ color: "#000" }}>
                  <b>
                    {t("You have")}{" "}
                    {address.length === 1 || address.length === 2
                      ? null
                      : address.length}{" "}
                    {address.length === 1
                      ? t("one Address")
                      : address.length === 2
                      ? t("two Addresses")
                      : address.length > 2 && address.length < 11
                      ? t("three or more Addresses")
                      : t("Addresses")}
                  </b>
                </h6>
              </div>
              <div className={styles.addresses_body_content}>
                {address.map((item, index) => (
                  <div className={styles.addresses_body_content_address}>
                    <AddressBox
                      address={
                        document.body.dir === "rtl"
                          ? item.formatted_address_ar
                          : item.formatted_address_en
                      }
                      phone={item.phone ? item.phone : "unset"}
                      name={
                        item.customer_first_name
                          ? item.customer_first_name
                          : "unset"
                      }
                      key={index}
                      address_id={item.id}
                      apartment={item.apartment ? item.apartment : ""}
                      area={item.area?.name ? item.area?.name : ""}
                      area_id={item.area_id ? item.area_id : ""}
                      building={item.building ? item.building : ""}
                      floor={item.floor ? item.floor : ""}
                      governate={item.city?.name ? item.city?.name : ""}
                      city_id={item.city_id ? item.city_id : ""}
                      landmark={item.landmark ? item.landmark : ""}
                      street={item.street ? item.street : ""}
                      age={item.age ? item.age : ""}
                    />
                  </div>
                ))}
              </div>
              <button
                className={styles.addNewCard}
                onClick={() => navigate("/customer/addresses/addnew")}
              >
                {t("Add New Addresses")}
              </button>
            </>
          ) : (
            <EmptyCart
              imgSrc={emptyAddress}
              link="/customer/addresses/addnew"
              linkTxt={t("Add New Addresses")}
            />
          )}
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default AccountAddresses;
