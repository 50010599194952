import React, { useEffect, useRef } from "react";
import { useStateContext } from "../contexts/contextProvider";

import styles from "./../styles/OrderStatus.module.css";

import breadcrumb_placed from "../assets/breadcrumb_placed.svg";
import breadcrumb_confirmed from "../assets/breadcrumb_confirmed.svg";
import breadcrumb_prepared from "../assets/breadcrumb_prepared.svg";
import breadcrumb_shipped from "../assets/breadcrumb_shipped.svg";
import breadcrumb_delivered from "../assets/breadcrumb_delivered.svg";
import checked from "../assets/checked.svg";
import cx from "classnames";

const OrderStatus = ({ status, status_name }) => {
  const OrderRef = useRef(null);
  const {
    setScreenSize,
    screenSize,
    activeStatusMobile,
    setActiveStatusMobile,
  } = useStateContext();

  useEffect(() => {
    const handleScreenSize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleScreenSize);
    handleScreenSize();
    return () => window.removeEventListener("resize", handleScreenSize);
  }, []);

  useEffect(() => {
    screenSize < 768 && setActiveStatusMobile(true);
    screenSize >= 768 && setActiveStatusMobile(false);
  }, [screenSize]);

  const breadcrumbStyle = (status) => {
    if (status === 1) {
      return breadcrumb_placed;
    }
    if (status === 2) {
      return breadcrumb_confirmed;
    }
    if (status === 8) {
      return breadcrumb_prepared;
    }
    if (status === 3) {
      return breadcrumb_shipped;
    }
    if (status === 4) {
      return breadcrumb_delivered;
    }
    if (status === 6) {
      return null;
    } else {
      return status;
    }
  };
  const statuses = [
    {
      id: 1,
      name: "Placed",
      name_ar: "تم وضع الطلب",
      icon: checked,
    },
    {
      id: 2,
      name: "Confirmed",
      name_ar: "تم تأكيد الطلب",
      icon: checked,
    },
    {
      id: 8,
      name: "Prepared",
      name_ar: "تم تجهيز الطلب",
      icon: checked,
    },
    {
      id: 3,
      name: "Shipped",
      name_ar: "تم شحن الطلب",
      icon: checked,
    },
    {
      id: 4,
      name: "Delivered",
      name_ar: "تم تسليم الطلب",
      icon: checked,
    },
    {
      id: 6,
      name: "Cancelled",
      name_ar: "تم إلغاء الطلب",
      icon: checked,
    },
  ];

  return (
    <>
      <div className={styles.orderStatus_main}>
        {status === 6 ? null : (
          <ul className={styles.orderStatus_list}>
            {document.body.dir === "rtl" ? (
              statuses.map((item) => (
                <>
                  <div
                    className={
                      status === item.id && activeStatusMobile
                        ? styles.statusMobileShow
                        : styles.statusMobileHide
                    }
                  >
                    <li
                      key={item.id}
                      className={
                        status === item.id
                          ? styles.orderStatus_list_item_active
                          : styles.orderStatus_list_item
                      }
                    >
                      {document.body.dir === "rtl" ? item.name_ar : item.name}
                    </li>
                    <li
                      className={
                        status ? styles.divider_active : styles.divider
                      }
                    ></li>
                  </div>
                </>
              ))
            ) : status === 6 ? null : (
              <img
                src={activeStatusMobile ? checked : breadcrumbStyle(status)}
                className={styles.breadcrumb_img}
                alt="breadcrumb"
              />
            )}
          </ul>
        )}
        {activeStatusMobile && document.body.dir === "ltr" && (
          <div className={styles.breadcrumb_text}>{status_name}</div>
        )}
      </div>
    </>
  );
};

export default OrderStatus;
