import React from "react";
import { CategoriesCarousel } from "../components";

const Categories = () => {
  return (
    <div style={{ margin: "4vh 7vw" }}>
      <CategoriesCarousel />
    </div>
  );
};

export default Categories;
