import React , { useState , useEffect } from "react";

import { AccountMenu, ProductCard, EmptyCart } from "../../components";

import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

import { useTranslation } from "react-i18next";
import styles from "../../styles/Account.module.css";

import emptyCart from "../../assets/shop-bag-yallaFood.svg";
import userPrefService from "../../services/user-preferences";

const AccountFavourite = () => {
  const { user } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  useDocumentTitle("Account Favourites");
  // const [favouritedProducts, setFavouritedProducts] = useState([]);
  const { favouritedProducts } = useSelector(state => state.wishlist)
  console.log(favouritedProducts);

  // useEffect(() => {
  //   userPrefService
  //     .getFavourites()
  //     .then((res) => {
  //       setIsLoading(false);
  //       console.log(res);
  //       // setFavouritedProducts(res);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.account_main}>
        <AccountMenu active={"My Wishlist"} />
        <div className={styles.account_body}>
          <div className={styles.account_body_title}>
            <h1>{t("My Wishlist")}</h1>
          </div>
          {isLoading ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, minmax(300px, 1fr))",
                gridGap: "10px",
                marginTop: "10px",
              }}
            >
              {[1, 2, 3, 4, 5, 6].map((i) => (
                <div>
                  <Skeleton
                    key={i}
                    variant="rect"
                    width={300}
                    height={400}
                    animation="wave"
                    style={{ borderRadius: "20px", marginBottom: "10px" }}
                  />
                </div>
              ))}
            </div>
          ) : favouritedProducts.length ? (
            <div className={styles.accountFavourite_body_products}>
              {favouritedProducts.map((product) => (
                <div className={styles.accountFavourite_body_products_product}>
                  <ProductCard product={product} />
                </div>
              ))}
            </div>
          ) : (
            <EmptyCart
              imgSrc={emptyCart}
              link="/"
              linkTxt={t("Continue Shopping")}
            />
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AccountFavourite;
