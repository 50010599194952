import React, { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import { Skeleton } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { placeholderImg } from "../constants/placeholderImg";
import {
  filterByCategoryId,
  filterBySubCategoryId,
  filterByGroupId,
  filterOrSortProducts,
  resetTotalFilteredProducts,
  resetPageNumber,
} from "../store/products/productsSlice";
import { fetchProducts } from "../services/products.service";

import styles from "../styles/Navbar.module.css";

const CategoryMenu = () => {
  const dispatch = useDispatch();
  const { totalFilteredProducts, products } = useSelector(
    (state) => state.products
  );
  const navigate = useNavigate();
  const categoryId = window.location.search
    .split("category_id=")[1]
    ?.split("&")[0]
    ?.replace(/%20/g, " ");

  const [category, setCategory] = useState([]);
  const [menuBgColor, setMenuBgColor] = useState("");
  const [dropMenuBgColor, setDropMenuBgColor] = useState("");
  const [l1TxtColor, setL1TxtColor] = useState("");
  const [l2TxtColor, setL2TxtColor] = useState("");
  const [l3TxtColor, setL3TxtColor] = useState("");
  const [l1HoverColor, setL1HoverColor] = useState("");
  const [l2HoverColor, setL2HoverColor] = useState("");
  const [l3HoverColor, setL3HoverColor] = useState("");
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(0);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getCategories = async () => {
      const res = await axios.get(`${baseUrl}/home/menu`);
      try {
        setCategory(res.data.data.level1);
        setMenuBgColor(res.data.data.menu_background_color);
        setDropMenuBgColor(res.data.data.drop_menu_background_color);
        setL1TxtColor(res.data.data.level1_text_color);
        setL2TxtColor(res.data.data.level2_text_color);
        setL3TxtColor(res.data.data.level3_text_color);
        setL1HoverColor(res.data.data.level1_hover_color);
        setL2HoverColor(res.data.data.level2_hover_color);
        setL3HoverColor(res.data.data.level3_hover_color);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    getCategories();
  }, []);

  const handleClickCategory = (id) => {
    dispatch(resetPageNumber());
    dispatch(resetTotalFilteredProducts());
    dispatch(fetchProducts(1, {}, id, {}, {}, {}, 12));
    console.log(products);
    localStorage.setItem("products", JSON.stringify(products));

    console.log(id);
    navigate({
      pathname: "/products",
      search: `?category_id=${id}`,
    });
  };
  const handleDeals = () => {
    dispatch(fetchProducts(1, {}, {}, {}, {}, {}, 12, {}, {}, true, {}));
    navigate({
      pathname: "/products",
      search: `?promotions=true`,
    });
  };
  const handleClickSubCategory = ({ id, link, group_id }) => {
    dispatch(resetPageNumber());
    dispatch(filterBySubCategoryId(id));
    dispatch(resetTotalFilteredProducts());
    dispatch(fetchProducts(1, {}, {}, [id], {}, {}, 12));
    console.log(id);
    navigate(`${link}&group_id=${group_id}`);
  };
  const handleClickGroup = (id) => {
    dispatch(resetPageNumber());
    dispatch(resetTotalFilteredProducts());
    dispatch(filterByGroupId(id));
    dispatch(fetchProducts(1, {}, {}, {}, [id], {}, 12));
    console.log(id);
    navigate({
      pathname: "/products",
      search: `&group_id=${id}`,
    });
  };

  //extract the number after category_id in category url
  const extractCategoryId = (url) => {
    const id = url.split("category_id=")[1];
    return id;
  };

  const extractSubCategoryId = (url) => {
    const id = url.split("sub_category_id=")[1];
    return id;
  };
  const extractGroupId = (url) => {
    const id = url.split("group_id=")[1];
    return id;
  };
  // add the extracted id to an object
  const addExtractedId = (obj) => {
    const id = extractSubCategoryId(obj.link);
    return { ...obj, id };
  };

  // const selectedCategory = (id) => {
  //   const categoryUrl = window.location.search
  //     .split("category_id=")[1]
  //     ?.split("&")[0]
  //     ?.replace(/%20/g, " ");
  //   if (id === extractCategoryId(categoryUrl)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  //buttons to scroll horizontally  in the category menu
  const scrollLeftHandler = () => {
    const categoriesMenuUl = document.querySelector(".categoriesMenuUl");
    categoriesMenuUl.scrollLeft -= 100;
  };
  const scrollRightHandler = () => {
    const categoriesMenuUl = document.querySelector(".categoriesMenuUl");
    categoriesMenuUl.scrollLeft += 100;
  };

  return (
    <>
      {isLoading ? (
        <Skeleton
          animation="pulse"
          style={{
            borderRadius: "20px",
            marginTop: "1rem",
            marginInlineEnd: "1rem",
          }}
          variant="rect"
          width={1024}
          height={20}
        />
      ) : (
        <ul
          className={styles.categoriesMenuUl}
          onScroll={(e) => {
            const categoriesMenuUl =
              document.querySelector(".categoriesMenuUl");
            if (categoriesMenuUl?.scrollLeft === 0) {
              setScrollLeft(0);
            } else {
              setScrollLeft(1);
            }
            if (
              categoriesMenuUl?.scrollLeft ===
              categoriesMenuUl?.scrollWidth - categoriesMenuUl?.clientWidth
            ) {
              setScrollRight(0);
            } else {
              setScrollRight(1);
            }
          }}
        >
          {category.map((categoryL1, index) => (
            <div key={index}>
              <li
                style={{ paddingLeft: "0" }}
                className={styles.catItem}
                key={categoryL1.id}
              >
                <div
                  onClick={() =>
                    categoryL1.name === "Home"
                      ? navigate("/")
                      : categoryL1.name === "Deals"
                      ? handleDeals()
                      : handleClickCategory(extractCategoryId(categoryL1.link))
                  }
                  className={styles.catItemTitle}
                  scroll-behavior="smooth"
                  style={
                    Number(categoryId) ===
                    Number(extractCategoryId(categoryL1.link))
                      ? {
                          backgroundColor: "#ff1d5214",
                          color: "#ff1d52",
                          borderRadius: "20px",
                          justifyContent: "center",
                          padding: "10px",
                        }
                      : null
                  }
                >
                  {document.body.dir === "rtl"
                    ? categoryL1.name_ar
                    : categoryL1.name}
                </div>
                {categoryL1.level2.length > 0 ? (
                  <ul className={styles.all_menu} key={categoryL1.id}>
                    <div className={styles.menu}>
                      {categoryL1.level2.map((categoryL2, index) => (
                        <div key={index}>
                          <li
                            className={styles.catItemSubItem}
                            key={categoryL2.id}
                          >
                            <div
                              className={styles.catItemSubItemLink}
                              onClick={() => handleClickGroup(categoryL2.id)}
                            >
                              {document.body.dir === "rtl"
                                ? categoryL2.name_ar
                                : categoryL2.name}
                            </div>
                            <ul className={styles.catItemSubItemSubUl}>
                              {categoryL2.level3.map((categoryL3, index) => (
                                <div key={index}>
                                  <li
                                    className={styles.catItemSubItemSubItem}
                                    key={categoryL3.id}
                                  >
                                    <div
                                      onClick={() =>
                                        handleClickSubCategory({
                                          id: categoryL3.id,
                                          group_id: categoryL2.id,
                                          link: categoryL3.link,
                                        })
                                      }
                                      className={
                                        styles.catItemSubItemSubItemLink
                                      }
                                    >
                                      {document.body.dir === "rtl"
                                        ? categoryL3.name_ar
                                        : categoryL3.name}
                                    </div>
                                  </li>
                                </div>
                              ))}
                            </ul>
                          </li>
                        </div>
                      ))}
                    </div>

                    <img
                      src={categoryL1.image ? categoryL1.image : placeholderImg}
                      alt={categoryL1.name}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = placeholderImg;
                      }}
                      width={300}
                      height={300}
                      className={styles.imageMenu}
                    />
                  </ul>
                ) : null}
              </li>
            </div>
          ))}
        </ul>
      )}
    </>
  );
};

export default CategoryMenu;
