import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  filterByCategoryId,
  filterBySubCategoryId,
  filterByGroupId,
} from "../store/products/productsSlice";
import { fetchProducts } from "../services/products.service";

import HamburgerMenuIcon from "../assets/hamburger-menu.svg";
import locationIcon from "../assets/location-icon.svg";
import SignIn_white from "../assets/SignIn_white.svg";

import styles from "../styles/OffCanvasMenu.module.css";
import userPrefService from "../services/user-preferences";
import { useFormik } from "formik";
import * as Yup from "yup";
import { profileFetching, startFetching } from "../store/init/initSlice";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "react-bootstrap/Button";

// const { user } = useSelector((state) => state.auth);
// console.log(user);
const OffCanvasExample = ({ name, ...props }) => {
  const config = JSON?.parse(localStorage.getItem("config"));
  const { t, i18n } = useTranslation();
  const { profile, cart } = useSelector((state) => state.init);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [menuBgColor, setMenuBgColor] = useState("");
  const [dropMenuBgColor, setDropMenuBgColor] = useState("");
  const [l1TxtColor, setL1TxtColor] = useState("");
  const [l2TxtColor, setL2TxtColor] = useState("");
  const [l3TxtColor, setL3TxtColor] = useState("");
  const [l1HoverColor, setL1HoverColor] = useState("");
  const [l2HoverColor, setL2HoverColor] = useState("");
  const [l3HoverColor, setL3HoverColor] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  ////////////////////////// Multi Inventory //////////////////////////
  const [cities, setCities] = useState([]);
  const [chosenArea, setChosenArea] = useState([]);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [messageLocation, setMessageLocation] = useState("");
  const [selectedLoc, setSelectedLoc] = useState("");

  const handleCloseMap = () => setShow(false);
  const handleShowMap = () => setShow(true);
  useEffect(() => {
    if (
      (profile?.area === null || profile?.area?.active === false) &&
      config?.ENABLE_MULTI_INVENTORY
    ) {
      handleShowMap();
    }
  }, [config?.ENABLE_MULTI_INVENTORY, profile?.area, profile?.area?.active]);

  useEffect(() => {
    userPrefService.cities().then((res) => {
      console.log(res);
      setCities(res);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      city: "",
      area: "",
      city_id: "",
      area_id: "",
    },
    validationSchema: Yup.object({
      city: Yup.string().required(
        document.body.dir === "rtl" ? "مطلوب" : "Required"
      ),
      area: Yup.string().required(
        document.body.dir === "rtl" ? "مطلوب" : "Required"
      ),
    }),
    onSubmit: (values) => {
      const { city, area, city_id, area_id } = values;
      setIsLoadingLocation(true);
      console.log(values);
      userPrefService
        .set_area({ city_id: city_id, area_id: area_id })
        .then((res) => {
          setIsLoadingLocation(false);
          setSelectedLoc(res?.data?.area?.name);
          localStorage.setItem("area=location", JSON.stringify(res));
          setMessageLocation(res.message);
          console.log(res);
          if (res.code === 200) {
            dispatch(startFetching());
            dispatch(profileFetching(res.data));
            handleCloseMap();
          }
        });
    },
  });

  const handleCityChange = (e) => {
    const selectedCity = cities.find((city) => city.name === e.target.value);
    setChosenArea(selectedCity.areas);
    const selectedGovernate = cities.find(
      (governate) => governate.name === e.target.value
    );
    formik.setFieldValue("city_id", selectedGovernate.id);
    formik.handleChange(e);
  };
  const handleAreaChange = (e) => {
    const selectedArea = chosenArea.find(
      (area) => area.name === e.target.value
    );
    formik.setFieldValue("area_id", selectedArea.id);
    formik.handleChange(e);
  };

  ///////////////////////////// Multi Inventory //////////////////////////

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleChange2 = (panel) => (event, isExpanded2) => {
    setExpanded2(isExpanded2 ? panel : false);
  };

  useEffect(() => {
    const getCategories = async () => {
      const res = await axios.get(`${baseUrl}/home/menu`);
      try {
        setCategory(res.data.data.level1);
        setMenuBgColor(res.data.data.menu_background_color);
        setDropMenuBgColor(res.data.data.drop_menu_background_color);
        setL1TxtColor(res.data.data.level1_text_color);
        setL2TxtColor(res.data.data.level2_text_color);
        setL3TxtColor(res.data.data.level3_text_color);
        setL1HoverColor(res.data.data.level1_hover_color);
        setL2HoverColor(res.data.data.level2_hover_color);
        setL3HoverColor(res.data.data.level3_hover_color);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setIsLoading(false);
      }
    };
    getCategories();
  }, []);
  const handleClickCategory = (id) => {
    dispatch(filterByCategoryId(id));
    dispatch(fetchProducts({}, {}, id, {}, {}, {}, 12));
    console.log(id);
    navigate({
      pathname: "/products",
      search: `?category_id=${id}`,
    });
    handleClose();
  };
  const handleClickSubCategory = (id) => {
    dispatch(filterBySubCategoryId(id));
    dispatch(fetchProducts({}, {}, {}, id, {}, {}, 12));
    console.log(id);
    navigate({
      pathname: "/products",
      search: `?subCategory_id=${id}`,
    });
    handleClose();
  };
  const handleClickGroup = (id) => {
    dispatch(filterByGroupId(id));
    dispatch(fetchProducts({}, {}, {}, {}, id, {}, 12));
    console.log(id);
    navigate({
      pathname: "/products",
      search: `?group_id=${id}`,
    });
    handleClose();
  };
  const handleDeals = () => {
    dispatch(fetchProducts(1, {}, {}, {}, {}, {}, 12, {}, {}, true, {}));
    navigate({
      pathname: "/products",
      search: `?promotions=true`,
    });
  };

  //extract the number after category_id in category url
  const extractCategoryId = (url) => {
    const id = url.split("category_id=")[1];
    return id;
  };

  const extractSubCategoryId = (url) => {
    const id = url.split("sub_category_id=")[1];
    return id;
  };
  const extractGroupId = (url) => {
    const id = url.split("group_id=")[1];
    return id;
  };

  // add the extracted id to an object
  const addExtractedId = (obj) => {
    const id = extractSubCategoryId(obj.link);
    return { ...obj, id };
  };

  const goHome = () => {
    navigate("/");
    handleClose();
  };
  // const init = JSON.parse(localStorage.getItem("init"));

  return (
    <>
      <div onClick={handleShow} className="me-2">
        <img
          className={styles.mobileMenuIcon}
          src={HamburgerMenuIcon}
          alt="menu-mobile"
        ></img>
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        {...props}
        //on rtl
        placement={document.body.dir === "rtl" ? "end" : "start"}
      >
        <Offcanvas.Header
          style={{
            backgroundColor: "#ff1d521a",
            padding: "7px 10px",
            margin: 0,
          }}
          closeButton 
        >
          <Offcanvas.Title>
            <div className={styles.offCanvasNav}>
              {config?.ENABLE_SOFTBAR && (
                <div className={styles.offCanvasNavTxt}>{t("stickyMsg")}</div>
              )}
              <div
                style={{ color: "#ff1d52", textDecoration: "none" }}
                onClick={() => {
                  navigate(
                    profile?.is_guest === false
                      ? "/customer/account"
                      : "/customer/account/signin"
                  );
                  handleClose();
                }}
              >
                <div className={styles.offCanvasNavSignIn}>
                  <img src={SignIn_white} alt="sign-in" />
                  <span className={styles.offCanvasNavSignTxt}>
                    {profile?.is_guest !== true
                      ? `${t("hi")}
                        , ${profile?.name}`
                      : ` ${t("sign_in")}`}
                  </span>
                </div>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            padding: "1rem 0",
          }}
        >
          <div className={styles.menuMobileBody}>
            <ul className={styles.categoriesMenuUl}>
              <li className={styles.menuMobileBodyItem}>
                {config?.ENABLE_MULTI_INVENTORY && (
                  <div
                    style={{ display: "flex" }}
                    className={styles.menuMobileBodyItemLink}
                  >
                    <div className={styles.menuMobileBodyItemLinkIcon}>
                      <img src={locationIcon} alt="location" />
                    </div>
                    <div className={styles.menuMobileBodyItemLinkTitle}>
                      {t("Deliver to")}
                      <span>
                        <a
                          href="/#"
                          onClick={
                            config?.ENABLE_MULTI_INVENTORY
                              ? handleShowMap
                              : null
                          }
                        >
                          {profile?.area?.name
                            ? document.body.dir === "rtl"
                              ? profile?.area?.name_ar
                              : profile?.area?.name
                            : t("select_area_textBtn")}
                        </a>
                      </span>
                      <Modal
                        className={styles.ModalLocationAll}
                        open={show}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box
                          sx={{
                            bgcolor: "background.paper",
                            boxShadow: 24,
                            borderRadius: "20px",
                            p: 4,
                            width: "fit-content",
                            margin: "2rem auto",
                          }}
                        >
                          <h3 style={{ fontWeight: "600" }}>
                            {t("Select Area")}
                          </h3>
                          <div
                            style={{
                              borderRaduis: "30px",
                            }}
                          >
                            {cart?.length > 0 ? (
                              <div className={styles.modalCart}>
                                {t("Changing your area will clear your cart")}
                              </div>
                            ) : null}
                            <form
                              onSubmit={formik.handleSubmit}
                              style={{ borderRadius: "30px" }}
                            >
                              <div className={styles.NavLocationModal}>
                                <div className={styles.NavLocationModalDesc}>
                                  {/* Choose your area so that we can easily estimate the
                          delivery time and cost for you */}
                                  {t("select_area_info")}
                                </div>
                                <div className={styles.NavLocationModalDetails}>
                                  <div className={styles.GovernLocation}>
                                    <div className={styles.GovernLocationTitle}>
                                      {t("Government*")}
                                    </div>
                                    <div className={styles.addNew_input}>
                                      <select
                                        name="city"
                                        id="city"
                                        onChange={handleCityChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.city}
                                      >
                                        <option value="" disabled selected>
                                          {t("Select Governate")}
                                        </option>
                                        {cities.map((item) => (
                                          <option
                                            value={item.name}
                                            id={item.id}
                                          >
                                            {document.body.dir === "rtl"
                                              ? item.name_ar
                                              : item.name}
                                          </option>
                                        ))}
                                      </select>
                                      {formik.touched.city &&
                                        formik.errors.city && (
                                          <div className={styles.error}>
                                            {formik.errors.city}
                                          </div>
                                        )}
                                    </div>
                                    <div className={styles.addNew_input}>
                                      <div
                                        className={styles.addNew_label}
                                        htmlFor="areas"
                                      >
                                        Areas
                                      </div>

                                      <select
                                        name="area"
                                        id="area"
                                        onChange={handleAreaChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.area}
                                      >
                                        <option value="" disabled selected>
                                          {t("Select Area")}
                                        </option>
                                        {chosenArea.map((city) => (
                                          <option value={city.name}>
                                            {document.body.dir === "rtl"
                                              ? city.name_ar
                                              : city.name}
                                          </option>
                                        ))}
                                      </select>
                                      {formik.touched.area &&
                                        formik.errors.area && (
                                          <div className={styles.error}>
                                            {formik.errors.area}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Button
                                type="submit"
                                className={styles.ModalLocationBtn}
                                disabled={formik.isSubmitting}
                                // onClick={handleClose}
                              >
                                {isLoadingLocation ? (
                                  <span className="spinner-border spinner-border-sm"></span>
                                ) : (
                                  <span>{t("confirm_location_textBtn")}</span>
                                )}
                              </Button>
                            </form>
                          </div>
                          <div className={styles.ModalFooter}></div>
                        </Box>
                      </Modal>
                    </div>
                  </div>
                )}
              </li>

              {category.map((categoryL1, index) => (
                <>
                  <li key={index} className={styles.catItem}>
                    <Accordion
                      expanded={
                        category.length > 0
                          ? expanded === `panel${index}`
                          : false
                      }
                      onChange={
                        category.length > 0
                          ? handleChange(`panel${index}`)
                          : null
                      }
                      style={{
                        backgroundColor: "transparent",
                        boxShadow: "none",
                      }}
                      key={categoryL1.id}
                    >
                      <AccordionSummary
                        expandIcon={
                          categoryL1?.level2.length > 0 ? (
                            <ExpandMoreIcon />
                          ) : null
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        style={{
                          backgroundColor: "transparent",
                          boxShadow: "none",
                        }}
                        key={categoryL1.id}
                      >
                        <div
                          onClick={() => {
                            categoryL1.name === "Home"
                              ? goHome()
                              : categoryL1.name === "Deals"
                              ? handleDeals()
                              : handleClickCategory(
                                  extractCategoryId(categoryL1.link)
                                );
                            handleClose();
                          }}
                          className={styles.catItemTitle}
                        >
                          {document.body.dir === "rtl"
                            ? categoryL1.name_ar
                            : categoryL1.name}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          ".MuiAccordionDetails-root": {
                            padding: "0px",
                          },
                        }}
                      >
                        {categoryL1?.level2?.length > 0 && (
                          <ul className={styles.catItemSubUl}>
                            {categoryL1?.level2?.map((subCategory) => (
                              <Accordion
                                // if click on category, it will be expanded and show sub categories not expanded
                                expanded={
                                  categoryL1?.level2?.length > 0
                                    ? expanded2 === `panel_L2${subCategory.id}`
                                    : null
                                }
                                onChange={
                                  categoryL1?.level2?.length > 0
                                    ? handleChange2(`panel_L2${subCategory.id}`)
                                    : null
                                }
                                style={{
                                  backgroundColor: "transparent",
                                  boxShadow: "none",
                                }}
                                key={subCategory.id}
                              >
                                <li
                                  key={subCategory.id}
                                  className={styles.catItemSubLi}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2bh-content"
                                    id="panel2bh-header"
                                    key={subCategory.id}
                                  >
                                    <div
                                      onClick={() =>
                                        handleClickSubCategory(
                                          extractSubCategoryId(subCategory.link)
                                        )
                                      }
                                      className={styles.catItemSubTitle}
                                    >
                                      {document.body.dir === "rtl"
                                        ? subCategory.name_ar
                                        : subCategory.name}
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      ".MuiAccordionDetails-root": {
                                        padding: "0px",
                                      },
                                    }}
                                  >
                                    {subCategory?.level3?.length > 0 && (
                                      <ul className={styles.catItemSubUl}>
                                        {expanded2 ===
                                          `panel_L2${subCategory.id}` &&
                                          subCategory?.level3?.map(
                                            (subCategory) => (
                                              <li
                                                key={subCategory.id}
                                                className={styles.catItemSubLi}
                                              >
                                                <div
                                                  onClick={() =>
                                                    handleClickCategory(
                                                      extractCategoryId(
                                                        subCategory.link
                                                      )
                                                    )
                                                  }
                                                  className={
                                                    styles.catItemSubTitle
                                                  }
                                                >
                                                  {document.body.dir === "rtl"
                                                    ? subCategory.name_ar
                                                    : subCategory.name}
                                                </div>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    )}
                                  </AccordionDetails>
                                </li>
                              </Accordion>
                            ))}
                          </ul>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </li>
                </>
              ))}

              <div className={styles.divider}></div>
              <li className={styles.menuMobileBodyItem}>
                <div className={styles.menuMobileBodyItemLink_support}>
                  <div className={styles.menuMobileBodyItemLinkTitle_support}>
                    {t("Change Language")}
                  </div>
                  <div
                    className={styles.lang}
                    onClick={() => {
                      document.body.dir = i18n.dir() === "rtl" ? "ltr" : "rtl";
                      //if the language is arabic change it to english and vice versa
                      i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
                      localStorage.setItem(
                        "lang",
                        i18n.language === "ar" ? "en" : "ar"
                      );
                      // toggle document.body.id from "en" to "ar" and vice versa
                      document.body.id = i18n.language === "ar" ? "en" : "ar";
                    }}
                    style={
                      document.body.dir === "ltr"
                        ? { fontFamily: "Vazirmatn, sans-serif" }
                        : { fontFamily: "CircularStd-Black" }
                    }
                  >
                    {t("lang")}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default OffCanvasExample;
