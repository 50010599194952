import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TimelineBar } from "../../components";
import { cartItemsFetching } from "../../store/cart/cartSlice";
import { updateGuestProfile, login, loginGuest } from "../../auth/authSlice";
import styles from "../../styles/Checkout.module.css";
import mastercard from "../../assets/masterCard.svg";
import visa from "../../assets/visa.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import userPrefService from "../../services/user-preferences";
import { initialize } from "../../services/initialize";
import { Skeleton } from "@mui/material";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { userValidationSignInSchema } from "../../Validations/UserValidationSignIn";
import { clearMessage } from "../../auth/message";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addressesFetching,
  deliveryAddressFetching,
} from "../../store/init/initSlice";
import { placeholderImg } from "../../constants/placeholderImg";

const CheckoutDelivery = () => {
  const config = JSON?.parse(localStorage.getItem("config"));

  useDocumentTitle(
    document.body.dir === "rtl"
      ? `الشراء | ${config?.APP_NAME_AR}`
      : `Checkout Delivery | ${config?.APP_NAME}`
  );
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { message } = useSelector((state) => state.message);
  const { profile } = useSelector((state) => state.init);
  const [open, setOpen] = useState(true);

  const [deliveryAddress, setDeliveryAddress] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [activeAddress, setActiveAddress] = useState(false);
  const [signupMsg, setSignupMsg] = useState("");
  const [loginMsg, setLoginMsg] = useState("");
  const [loadingCart, setLoadingCart] = useState(false);
  const [loadingSignin, setLoadingSignin] = useState(false);
  const [loadingGuest, setLoadingGuest] = useState(false);

  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const { addresses, isLoading } = useSelector((state) => state.init);
  const init_token = localStorage.getItem("init_token");

  const phoneRegExp = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
  const { localization } = useSelector((state) => state.init);

  useEffect(() => {
    dispatch(clearMessage());
    if (addresses.find((address) => address?.primary === true)) {
      setActiveAddress(true);
      setDeliveryAddress(
        addresses.find((address) => address?.primary === true)
      );
    }
  }, [addresses, dispatch]);

  const formikSignin = useFormik({
    initialValues: {
      email: "",
      password: "",
      phone: "",
    },
    onSubmit: (values) => {
      const { email, password, phone } = values;
      setLoadingSignin(true);

      dispatch(
        loginGuest({
          email,
          password,
          phone,
          token: profile?.is_guest && profile?.token,
        })
      )
        .unwrap()
        .then(() => {
          console.log("logged in");
          setLoadingSignin(false);
          console.log(message);
        })
        .catch(() => {
          setLoadingSignin(false);
          console.log("error");
          console.log(message);
          formikSignin.resetForm();
        });
    },
    // validateOnChange: true,
    // validateOnBlur: true,
    validationSchema: yup.object({
      email: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
          document.body.dir === "rtl"
            ? "البريد الإلكتروني غير صالح"
            : "Invalid email address"
        ),
      password: yup
        .string()
        .min(
          6,
          document.body.dir === "rtl"
            ? "كلمة المرور قصيرة جدا"
            : "Password is too short - should be 6 chars minimum."
        )
        .required(
          document.body.dir === "rtl"
            ? "كلمة المرور مطلوبة"
            : "Password is required"
        ),
      phone: yup
        .string()
        .typeError(
          document.body.dir === "rtl"
            ? "رقم الهاتف غير صالح"
            : "Invalid phone number"
        )
        //matches regex for phone 10 digits or 11 digits
        .matches(/^\d+$/, {
          message:
            document.body.dir === "rtl"
              ? "رقم الهاتف غير صالح"
              : "Invalid phone number",
        })
        .min(
          10,
          document.body.dir === "rtl"
            ? "رقم الهاتف هو 10 على الأكثر"
            : "Phone number is 10 at most"
        )
        .max(
          11,
          document.body.dir === "rtl"
            ? "رقم الهاتف هو 11 على الأكثر"
            : "Phone number is 11 at most"
        )
        .required(
          document.body.dir === "rtl"
            ? "رقم الهاتف مطلوب"
            : "Phone number is required"
        ),
    }),
  });

  const formikSignupGuest = useFormik({
    initialValues: {
      name_guest: "",
      last_name_guest: "",
      email_guest: "",
      phone_guest: "",
      token: localStorage.getItem("init_token")
        ? localStorage.getItem("init_token")
        : "",
    },
    onSubmit: (values) => {
      const { name_guest, last_name_guest, email_guest, phone_guest, token } =
        values;
      setLoadingGuest(true);

      dispatch(
        updateGuestProfile({
          name: name_guest,
          last_name: last_name_guest,
          email: email_guest,
          phone: phone_guest,
          token: token,
        })
      )
        .unwrap()
        .then((res) => {
          console.log(res);
          setLoadingGuest(false);
          dispatch(initialize());
          setSignupMsg(res.message);
          // navigate("/checkout/delivery");
        })
        .catch((err) => {
          console.log(err);
          setLoadingGuest(false);
        });
    },
    validationSchema: yup.object({
      name_guest: yup
        .string()
        //without a lot of space
        .matches(
          /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
          document.body.dir === "rtl" ? "الاسم غير صالح" : "Invalid name"
        )
        .required(
          document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is required"
        ),
      last_name_guest: yup
        .string()
        .required(
          document.body.dir === "rtl"
            ? "اسم العائلة مطلوب"
            : "Last name is required"
        ),
      email_guest: yup
        .string()
        .email()
        .required(
          document.body.dir === "rtl"
            ? "البريد الإلكتروني مطلوب"
            : "Email is required"
        ),
      phone_guest: yup
        .string()
        .typeError(
          document.body.dir === "rtl"
            ? "رقم الهاتف غير صالح"
            : "Invalid phone number"
        )
        //matches regex for phone 10 digits or 11 digits
        .matches(/^\d+$/, {
          message:
            document.body.dir === "rtl"
              ? "رقم الهاتف غير صالح"
              : "Invalid phone number",
        })
        .min(
          10,
          document.body.dir === "rtl"
            ? "رقم الهاتف هو 10 على الأكثر"
            : "Phone number is 10 at most"
        )
        .max(
          11,
          document.body.dir === "rtl"
            ? "رقم الهاتف هو 11 على الأكثر"
            : "Phone number is 11 at most"
        )
        .required(
          document.body.dir === "rtl"
            ? "رقم الهاتف مطلوب"
            : "Phone number is required"
        ),
    }),
  });

  // console.log(message);

  useEffect(() => {
    setLoadingCart(true);
    userPrefService.getCart().then((res) => {
      setLoadingCart(false);
      setCartProducts(res);
      dispatch(cartItemsFetching(res));
      if (res.length === 0) {
        navigate(-1);
      } else {
        navigate("/checkout/delivery");
      }
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(initialize());
    setLoading(false);
  }, []);

  const TotalFees = ({ label, value }) => {
    return (
      <div className={styles.totalFees}>
        <div className={styles.totalFees__title}>
          <div>{label}</div>
        </div>
        <div className={styles.totalFees__price}>
          <div
            className={
              value === "Calculated at next step"
                ? styles.totalFees__price_value__disabled
                : styles.totalFees__price_value
            }
          >
            {value}
          </div>
        </div>
      </div>
    );
  };

  // const Field = ({ label, type, placeholder, htmlFor }) => (
  //   <div className={styles.addNew_input}>
  //     <div className={styles.addNew_label} htmlFor={htmlFor}>
  //       {label}
  //     </div>
  //     <input placeholder={placeholder} type={type} id={type} />
  //   </div>
  // );

  // on check the radio button the address will be set to the deliveryAddress state
  const handleAddress = (address) => {
    if (addresses.find((address) => address?.primary === true)) {
      setDeliveryAddress(
        addresses.find((address) => address?.primary === true)
      );
    }

    setDeliveryAddress(address);
    setActiveAddress(true);
    dispatch(deliveryAddressFetching(address));
    localStorage.setItem("deliveryAddress", JSON.stringify(address));

    console.log(activeAddress);
  };

  const addressSlice = (add) => {
    if (add?.length > 25) {
      return add.substring(0, 25) + "...";
    } else {
      return add;
    }
  };
  const addressToaster = () => {
    if (deliveryAddress?.area_id === null) {
      toast.error(t("This address is not working ,please select another one"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      navigate("/checkout/payment");
    }
  };

  return (
    <>
      {/* <Navbarr /> */}
      <div
        style={{ backgroundColor: "rgb(248, 248, 246)", padding: "2vh 7vw" }}
        className={styles.checkout_all}
      >
        <div className={styles.all_container}>
          <div className={styles.checkout_container}>
            <div className={styles.delivery_header}>
              <TimelineBar />
            </div>
            {profile?.name?.length > 0 ? (
              <div className={styles.checkout_container}>
                <div className={styles.checkout}>
                  <div className={styles.checkout__header}>
                    <div className={styles.checkout__header__title}>
                      {t("Shipping")}
                    </div>
                  </div>
                  <div className={styles.checkout__body}>
                    {isLoading ? (
                      [1, 2, 3].map((item) => (
                        <Skeleton
                          variant="rectangular"
                          key={item}
                          width={500}
                          height={100}
                          style={{
                            borderRadius: "20px",
                            marginBottom: "20px",
                            maxWidth: "80vw",
                            maxHeight: "100%",
                          }}
                        />
                      ))
                    ) : addresses && addresses.length > 0 ? (
                      loading ? (
                        <Skeleton
                          variant="rectangular"
                          width={500}
                          height={500}
                        />
                      ) : (
                        addresses.map((address) => (
                          <div
                            className={styles.checkout_body_box}
                            key={address.id}
                            style={
                              config?.ENABLE_MULTI_INVENTORY
                                ? address?.area?.name !== profile?.area?.name
                                  ? { opacity: "0.5" }
                                  : { opacity: "1" }
                                : null
                            }
                          >
                            <div className={styles.checkout_body_box_right}>
                              <div
                                className={styles.checkout_body_box_right_icon}
                              >
                                <input
                                  type="radio"
                                  name="address"
                                  id={address.id}
                                  onChange={() => handleAddress(address)}
                                  disabled={
                                    config?.ENABLE_MULTI_INVENTORY
                                      ? address?.area?.name !==
                                        profile?.area?.name
                                        ? true
                                        : false
                                      : false
                                  }
                                  defaultChecked={
                                    address?.primary ? true : false
                                  }
                                  dir={document.body.dir === "rtl" ? "rtl" : ""}
                                />
                              </div>
                              <div
                                className={
                                  styles.checkout_body_box_right_details
                                }
                              >
                                <div
                                  className={
                                    styles.checkout_body_box_right_details_title
                                  }
                                >
                                  {t("DELIVER TO:")}
                                </div>
                                <div
                                  className={
                                    styles.checkout_body_box_right_details_address
                                  }
                                >
                                  {document.body.dir === "rtl"
                                    ? addressSlice(address?.area?.name_ar)
                                    : addressSlice(address?.area?.name)}
                                  {","}
                                  {document.body.dir === "rtl"
                                    ? addressSlice(address?.city?.name_ar)
                                    : addressSlice(address?.city?.name)}
                                  {","}
                                  {addressSlice(address.address)}
                                </div>
                              </div>
                            </div>
                            <div
                              className={styles.checkout_body_box_left}
                              onClick={() =>
                                address?.area?.name !== profile?.area?.name
                                  ? config?.ENABLE_MULTI_INVENTORY
                                    ? null
                                    : navigate(
                                        `/checkout/delivery/editAdd/${address.id}`
                                      )
                                  : navigate(
                                      `/checkout/delivery/editAdd/${address.id}`
                                    )
                              }
                              style={
                                address?.area?.name !== profile?.area?.name
                                  ? config?.ENABLE_MULTI_INVENTORY
                                    ? { cursor: "unset" }
                                    : { cursor: "pointer" }
                                  : { cursor: "pointer" }
                              }
                            >
                              {t("Edit")}
                            </div>
                          </div>
                        ))
                      )
                    ) : (
                      <div>{t("Add New Address")}</div>
                    )}

                    <Link
                      to="/checkout/delivery/newAdd"
                      className={styles.checkout_body_box_addAddress}
                    >
                      <div>&#43; {t("Add New Address")}</div>
                    </Link>
                    <div className={styles.checkout_btns_group}>
                      <Link
                        to="/cart"
                        className={styles.checkout_btns_group_btn_none}
                      >
                        &#60; {t("Back to cart")}
                      </Link>

                      <Link
                        // disable the button if the address is not selected
                        to={
                          deliveryAddress?.area_id
                            ? "/checkout/payment"
                            : "/checkout/delivery"
                        }
                        className={styles.checkout_btns_group_btn}
                        disabled={activeAddress ? false : true}
                        onClick={() => {
                          localStorage.setItem(
                            "deliveryAddress",
                            JSON.stringify(deliveryAddress)
                          );
                          addressToaster();
                        }}
                        style={
                          activeAddress
                            ? { backgroundColor: "#FF1D52" }
                            : {
                                backgroundColor: "#FF1D52",
                                opacity: "0.5",
                                cursor: "not-allowed",
                              }
                        }
                        //if the delivery address is not set the button will be disabled
                      >
                        {t("Continue to payment")}
                      </Link>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
              </div>
            ) : isGuest === false ? (
              <form
                onSubmit={formikSignin.handleSubmit}
                style={{ whiteSpace: "nowrap" }}
              >
                <div className={styles.checkout_login}>
                  <div className={styles.checkout_login_title}>
                    <div className={styles.checkout_login_title_text}>
                      {t("Login")}
                    </div>
                    <div
                      style={{ fontSize: "12px", color: "#b3b3b3" }}
                      className={styles.checkout_login_title_text}
                    >
                      {t("Don't have an account ?")}
                      <span>
                        <Link to="/customer/account/signup">
                          {t("Sign up")}
                        </Link>
                      </span>
                    </div>
                  </div>
                  <div className={styles.checkout_login_body}>
                    <div className={styles.addNew_input}>
                      <div
                        className={styles.addNew_label}
                        htmlFor={config?.enablePhoneLogin ? "Phone" : "Email"}
                      >
                        {config?.enablePhoneLogin ? t("Phone") : t("Email")}
                      </div>
                      <input
                        name={config?.enablePhoneLogin ? "phone" : "email"}
                        placeholder=""
                        inputMode={
                          config?.enablePhoneLogin ? "number" : "email"
                        }
                        id={config?.enablePhoneLogin ? "phone" : "email"}
                        // just accept numbers
                        //  pattern="[0-9]+"
                        //put regex to accept only 11 numbers
                        type={config?.enablePhoneLogin ? "number" : "email"}
                        maxLength={
                          config?.enablePhoneLogin
                            ? localization?.phone_length
                            : "50"
                        }
                        onChange={formikSignin.handleChange}
                        onBlur={formikSignin.handleBlur}
                        value={formikSignin.values.phone}
                        key={config?.enablePhoneLogin ? "phone" : "email"}
                      />
                      {config?.enablePhoneLogin
                        ? formikSignin.touched.phone &&
                          formikSignin.errors.phone && (
                            <div style={{ color: "red" }}>
                              {formikSignin.errors.phone}
                            </div>
                          )
                        : formikSignin.touched.email &&
                          formikSignin.errors.email && (
                            <div style={{ color: "red" }}>
                              {formikSignin.errors.email}
                            </div>
                          )}
                    </div>
                    <div className={styles.addNew_input}>
                      <div className={styles.addNew_label} htmlFor="password">
                        {t("Password")}
                      </div>
                      <input
                        placeholder="********"
                        type="password"
                        id="password"
                        onChange={formikSignin.handleChange}
                        onBlur={formikSignin.handleBlur}
                        value={formikSignin.values.password}
                      />
                      {formikSignin.touched.password &&
                        formikSignin.errors.password && (
                          <div style={{ color: "red" }}>
                            {formikSignin.errors.password}
                          </div>
                        )}
                      <div style={{ textAlign: "end" }}>
                        <a
                          href="/customer/account/forgotpassword"
                          className={styles.SignInForm_forgotPassword}
                        >
                          {t("Forgot password?")}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles.checkout_btns_group}>
                    <Link
                      to="/cart"
                      className={styles.checkout_btns_group_btn_none}
                    >
                      &#60; {t("Back to cart")}
                    </Link>
                    <button
                      className={styles.checkout_btns_group_btn}
                      type="submit"
                    >
                      {loadingSignin ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <span>{t("Login")}</span>
                      )}
                    </button>
                  </div>
                  {message ? (
                    message !== "Success" ? (
                      <Box sx={{ width: "fit-content", overflowX: "hidden" }}>
                        <Collapse in={open}>
                          <Alert severity="error" sx={{ mb: 2 }}>
                            {message}
                          </Alert>
                        </Collapse>
                      </Box>
                    ) : (
                      window.location.reload()
                    )
                  ) : null}
                  <div className={styles.checkout_guest}>
                    <p>{t("OR")}</p>
                    <div className={styles.checkout_guest_text}>
                      {t("Order without an account")}
                    </div>

                    <button
                      className={styles.checkout_guest_btn}
                      onClick={() => setIsGuest(true)}
                      style={{ color: "#FF1D52", backgroundColor: "white" }}
                    >
                      {t("GUEST CHECKOUT")}
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div className="signup-styles" style={{ padding: "7vh 10vw" }}>
                <form
                  onSubmit={formikSignupGuest.handleSubmit}
                  className={styles.SignUpForm}
                >
                  <div className={styles.addNew_input}>
                    {/* <div className={styles.addNew_label} htmlFor="name">
                      {t("First Name")}
                    </div> */}
                    <input
                      placeholder={t("First Name")}
                      name="name_guest"
                      type="text"
                      id="name_guest"
                      //just accept letters
                      pattern="[A-Za-z]+"
                      onChange={formikSignupGuest.handleChange}
                      onBlur={formikSignupGuest.handleBlur}
                      value={formikSignupGuest.values.name_guest}
                      key="name_guest"
                    />
                    {formikSignupGuest.touched.name_guest &&
                      formikSignupGuest.errors.name_guest && (
                        <div style={{ color: "red" }}>
                          {formikSignupGuest.errors.name_guest}
                        </div>
                      )}
                  </div>
                  <div className={styles.addNew_input}>
                    {/* <div className={styles.addNew_label} htmlFor="email">
                      {t("Last Name")}
                    </div> */}
                    <input
                      placeholder={t("Last Name")}
                      name="last_name_guest"
                      type="text"
                      id="last_name_guest"
                      //just accept letters
                      pattern="[A-Za-z]+"
                      onChange={formikSignupGuest.handleChange}
                      onBlur={formikSignupGuest.handleBlur}
                      value={formikSignupGuest.values.last_name_guest}
                      key="last_name_guest"
                    />
                    {formikSignupGuest.touched.last_name_guest &&
                      formikSignupGuest.errors.last_name_guest && (
                        <div style={{ color: "red" }}>
                          {formikSignupGuest.errors.last_name_guest}
                        </div>
                      )}
                  </div>
                  <div className={styles.addNew_input}>
                    {/* <div className={styles.addNew_label} htmlFor="phone">
                      {t("Phone Number")}
                    </div> */}
                    <input
                      name="phone_guest"
                      placeholder={t("Phone Number")}
                      inputMode="numeric"
                      id="phone_guest"
                      // just accept numbers
                      pattern="[0-9]+"
                      //put regex to accept only 11 numbers
                      maxLength="11"
                      onChange={formikSignupGuest.handleChange}
                      onBlur={formikSignupGuest.handleBlur}
                      value={formikSignupGuest.values.phone_guest}
                      key="phone_guest"
                    />
                    {formikSignupGuest.touched.phone_guest &&
                      formikSignupGuest.errors.phone_guest && (
                        <div style={{ color: "red" }}>
                          {formikSignupGuest.errors.phone_guest}
                        </div>
                      )}
                  </div>
                  <div className={styles.addNew_input}>
                    {/* <div className={styles.addNew_label} htmlFor="email">
                      {t("Email")}
                    </div> */}
                    <input
                      name="email_guest"
                      placeholder={t("Email")}
                      type="email"
                      id="email_guest"
                      onChange={formikSignupGuest.handleChange}
                      onBlur={formikSignupGuest.handleBlur}
                      value={formikSignupGuest.values.email_guest}
                      key="email_guest"
                    />
                    {formikSignupGuest.touched.email_guest &&
                      formikSignupGuest.errors.email_guest && (
                        <div style={{ color: "red" }}>
                          {formikSignupGuest.errors.email_guest}
                        </div>
                      )}
                  </div>
                  <button
                    type="submit"
                    disabled={loading}
                    className={styles.checkout_btns_group_btn}
                  >
                    {loadingGuest ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <span>{t("Register As Guest")}</span>
                    )}
                  </button>{" "}
                  <div className={styles.checkout_login_title_text}>
                    <p>
                      {t("Already have an account?")}
                      <a href="/customer/account/signin">{t("Sign In")}</a>
                    </p>
                  </div>
                </form>
                {message ? (
                  message !== "Success" ? (
                    <Box sx={{ width: "100%" }}>
                      <Collapse in={open}>
                        <Alert severity="error" sx={{ mb: 2 }}>
                          {message}
                        </Alert>
                      </Collapse>
                    </Box>
                  ) : isLoggedIn ? (
                    console.log("success", message)
                  ) : (
                    localStorage.removeItem("user")
                  )
                ) : null}
              </div>
            )}
          </div>
          <div className={styles.cart_body__right_products}>
            <div className={styles.cart_body__right_products__payment}>
              <div className={styles.cart_body__right_total}>
                <div className={styles.cart_body__right_total__header}>
                  {t("Payment Summary")}
                </div>
                <div className={styles.cart_body__right_total__fees}>
                  {loadingCart ? (
                    [1, 2, 3].map((item) => (
                      <Skeleton
                        variant="rectangular"
                        key={item}
                        width={300}
                        height={50}
                        style={{ borderRadius: "20px", marginBottom: "20px" }}
                      />
                    ))
                  ) : (
                    <>
                      <TotalFees
                        label={t("Subtotal")}
                        value={`${
                          Math.round(cartProducts?.grand_total * 100) / 100
                        } ${t(" EUR")}`}
                      />
                      <TotalFees
                        label={t("Shipping Fees")}
                        value={t("Calculated at next step")}
                      />
                      <div
                        className={styles.cart_divider}
                        style={{ backgroundColor: "#ff1d52" }}
                      ></div>

                      <TotalFees
                        label={t("Total Taxes included")}
                        value={`${
                          Math.round(cartProducts?.grand_total * 100) / 100
                        } ${t(" EUR")}`}
                      />
                    </>
                  )}
                </div>
                <div className={styles.cart_credit}>
                  <img src={mastercard} alt="masterCard" />
                  <img src={visa} alt="visa" />
                </div>
              </div>
            </div>
            <div className={styles.cart_body__right_products__summary}>
              <div className={styles.cart_body__right_total__header}>
                {t("Products Summary")}
              </div>
              {loadingCart
                ? [1, 2, 3].map((item) => (
                    <Skeleton
                      variant="rectangular"
                      key={item}
                      width={400}
                      height={100}
                      style={{ borderRadius: "20px", marginBottom: "20px" }}
                    />
                  ))
                : cartProducts?.items?.map((item) => (
                    <>
                      <div className={styles.cart_body__right_products_product}>
                        <div className={styles.products__img}>
                          <img
                            src={item.image}
                            alt={item.name}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = placeholderImg;
                            }}
                          />
                        </div>
                        <div className={styles.products__details}>
                          <div className={styles.products__details_title}>
                            {document.body.dir === "rtl"
                              ? item.name_ar
                              : item.name}
                          </div>
                          <div className={styles.products__details_qty}>
                            {t("QTY") + item.amount}
                          </div>
                          <div className={styles.products__details_prices}>
                            <div
                              className={
                                styles.products__details_prices_newPrice
                              }
                            >
                              {item?.discount_price !== null || undefined
                                ? t("Exclusive Price") +
                                  ": " +
                                  item?.discount_price
                                : item.price}{" "}
                              {t("EUR")}
                            </div>

                            <div
                              className={
                                styles.products__details_prices_oldPrice
                              }
                            >
                              {item?.discount_price !== null || undefined
                                ? item.price + t(" EUR")
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.cart_divider}></div>
                    </>
                  ))}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default CheckoutDelivery;
