import React from "react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";

import styles from "./../styles/AddressBox.module.css";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { useTranslation } from "react-i18next";
import edit from "../assets/edit.svg";
import basket from "../assets/basket.svg";
import userPrefService from "../services/user-preferences";

const AddressBox = ({
  name,
  phone,
  address,
  address_id,
  age,
  governate,
  area,
  street,
  building,
  floor,
  apartment,
  landmark,
  city_id,
  area_id,
}) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  // if path ends with /edit
  const path_url_addresses = window.location.pathname.endsWith("/addresses");
  console.log("path_url_addresses", path_url_addresses);

  const handleDelete = () => {
    userPrefService
      .deleteAddress(address_id)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setMessage(res.message);
        console.log("deleted");
        if (window.location.pathname.endsWith("/addresses")) {
          navigate("/customer/addresses");
          window.location.reload();
        } else if (window.location.pathname.endsWith("/account")) {
          navigate("/customer/account");
          window.location.reload();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        setMessage(err);
      });
  };
  console.log(
    address_id,
    phone,
    address,
    age,
    governate,
    area,
    street,
    building,
    floor,
    apartment,
    landmark,
    city_id,
    area_id
  );
  const actions = [
    {
      name: "Edit",
      name_ar: "تعديل",
      link: `/customer/addresses/${address_id}`,
      icon: edit,
      action_id: 1,
    },
    {
      name: "Delete",
      name_ar: "حذف",
      link: handleDelete,
      icon: basket,
      action_id: 2,
    },
  ];
  const [message, setMessage] = React.useState("");

  const addressSlice = (add) => {
    if (add.length > 35) {
      return add.substring(0, 35) + "...";
    } else {
      return add;
    }
  };

  return (
    <>
      <div className={styles.address_box}>
        <div className={styles.address_box__title}>
          <h5>{t("Address Name")}</h5>
        </div>
        <div className={styles.address_box__content}>
          <div className={styles.address_box__content__data}>
            <div className={styles.address_box__content__data__label}>
              {t("Address")}
            </div>
            <div className={styles.address_box__content__data__value}>
              {addressSlice(address)}
            </div>
          </div>
          <div className={styles.address_box__content__actions}>
            {actions.map((action, index) => (
              <div
                key={index}
                className={styles.address_box__content__actions__action}
                // style={{marginInlineEnd: "unset"}}
              >
                <img
                  src={action.icon}
                  alt={action.name}
                  className={styles.address_box__content__actions__action__icon}
                  style={{ width: "20px" }}
                />
                {action.action_id === 2 ? (
                  <Button
                    onClick={action.link}
                    className={
                      styles.address_box__content__actions__action__name
                    }
                    style={{
                      color: "#425466",
                      fontSize: "0.9rem",
                      fontWeight: "bold",
                      padding: 0,
                      textTransform: "none",
                      margin: 0,
                      textDecoration: "underline",
                      fontFamily: "unset",
                    }}
                  >
                    {document.body.dir === "rtl" ? action.name_ar : action.name}
                  </Button>
                ) : (
                  <Link
                    to={action.link}
                    className={
                      styles.address_box__content__actions__action__name
                    }
                  >
                    {document.body.dir === "rtl" ? action.name_ar : action.name}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {message ? (
        <Box sx={{ width: "50%", marginTop: "1rem" }}>
          <Collapse in={open}>
            <Alert
              severity={message.includes("Success") ? "success" : "error"}
              sx={{ mb: 2 }}
            >
              {message}
            </Alert>
          </Collapse>
        </Box>
      ) : null}
    </>
  );
};

export default AddressBox;
