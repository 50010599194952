import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage , setSocialMessage } from "./message";
import AuthService from "../services/auth.service";

const user = localStorage.getItem("user");


console.log(localStorage.getItem("user"));

export const register = createAsyncThunk(
  "signup",
  async (
    { name, last_name, phone, email, password},
    thunkAPI
  ) => {
    try {
      const response = await AuthService.register(
        name,
        last_name,
        phone,
        email,
        password,
      );
      thunkAPI.dispatch(setMessage(response?.message));
      console.log(response.data);
      // localStorage.setItem("user with token", JSON.stringify(response.data.data.token));
      response?.data?.data
        ? localStorage.setItem("user", JSON.stringify(response?.data?.data))
        : localStorage.removeItem("user");
      console.log(response.message);
      return { user: response?.data?.data };
    } catch (error) {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.response.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
    }
  }
);

export const login = createAsyncThunk(
  "signin",
  async ({ email, phone, password }, thunkAPI) => {
    try {
      const data = await AuthService.login(email, phone, password);
      thunkAPI.dispatch(setMessage(data.message));
      console.log(data);
      const userData = data.data;
      userData
        ? localStorage.setItem("user", JSON.stringify(userData))
        : localStorage.removeItem("user");

      // console.log(userData);
      // console.log("local Storage "+ JSON.parse(localStorage.getItem("user")));
      return { user: userData };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const loginGuest = createAsyncThunk(
  "signinGuest",
  async ({ email, phone, password , token }, thunkAPI) => {
    try {
      const data = await AuthService.loginGuest(email, phone, password, token);
      thunkAPI.dispatch(setMessage(data.message));
      console.log(data);
      const userData = data.data;
      userData
        ? localStorage.setItem("user", JSON.stringify(userData))
        : localStorage.removeItem("user");

      // console
      // console.log("local Storage "+ JSON.parse(localStorage.getItem("user")));
      return { user: userData };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);


export const loginWithSocial = createAsyncThunk(
  "signinSocial",
  async ({ access_token, provider }, thunkAPI) => {
    try {
      console.log(access_token, provider);
      const data = await AuthService.loginSocial(access_token, provider);
      thunkAPI.dispatch(setSocialMessage(data.message));
      console.log(data);
      data?.data?.data
        ? localStorage.setItem("user", JSON.stringify(data?.data?.data))
        : localStorage.removeItem("user");
      return { user: data.data.data };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message ||
        error.data.message ||
        error.toString();
      console.log(error?.data?.message);
      thunkAPI.dispatch(setSocialMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const registerWithSocial = createAsyncThunk(
  "signupSocial",
  async ({ email, last_name, name, phone, token }, thunkAPI) => {
    try {
      console.log(email, last_name, name, phone, token);
      const data = await AuthService.registerSocial(
        email,
        last_name,
        name,
        phone,
        token
      );
      thunkAPI.dispatch(setSocialMessage(data?.data?.message));
      console.log(data);
      // const userToken = data.data.token;
      // localStorage.setItem("user with token", userToken);
      // const userData = (data.data);
      data?.data?.data
        ? localStorage.setItem("user", JSON.stringify(data?.data?.data))
        : localStorage.removeItem("user");
      // console.log(userData);
      // console.log("local Storage "+ JSON.parse(localStorage.getItem("user")));
      // check if data.code === 200 then return data.data

      return {
        user: data?.data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message ||
        error.data.message ||
        error.toString();
      thunkAPI.dispatch(setSocialMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);
export const updateGuestProfile = createAsyncThunk(
  "updateGuestProfile",
  async ({ name, last_name, phone, email, token }, thunkAPI) => {
    try {
      console.log(name, last_name, phone, email, token);
      const data = await AuthService.updateGuestProfile(
        name,
        last_name,
        phone,
        email,
        token
      );
      thunkAPI.dispatch(setMessage(data?.message));
      console.log(data);
      // const userToken = data.data.token;
      // localStorage.setItem("user with token", userToken);
      // const userData = (data.data);
      data?.data
        ? localStorage.setItem("user", JSON.stringify(data?.data))
        : localStorage.removeItem("user");
      // console.log(userData);
      // console.log("local Storage "+ JSON.parse(localStorage.getItem("user")));
      // check if data.code === 200 then return data.data

      return {
        user: data?.data,
      };
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.response.message ||
        error.data.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  AuthService.logout();
  localStorage.removeItem("user with token");
  localStorage.removeItem("user");
  localStorage.removeItem("cartProducts");
  localStorage.removeItem("order_placed");
  localStorage.removeItem("deliveryAddress");
  localStorage.removeItem("orderPlaced");
  localStorage.removeItem("payment_type");
  localStorage.removeItem("address");
  localStorage.removeItem("init_token");
  return { user: null };
});

console.log("user with token", localStorage.getItem("user with token"));

const initialState = {
  isLoggedIn: user?.length > 0 ? true : false,
  user: user === undefined ? null : JSON.parse(user),
  message: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [register.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [register.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [loginWithSocial.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [updateGuestProfile.fulfilled]: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    [updateGuestProfile.rejected]: (state, action) => {
      state.isLoggedIn = false;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [loginWithSocial.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
