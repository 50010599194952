import React from 'react'
import { Footer, Navbarr, SignInSignUp } from '../../components'
import  useDocumentTitle  from "../../hooks/useDocumentTitle";


const SignIn = () => {

  useDocumentTitle('Sign In')
  return (
    <>
   {/* <Navbarr /> */}
   <div className='signin-styles' style={{backgroundColor:"#f8f8f6",padding:"2vh 20vw"}}>
        <SignInSignUp />
      </div>
    {/* <Footer /> */}
   </>
  )
}

export default SignIn