import React, { useState, useEffect } from "react";
import styles from "../styles/Home.module.css";
import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import {
  ProductsCarousal,
  BrandsCarousal,
  CategoriesCarousel,
  Support,
  HeroSlider,
} from "../components/index.js";
import { Skeleton } from "@mui/material";

import useScrollTop from "../hooks/useScrollTop";

const Home = () => {
  useScrollTop();
  const [customAds1, setCustomAds1] = useState([]);
  const [customAds2, setCustomAds2] = useState([]);
  const [customAds3, setCustomAds3] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${baseUrl}/home/custom-ads`)
      .then((res) => {
        setLoading(false);
        //add first 2 elements to customAds1
        setCustomAds1(res.data.data.slice(0, 2));
        //add last 3 elements to customAds2
        setCustomAds2(res.data.data.slice(2, 4));
        //add last 3 elements to customAds3
        setCustomAds3(res.data.data.slice(4, 5));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <div className={styles.Home}>
        {/* <Navbarr /> */}
        <section className={styles.HeroSection}>
          <div className={styles.HeroSliderHeader}>
            <HeroSlider />
          </div>
          <div className={styles.HeroSeconday}>
            {loading ? (
              <Skeleton
                style={{ marginInlineEnd:"10px", borderRadius: "10px" }}
                variant="rectangular"
                width="100%"
                className={styles.HeroSeconday__Skeleton}
              />
            ) : (
              customAds1.map((ad) => (
                <div className={styles.Hero_1}>
                  <img
                    src={ad.image_web}
                    alt={ad.name}
                    className={styles.HeroImg_1}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "https://via.placeholder.com/100";
                    }}
                    style={{ borderRadius: "10px" }}
                  />
                </div>
              ))
            )}
          </div>
        </section>
        <BrandsCarousal />
        <CategoriesCarousel />
        <section className={styles.middleSection}>
          {loading ? (
            <Skeleton
              style={{ margin: "auto", borderRadius: "10px" }}
              variant="rectangular"
              width="100%"
              className={styles.HeroSeconday__Skeleton}
            />
          ) : (
            customAds3.map((ad) => (
              <img
                src={ad.image_web}
                alt={ad.name}
                className={styles.middleMainImg}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://via.placeholder.com/100";
                }}
              />
            ))
          )}

          <div className={styles.middleSeconday}>
            {customAds2.map((ad) => (
              <img
                src={ad.image_web}
                alt={ad.name}
                className={styles.middleImg_1}
                width={window.innerWidth > 768 ? "50%" : "100%"}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://via.placeholder.com/100";
                }}
              />
            ))}

            {/* <div className={styles.middle_2}>
              <img
                src={middle_img_2}
                alt="middle_img_2"
                className={styles.middleImg_2}
              />
            </div> */}
          </div>
        </section>
        <ProductsCarousal />

        <Support />
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default Home;
