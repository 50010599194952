import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favouritedProducts: [],
  isFavaorite: null,
  isLoading: false,
  error: "",
  isClicked: false,
  favouritesCount: 0,
  singleProductFavourite: null,
};
export const wishlistSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    startFetching(state) {
      state.isLoading = true;
    },
    favouritesProductsFetching(state, action) {
      state.favouritedProducts = action.payload;
      state.isLoading = false;
      state.favouritesCount = action.payload.length;
    },
    favSingleProduct(state, action) {
      state.singleProductFavourite = action.payload;
    },
    addToFavourite(state, action) {
      //if the product is not in the array, add it to the array
      if (
        state.favouritedProducts.find((item) => item.id !== action.payload.id)
      ) {
        state.favouritedProducts.push(action.payload);
        state.isFavaorite = true;
        state.isClicked = true;
        state.favouritesCount = state.favouritedProducts.length;
      }

      console.log(state.favouritedProducts);
      state.isFavaorite = true;
      console.log("Added to favourite", state.favouritedProducts);

      //if the product is in the array, remove it from the array
    },
    addToFavouriteSingleProduct(state, action) {
      state.singleProductFavourite = action.payload;
    },

    removeFromFavourite(state, action) {
      //if the product is in the array, remove it from the array
      state.favouritedProducts = state.favouritedProducts.filter(
        (item) => item.id !== action.payload.id
      );
      state.isFavaorite = false;
      state.isClicked = true;
      state.favouritesCount -= 1;
      console.log("Removed from favourite", state.favouritedProducts);
    },
    removeFromFavouriteSingleProduct(state, action) {
      state.singleProductFavourite = action.payload;
    },
  },
});
export default wishlistSlice.reducer;
export const {
  startFetching,
  addToFavourite,
  removeFromFavourite,
  favouritesProductsFetching,
  addToFavouriteSingleProduct,
  removeFromFavouriteSingleProduct,
  favSingleProduct,
} = wishlistSlice.actions;
