import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as ROUTES from "./routes.js";

import Home from "../src/pages/Home";
import ProductDetails from "./pages/ProductDetails";
import ProductsList from "./pages/ProductsList";
import AccountFavourites from "./pages/Overview page/AccountFavourites";
import GuestFavourites from "./pages/Overview page/GuestFavourites";
import SignIn from "./pages/SignInSignUp/SignIn";
import SignUp from "./pages/SignInSignUp/SignUp";
import SignupSocial from "./pages/SignInSignUp/SignupSocial";
import ForgotPassword from "./pages/SignInSignUp/ForgotPassword";
import AccountOrders from "./pages/Overview page/Account Orders/AccountOrders";
import AccountOrdersDetails from "./pages/Overview page/Account Orders/AccountOrdersDetails";
import ACCOUNTOVERVIEW from "./pages/Overview page/AccountOverview";
import ACCOUNTADDRESSES from "./pages/Overview page/Account Addresses/AccountAddresses";
import ACCOUNTNEWADDRESS from "./pages/Overview page/Account Addresses/AccountNewAddress";
import ACCOUNTPAYMENT from "./pages/Overview page/AccountPayment";
import ACCOUNTSETTINGS from "./pages/Overview page/Account Settings/AccountSettings";
import EDITADDRESS from "./pages/Overview page/Account Addresses/EditAddress";
import ChangePass from "./pages/Overview page/Account Settings/ChangePass";
import Cart from "./pages/Cart";
import CheckoutPayment from "./pages/Checkout page/CheckoutPayment";
import CheckoutDelivery from "./pages/Checkout page/CheckoutDelivery";
import OrderConfirmation from "./pages/Checkout page/OrderConfirmation";
import CheckoutNewAddress from "./pages/Checkout page/CheckoutNewAddress";
import OrderPlaced from "./pages/Checkout page/OrderPlaced";
import Faq from "./pages/Faq";
import { baseUrl } from "./constants/apiURL";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import RequireAuth from "./components/RequireAuth";
import { Footer, Navbarr } from "./components";
import userPrefService from "./services/user-preferences";
import { cartItemsFetching } from "./store/cart/cartSlice";
import { startFetching } from "./store/init/initSlice";
import i18n from "./i18n";
import { initialize } from "./services/initialize";
import CheckoutEditAddress from "./pages/Checkout page/CheckoutEditAddress";
import RefundPolicy from "./pages/Static Pages/RefundPolicy";
import TermsAndConditions from "./pages/Static Pages/TermsAndConditions";
import PrivacyPolicy from "./pages/Static Pages/PrivacyPolicy";
import Cookies from "./pages/Static Pages/Cookies";
import Categories from "./pages/Categories";

function App() {
  document.body.dir = i18n.dir();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize());
  }, [dispatch]);
  const [config, setConfig] = React.useState([]);
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.init);

  console.log(localStorage.getItem("user with token"));

  const configData = async () => {
    await axios
      .get(baseUrl + "/configurations")
      .then((res) => {
        setConfig(res.data);
        localStorage.setItem("config", JSON.stringify(res.data.data));
        const data = JSON.parse(localStorage.getItem("config"));
        const facebook_signin = data.FACEBOOK_CLIENT_ID;
        localStorage.setItem("facebook_signin", facebook_signin);
        const google_signin = data.GOOGLE_CLIENT_ID;
        localStorage.setItem("google_signin", google_signin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    profile?.token &&
      userPrefService.getCart().then((res) => {
        console.log(res);
        dispatch(cartItemsFetching(res));
      });
  }, [profile?.token, dispatch]);

  useEffect(() => {
    dispatch(startFetching());
    configData();
  }, [dispatch]);
  // console.log(init);

  // if direction is rtl, add id="ar" to body
  if (i18n.dir() === "rtl") {
    document.body.id = "ar";
    document.body.dir = "rtl";
  } else if (i18n.dir() === "ltr") {
    document.body.id = "en";
    document.body.dir = "ltr";
  }

  /// if there is no internet connection, show the error message
  if (!navigator.onLine) {
    <>
      <Navbarr />

      <div className="no-internet">
        <h1>There is no internet connection</h1>
      </div>
      <Footer />
    </>;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <>
          <Navbarr />
          <Routes>
            {/* ROUTE OF THE HOMEPAGE */}
            <Route path={ROUTES.HOME} element={<Home />} exact />
            <Route path="*" element={<Home />} />
            <Route path={ROUTES.FAQ} element={<Faq />} />
            {/* ROUTES OF THE PRODUCT/PRODUCTS PAGES */}
            <Route
              path={ROUTES.PRODUCTS_DETAILS}
              element={<ProductDetails />}
            />
            <Route path={ROUTES.PRODUCTSLIST} element={<ProductsList />} />
            <Route path={ROUTES.CATEGORIES} element={<Categories />} />
            {/* ROUTES FOR ACCOUNT OVERVIEW PAGE */}
            <Route
              path={ROUTES.ACCOUNTORDERS}
              element={
                <RequireAuth>
                  <AccountOrders />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ACCOUNTFAVOURITES}
              element={
                <RequireAuth>
                  <AccountFavourites />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.GUESTFAVOURITES}
              element={<GuestFavourites />}
            />
            <Route
              path={ROUTES.ACCOUNTORDERSDETAILS}
              element={
                <RequireAuth>
                  <AccountOrdersDetails />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ACCOUNTOVERVIEW}
              element={
                <RequireAuth>
                  <ACCOUNTOVERVIEW />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ACCOUNTPAYMENT}
              element={
                <RequireAuth>
                  <ACCOUNTPAYMENT />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ACCOUNTADDRESS}
              element={
                <RequireAuth>
                  <ACCOUNTADDRESSES />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ACCOUNTNEWADDRESS}
              element={
                <RequireAuth>
                  <ACCOUNTNEWADDRESS />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.EDITADDRESS}
              element={
                <RequireAuth>
                  <EDITADDRESS />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.ACCOUNTSETTINGS}
              element={
                <RequireAuth>
                  <ACCOUNTSETTINGS />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.CHANGEPASS}
              element={
                <RequireAuth>
                  <ChangePass />
                </RequireAuth>
              }
            />
            {/* ROUTES OF THE ACCOUNT */}
            <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
            <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
            <Route path={ROUTES.SIGN_UP_SOCIAL} element={<SignupSocial />} />
            <Route path={ROUTES.FORGOTPASSWORD} element={<ForgotPassword />} />
            {/* ROUTES OF THE CART PAGE AND CHECKOUT PAGE */}
            <Route path={ROUTES.CART} element={<Cart />} />
            <Route
              path={ROUTES.CHECKOUTPAYMENT}
              element={<CheckoutPayment />}
            />
            <Route
              path={ROUTES.CHECKOUTDELIVERY}
              element={<CheckoutDelivery />}
            />
            <Route
              path={ROUTES.ORDER_CONFIRMATION}
              element={<OrderConfirmation />}
            />
            <Route path={ROUTES.ORDER_PLACED} element={<OrderPlaced />} />
            <Route
              path={ROUTES.CHECKOUTNEWADDRESS}
              element={<CheckoutNewAddress />}
            />
            <Route
              path={ROUTES.CHECKOUTEDITADDRESS}
              element={<CheckoutEditAddress />}
            />
            <Route path={ROUTES.REFUNDPOLICY} element={<RefundPolicy />} />
            <Route
              path={ROUTES.TERMSANDCONDITIONS}
              element={<TermsAndConditions />}
            />
            <Route path={ROUTES.PRIVACY} element={<PrivacyPolicy />} />
            <Route path={ROUTES.COOKIES} element={<Cookies />} />
            {/* <Route
              path={ROUTES.COMPAREPRODUCTS}
              element={<CompareProducts />}
            /> */}
          </Routes>
          <Footer />
        </>
      </BrowserRouter>
    </div>
  );
}

export default App;
