import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";
import {
  addProduct,
  toggleItemAdded,
  increaseCount,
  decreaseCount,
  removeProduct,
  getCartItemQTY,
  toggleItemRemoved,
  setTotalSum,
} from "../store/cart/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import {
  addToFavourite,
  removeFromFavourite,
  favouritesProductsFetching,
} from "../store/wishlist/wishlistSlice";
import * as yup from "yup";

import styles from "../styles/ProductCard.module.css";
import salePercentImg from "../assets/salePercent.svg";
import wishlist from "../assets/wishList.svg";
import filledWishlist from "../assets/filled-heart.svg";
import cart from "../assets/whiteCart.svg";
import userPrefService from "../services/user-preferences";
import CompareIcon from "@mui/icons-material/Compare";
import Modal from "@mui/material/Modal";

import { startFetching } from "../store/init/initSlice";
import { cartItemsFetching } from "../store/cart/cartSlice";
import { useFormik } from "formik";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

const ProductCard = ({ product, loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.init);

  // const cartProducts = useSelector((state) => state.cartProducts);
  const { cartItems } = useSelector((state) => state.cartProducts);
  // const item = cartItems.items.find((item) => item.id === product.id);
  const { totalSum } = useSelector((state) => state.cartProducts);
  const { favouritedProducts } = useSelector((state) => state.wishlist);
  localStorage.getItem("cartProducts");
  // console.log(localStorage.getItem('cartProducts'));
  const [isAdded, setIsAdded] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [loadingCount, setLoadingCount] = useState(null);
  const [count, setCount] = useState(1);
  const [clicked, setClicked] = useState(false);
  const [messageNotify, setMessageNotify] = useState("");
  const [open, setOpen] = useState(true);
  const productAmount = cartItems?.items?.find(
    (item) => item.id === product.id
  )?.amount;

  // useEffect(() => {
  //   profile?.token &&
  //     userPrefService.getCart().then((res) => {
  //       console.log(res);
  //       dispatch(cartItemsFetching(res));
  //     });
  // }, [profile?.token, dispatch]);

  const [fav, setFav] = useState([]);
  const [carts, setCarts] = useState([]);
  const [emailNotify, setEmailNotify] = useState(
    profile?.is_guest === false ? profile?.email : ""
  );
  const [show, setShow] = useState(false);

  const localFav = JSON.parse(localStorage.getItem("init"));
  console.log(localFav);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const { favourites } = useSelector((state) => state.init);
  console.log(favourites);
  // const favourite_id = favourites.map(
  //   (favourite) => favourite.id === product.id
  // );

  console.log(favouritedProducts);
  useEffect(() => {
    setFav(favourites);
  }, [dispatch, favourites]);
  console.log(fav);

  const notifyMe = () => {
    console.log({ id: product.id, email: emailNotify });

    userPrefService
      .NotifyMe(product.id, { id: product.id, email: emailNotify })
      .then((res) => {
        console.log(res);
        setMessageNotify(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const notifyFormik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .required(
          document.body.dir === "rtl"
            ? "البريد الالكتروني مطلوب"
            : "Email is required"
        )
        //matches regex for email
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          document.body.dir === "rtl"
            ? "البريد الالكتروني غير صحيح"
            : "Invalid email address"
        )
        .email(
          document.body.dir === "rtl"
            ? "البريد الالكتروني غير صحيح"
            : "Invalid email address"
        ),
    }),
    onSubmit: (values) => {
      const { email } = values;
      console.log(values);
      userPrefService
        .NotifyMe(product.id, { id: product.id, email: email })
        .then((res) => {
          console.log(res);
          setMessageNotify(res.message);
          if (res.code === 200) {
            handleClose();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });
  const addToCart = () => {
    if (product.parent_id == null) {
      navigate(
        `/products/${product.slug}/${product.id}?variant=${product.product_variants[0].id}`
      );
    } else if (!cartItems?.items?.find((item) => item.id === product.id)) {
      dispatch(addProduct(product));
      dispatch(cartItemsFetching);
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: 1,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(setTotalSum(res.grand_total));

          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
      localStorage.setItem("cartProducts", JSON.stringify(cartItems));
      setIsAdded(true);
      // toast.success("Product added to cart");
    }
  };

  const placeholderImage =
    "https://nasijagaltangsel.websites.co.in/dummytemplate/img/product-placeholder.png";

  const increase = () => {
    if (productAmount === 1) {
      setCount((productAmount) => productAmount + 1);
      dispatch(increaseCount(product, productAmount + 1));
      setLoadingCount(product.id);
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: productAmount + 1,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));
          setLoadingCount(null);
          dispatch(setTotalSum(res.grand_total));
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setCount((productAmount) => productAmount + 1);
      dispatch(increaseCount(product, productAmount + 1));
      setLoadingCount(product.id);
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: productAmount + 1,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));
          setLoadingCount(null);
          dispatch(setTotalSum(res.grand_total));
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  const decrease = () => {
    if (productAmount > 1) {
      setLoadingCount(product.id);
      setCount((productAmount) => productAmount - 1);
      dispatch(decreaseCount(product, productAmount - 1));
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: productAmount - 1,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));
          setLoadingCount(null);
          dispatch(setTotalSum(res.grand_total));
          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (productAmount === 1) {
      dispatch(removeProduct(product));
      dispatch(toggleItemRemoved(true));
      dispatch(toggleItemAdded(false));
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: 0,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));

          dispatch(setTotalSum(res.grand_total));

          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const addToWishlist = () => {
    setClicked(true);
    setIsLoading(true);
    // dispatch(startFetching);
    dispatch(addToFavourite(product));
    userPrefService
      .addToFavourites(product.id)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setFav(res);
        dispatch(favouritesProductsFetching(res));
        setClicked(true);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        setClicked(true);
      });

    // setFav(favourites);
    console.log(fav);

    console.log(fav.includes(product));
  };

  /// favourite button animation

  const removeFromWishlist = () => {
    // dispatch(removeFromFavourite(product));
    setIsLoading(true);
    setClicked(false);
    dispatch(removeFromFavourite(product));
    userPrefService
      .removeFromFavourites(product.id)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        setClicked(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
    setFav([...fav.filter((item) => item.id !== product.id)]);

    console.log(fav.includes(product));
  };
  // const toggleWishlist = (item) => {
  //   if (fav.find((product) => product.id === item.id)) {
  //     removeFromWishlist(item);
  //     console.log(fav);
  //     // setClicked(false);
  //     //remove that product from the wishlist array
  //     // favouritedProducts.slice(favouritedProducts.indexOf(product), 1);
  //   } else {
  //     addToWishlist(item);
  //     console.log(fav);
  //     // setClicked(true);
  //   }
  // };
  // console.log(favouritedProducts);
  // if(itemAdded){
  //   dispatch(getCartItemQTY({product.id}));
  // }else { console.log("item not added"); }
  if (!product)
    return (
      <div>
        <Skeleton variant="rect" width={200} height={200} />
      </div>
    );

  // if product name is too long, truncate it to 50 characters
  const productName = (name) => {
    if (name.length > 60) {
      return name.substring(0, 60) + "...";
    } else {
      return name;
    }
  };

  const productLabel =
    product?.promotion?.length > 20
      ? product?.promotion?.substring(0, 20) + "..."
      : product.promotion;

  return (
    <div className={styles.ProductCardAnchor}>
      <div className={styles.ProductCardBody}>
        <div className={styles.ProductCardIcons}>
          <div
            onClick={() =>
              fav.find((item) => item.id === product.id)
                ? removeFromWishlist(product)
                : addToWishlist(product)
            }
            className={styles.ProductCardWishlist}
          >
            {isLoading ? (
              <Skeleton
                variant="circular"
                width={25}
                height={25}
                animation="wave"
              />
            ) : (
              <img
                src={
                  favouritedProducts.find((item) => item.id === product.id)
                    ? filledWishlist
                    : clicked
                    ? filledWishlist
                    : wishlist
                }
                alt="wishlist"
                className={styles.ProductCardWishImg}
              />
            )}
          </div>
          <div className={styles.Product_labels}>
            {product?.discount_price !== null &&
            !product?.promotion &&
            product.discount_price !== product.price ? (
              <div className={styles.ProductCardSalePercent}>
                <img
                  src={salePercentImg}
                  alt="salePercentage"
                  className={styles.ProductCardPercentImg}
                />
                <span className={styles.ProductCardPercentText}>
                  <span>
                    {document.body.dir === "rtl"
                      ? product?.promotion
                        ? product?.promotion_ar +
                          ` OR ` +
                          Math.round(
                            ((product.price - product.discount_price) /
                              product.price) *
                              100
                          )
                        : product?.promotion
                        ? product?.promotion_en +
                          ` OR ` +
                          Math.round(
                            ((product.price - product.discount_price) /
                              product.price) *
                              100
                          )
                        : Math.round(
                            ((product.price - product.discount_price) /
                              product.price) *
                              100
                          )
                      : Math.round(
                          ((product.price - product.discount_price) /
                            product.price) *
                            100
                        )}
                    {t(" % OFF")}
                  </span>
                </span>
              </div>
            ) : null}
            {!product.in_stock ? (
              <div className={styles.ProductCardSalePercent}>
                <span className={styles.ProductCardPercentText}>
                  {t("Out of Stock")}
                </span>
              </div>
            ) : null}
            {product.preorder && (
              <div className={styles.ProductCardSalePercent}>
                <span className={styles.ProductCardPercentText}>
                  {t("Preorder")}
                </span>
              </div>
            )}
            {product.promotion ? (
              <div className={styles.ProductCardSalePercent}>
                <span className={styles.ProductCardPercentText}>
                  {productLabel}
                </span>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.ProductCardImgContainer_imgAndData}>
          <Link
            to={`/products/${product.slug}/${product.parent_id}/?variant=${product.id}`}
          >
            <div
              className={styles.ProductCardImg}
              style={product.in_stock ? { opacity: 1 } : { opacity: 0.5 }}
            >
              {loading ? (
                <Skeleton variant="rect" width={200} height={500} />
              ) : (
                <img
                  src={product.image}
                  alt="ProductImg"
                  onError={(e) => {
                    e.target.src = placeholderImage;
                  }}
                  className={styles.ProductCardImg_mainImg}
                />
              )}
              {/* {product.images[0] ? (
                <img
                  src={product.images[0]}
                  alt="ProductImg"
                  onError={(e) => {
                    e.target.src = placeholderImage;
                  }}
                  className={
                    product.images[0] ? styles.ProductCardImg_hoverImg : null
                  }
                />
              ) : null} */}
            </div>
          </Link>
          <div className={styles.product_details}>
            <Link
              to={`/products/${product.slug}/${product.parent_id}/?variant=${product.id}`}
              className={styles.ProductCardTitle}
            >
              {document.body.dir === "rtl" ? product.name_ar : product.name}
            </Link>
            <div className={styles.ProductCardPrices}>
              <div className={styles.ProductCardNewPrice}>
                {product.discount_price
                  ? product.discount_price
                  : product.price}{" "}
                {t("EUR")}
              </div>
              <div className={styles.ProductCardOldPrice}>
                {product.discount_price ? `${product.price} EUR` : ""}
              </div>
            </div>

            <Modal
              className={styles.ModalLocationAll}
              open={show}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  borderRadius: "20px",
                  p: 4,
                  width: "fit-content",
                  margin: "2rem auto",
                }}
              >
                <h6 style={{ fontWeight: "600" }}>
                  {t("out_of_stock__message")}
                </h6>
                <form onSubmit={notifyFormik.handleSubmit}>
                  <div style={{ borderRadius: "20px" }}>
                    <div className={styles.NavLocationModal}>
                      <div className={styles.NavLocationModalDetails}>
                        <div className={styles.GovernLocation}>
                          <div className={styles.GovernLocationTitle}>
                            {t("Email")}
                          </div>
                          <input
                            className={styles.locationInput}
                            type="text"
                            name="email"
                            onChange={notifyFormik.handleChange}
                            onBlur={notifyFormik.handleBlur}
                            value={notifyFormik.values.email}
                          />
                          {notifyFormik.touched.email &&
                          notifyFormik.errors.email ? (
                            <div className={styles.error}>
                              {notifyFormik.errors.email}
                            </div>
                          ) : null}

                          {messageNotify ? (
                            messageNotify !== "Success" ? (
                              <Box
                                sx={{
                                  width: "100%",
                                  borderRadius: "20px",
                                  marginTop: "1rem",
                                }}
                              >
                                <Collapse in={open}>
                                  <Alert
                                    severity={
                                      messageNotify === "Success"
                                        ? "success"
                                        : "error"
                                    }
                                    sx={{ mb: 2 }}
                                  >
                                    {messageNotify}
                                  </Alert>
                                </Collapse>
                              </Box>
                            ) : null
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.ModalFooter}>
                    <button
                      className={styles.checkout_btns_group_btn}
                      type="submit"
                    >
                      {t("Notify me")}
                    </button>
                  </div>
                </form>
              </Box>
            </Modal>
          </div>
          {!cartItems?.items?.find((item) => item.id === product.id) ? (
            <div
              onClick={product.in_stock ? addToCart : handleShow}
              className={styles.ProductCardBuy}
            >
              <button className={styles.ProductCardBuyBtn}>
                {product.in_stock ? (
                  <img
                    src={cart}
                    alt="cart"
                    className={styles.ProductCardBuyIcon}
                  />
                ) : null}

                <span className={styles.ProductCardBuyText}>
                  {product.in_stock
                    ? t("Add to cart")
                    : product.preorder
                    ? t("Preorder")
                    : t("Notify me")}
                </span>
              </button>
              {/* <div className={styles.ProductCompare}>
                <CompareIcon color="black" />
              </div> */}
            </div>
          ) : (
            <div className={styles.addRemove}>
              <button
                onClick={() => decrease()}
                className={styles.removeProduct}
                style={
                  cartItems?.items?.find((item) => item.id === product.id)
                    ?.id === loadingCount
                    ? {
                        cursor: "not-allowed",
                        opacity: "0.5",
                        color: "#707070",
                      }
                    : { color: "#707070" }
                }
                disabled={
                  cartItems?.items?.find((item) => item.id === product.id)
                    ?.id === loadingCount
                    ? true
                    : false
                }
              >
                {/* // minus icon */}
                <RemoveIcon />
              </button>
              <div className={styles.productCount}>
                <span></span>
                {cartItems?.items?.find((item) => item.id === product.id)
                  ?.id === loadingCount ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <span>
                    {
                      cartItems?.items?.find((item) => item.id === product.id)
                        .amount
                    }
                  </span>
                )}
              </div>
              <button
                onClick={() =>
                  cartItems?.items?.find((item) => item.id === product.id)
                    .amount ===
                  cartItems?.items?.find((item) => item.id === product.id).stock
                    ? null
                    : increase()
                }
                className={styles.addProduct}
                style={
                  cartItems?.items?.find((item) => item.id === product.id)
                    .max_per_order
                    ? cartItems?.items?.find((item) => item.id === product.id)
                        .amount ===
                        cartItems?.items?.find((item) => item.id === product.id)
                          .max_per_order ||
                      cartItems?.items?.find((item) => item.id === product.id)
                        .amount >
                        cartItems?.items?.find((item) => item.id === product.id)
                          .max_per_order
                    : cartItems?.items?.find((item) => item.id === product.id)
                        .amount ===
                        cartItems?.items?.find((item) => item.id === product.id)
                          .stock ||
                      cartItems?.items?.find((item) => item.id === product.id)
                        .amount >
                        cartItems?.items?.find((item) => item.id === product.id)
                          .stock ||
                      cartItems?.items?.find((item) => item.id === product.id)
                        ?.id === loadingCount
                    ? { cursor: "not-allowed", opacity: "0.5" }
                    : null
                }
                disabled={
                  cartItems?.items?.find((item) => item.id === product.id)
                    ?.id === loadingCount
                    ? true
                    : false
                }
              >
                <AddIcon />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
