import React from "react";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import styles from "./../../../styles/Account.module.css";
import { useTranslation } from "react-i18next";
import { AccountMenu } from "../../../components";
import userPrefService from "../../../services/user-preferences";

const AccountNewAddress = () => {
  useDocumentTitle("Account New Addresses");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { localization, profile } = useSelector((state) => state.init);

  const [cities, setCities] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(true);
  const [primary, setPrimary] = React.useState(false);

  const [chosenArea, setChosenArea] = React.useState([]);

  React.useEffect(() => {
    userPrefService
      .cities()
      .then((res) => {
        console.log(res);
        setCities(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(governates);
  // console.log(cities);

  const formik = useFormik({
    initialValues: {
      name: profile?.is_guest ? "" : profile?.name,
      phone: profile?.is_guest ? "" : profile?.phone,
      governate: "",
      area: "",
      street: "",
      buildingNumber: "",
      floor: "",
      apartment: "",
      landmark: "",
      address: "",
      city_id: "",
      area_id: "",
      primary: false,
    },

    onSubmit: (values) => {
      const {
        name,
        phone,
        governate,
        area,
        street,
        buildingNumber,
        floor,
        apartment,
        landmark,
        address,
        city_id,
        area_id,
        primary,
      } = values;
      setIsLoading(true);
      console.log(values);
      userPrefService
        .addAddress({
          name: profile?.is_guest ? name : profile?.name,
          phone: phone,
          governate: governate,
          area: area,
          street: street,
          building: buildingNumber,
          floor: floor,
          apartment: apartment,
          landmark: landmark,
          address: address,
          city_id: city_id,
          area_id: area_id,
          primary: primary,
    })
        .then((res) => {
          if (res.code !== 200) {
            setIsLoading(false);
            console.log(res);
            setMessage(res?.message);
            console.log(res.message);
          } else {
            setIsLoading(false);
            console.log(res);
            setMessage(res?.message);
            navigate(-1);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(
        document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is required"
      ),
      phone: Yup.string()
        .typeError(
          document.body.dir === "rtl"
            ? "رقم الهاتف غير صحيح"
            : "Phone number is invalid"
        )
        //matches regex for phone 10 digits or 11 digits
        .matches(/^\d+$/, {
          message:
            document.body.dir === "rtl"
              ? "رقم الهاتف غير صحيح"
              : "Phone number is invalid",
        })
        .min(
          10,
          document.body.dir === "rtl"
            ? "رقم الهاتف 10 على الأقل"
            : "Phone number is 10 at least"
        )
        .max(
          11,
          document.body.dir === "rtl"
            ? "رقم الهاتف 11 على الأكثر"
            : "Phone number is 11 at most"
        )
        .required(
          document.body.dir === "rtl"
            ? "رقم الهاتف مطلوب"
            : "Phone number is required"
        ),
      address: Yup.string().required(
        document.body.dir === "rtl" ? "العنوان مطلوب" : "Address is required"
      ),
      // validate select fields
      governate: Yup.string().required(
        document.body.dir === "rtl"
          ? "المحافظة مطلوبة"
          : "Governorate is required"
      ),
      area: Yup.string().required(
        document.body.dir === "rtl" ? "المنطقة مطلوبة" : "Area is required"
      ),
      apartment: Yup.string().required(
        document.body.dir === "rtl" ? "الشقة مطلوبة" : "Apartment is required"
      ),
    }),
  });
  // generate array contains numbers from 1 to 100

  //when user select a city, the governates will be updated
  const handleCityChange = (e) => {
    const selectedCity = cities.find((city) => city.name === e.target.value);
    setChosenArea(selectedCity.areas);
    const selectedGovernate = cities.find(
      (governate) => governate.name === e.target.value
    );
    formik.setFieldValue("city_id", selectedGovernate.id);
    formik.handleChange(e);
  };
  const handleAreaChange = (e) => {
    const selectedArea = chosenArea.find(
      (area) => area.name === e.target.value
    );
    formik.setFieldValue("area_id", selectedArea.id);
    formik.handleChange(e);
  };
  console.log(formik.errors);

  return (
    <>
      <div className={styles.account_main}>
        <AccountMenu active={"My Address Book"} />
        <form onSubmit={formik.handleSubmit} className={styles.account_body}>
          <div className={styles.account_body}>
            <div className={styles.account_body_title}>
              <h1>{t("Add New Addresses")}</h1>
            </div>

            <div className={styles.addNew_fields__content}>
              {profile?.is_guest && (
                <>
                  <div className={styles.addNew_input}>
                    <div className={styles.addNew_label} htmlFor="name">
                      {t("Full Name*")}
                    </div>

                    <input
                      placeholder={t("Full Name*")}
                      type="text"
                      id="name"
                      className={styles.addNew_label}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className={styles.error}>{formik.errors.name}</div>
                    )}
                  </div>

                  <div className={styles.addNew_input}>
                    <div className={styles.addNew_label} htmlFor="phoneNumber">
                      {t("Phone Number*")}
                    </div>

                    <input
                      placeholder={t("Phone Number*")}
                      type="phone"
                      name="phone"
                      id="phone"
                      // maxlength={localization?.phone_length}
                      className={styles.addNew_label}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className={styles.error}>{formik.errors.phone}</div>
                    )}
                  </div>
                </>
              )}

              <div className={styles.addNew_fields__content_multipleFields}>
                <div className={styles.addNew_input}>
                  <div className={styles.addNew_label} htmlFor="governates">
                    {t("City*")}
                  </div>

                  <select
                    name="governate"
                    id="governate"
                    onChange={handleCityChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.governate}
                  >
                    <option value="" disabled selected>
                      {t("Select City")}
                    </option>
                    {cities.map((item) => (
                      <option value={item.name} id={item.id}>
                        {document.body.dir === "rtl" ? item.name_ar : item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.governate && formik.errors.governate && (
                    <div className={styles.error}>
                      {formik.errors.governate}
                    </div>
                  )}
                </div>
                <div
                  className={styles.addNew_input}
                  style={{ marginInlineEnd: "0" }}
                >
                  <div className={styles.addNew_label} htmlFor="areas">
                    {t("Area")}
                  </div>

                  <select
                    name="area"
                    id="area"
                    onChange={handleAreaChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.area}
                  >
                    <option value="" disabled selected>
                      {t("Select Area")}
                    </option>
                    {chosenArea.map((city) => (
                      <option value={city.name}>
                        {document.body.dir === "rtl" ? city.name_ar : city.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.area && formik.errors.area && (
                    <div className={styles.error}>{formik.errors.area}</div>
                  )}
                </div>

                <div
                  className={
                    styles.addNew_fields__content_multipleFields_select
                  }
                ></div>
              </div>

              <div className={styles.addNew_input}>
                <div className={styles.addNew_label} htmlFor="street">
                  {t("Street Name")}
                </div>

                <input
                  placeholder={t("Street Name")}
                  type="text"
                  id="street"
                  className={styles.addNew_label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.street}
                />
                {formik.touched.street && formik.errors.street && (
                  <div className={styles.error}>{formik.errors.street}</div>
                )}
              </div>
              <div className={styles.addNew_fields__content_multipleFields}>
                <div className={styles.addNew_input}>
                  <div className={styles.addNew_label} htmlFor="buildingNumber">
                    {t("Building Number")}
                  </div>

                  <input
                    placeholder={t("Building Number")}
                    type="text"
                    id="buildingNumber"
                    className={styles.addNew_label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.buildingNumber}
                  />
                  {formik.touched.buildingNumber &&
                    formik.errors.buildingNumber && (
                      <div className={styles.error}>
                        {formik.errors.buildingNumber}
                      </div>
                    )}
                </div>


                <div className={styles.addNew_input}>
                  <div className={styles.addNew_label} htmlFor="apartment">
                    {t("Apartment*")}
                  </div>

                  <input
                    placeholder={t("Apartment*")}
                    type="text"
                    id="apartment"
                    className={styles.addNew_label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.apartment}
                  />
                  {formik.touched.apartment && formik.errors.apartment && (
                    <div className={styles.error}>
                      {formik.errors.apartment}
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.addNew_input}>
                <div className={styles.addNew_label} htmlFor="landmark">
                  {t("Name on the doorbell")}
                </div>

                <input
                  placeholder={t("Name on the doorbell")}
                  type="text"
                  id="landmark"
                  className={styles.addNew_label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.landmark}
                />
                {formik.touched.landmark && formik.errors.landmark && (
                  <div className={styles.error}>{formik.errors.landmark}</div>
                )}
              </div>

              <div className={styles.addNew_input}>
                <div className={styles.addNew_label} htmlFor="address">
                  {t("Postal Code*")}
                </div>

                <input
                  placeholder={t("Postal Code*")}
                  type="text"
                  id="address"
                  className={styles.addNew_label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className={styles.error}>{formik.errors.address}</div>
                )}
              </div>

              <div className={styles.Payment_box__content__checkbox}>
                <input
                  type="checkbox"
                  //onClick change primary to true
                  id="primary"
                  value={formik.values.primary}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <span>{t("Set as default Address")}</span>
              </div>
            </div>
            <div className={styles.addNew_fields__content__buttons}>
              <button type="submit" className={styles.addNewCard}>
                {isLoading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <div className={styles.addNewCard_Btn__Text}>
                    {t("add_address")}
                  </div>
                )}
              </button>
              <button
                className={styles.addNewCardCancel}
                onClick={() => {
                  navigate(-1);
                }}
              >
                {t("Cancel")}
              </button>
            </div>
            {message ? (
              message !== "Success" ? (
                <Box sx={{ width: "50%", marginTop: "1rem" }}>
                  <Collapse in={open}>
                    <Alert
                      severity={
                        message.includes("Success") ? "success" : "error"
                      }
                      sx={{ mb: 2 }}
                    >
                      {message}
                    </Alert>
                  </Collapse>
                </Box>
              ) : (
                navigate("/customer/addresses")
              )
            ) : null}
          </div>
        </form>
      </div>
    </>
  );
};

export default AccountNewAddress;
