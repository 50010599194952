// ROUTE OF THE HOMEPAGE
export const HOME = '/';      
export const FAQ = '/faq';  

// ROUTES OF THE PRODUCT/PRODUCTS PAGES
export const PRODUCTS = '/products';        
// /products?category_id=671&sub_category_id=672
export const PRODUCTS_DETAILS = '/products/:variant/:parent_id';    // :id is a placeholder for the product id
// shows products in dynamic category and subcategory pages like /products?category_id=671&sub_category_id=672  

export const PRODUCTSLIST = '/products'; 
export const CATEGORIES = '/categories';

// ROUTES FOR ACCOUNT
export const SIGN_IN = '/customer/account/signin';   // sign in page
export const SIGN_UP = '/customer/account/signup';   // sign up page   
export const SIGN_UP_SOCIAL = '/customer/account/signup/social';   // sign up page
export const FORGOTPASSWORD = '/customer/account/forgotpassword';   // forgot password page

// ROUTES OF ACCOUNT OVERVIEW PAGE
export const ACCOUNTOVERVIEW = '/customer/account';   // account overview page
export const ACCOUNTPAYMENT = '/customer/payment';   // account payment page
export const ACCOUNTADDRESS = '/customer/addresses';   // account address page
export const ACCOUNTNEWADDRESS = '/customer/addresses/addnew' // account add new address page
export const ACCOUNTSETTINGS = '/customer/account/settings';   // account settings page
export const CHANGEPASS = '/customer/account/settings/changepass';   // account settings page
export const ACCOUNTFAVOURITES = '/customer/wishlist'; 
export const GUESTFAVOURITES = '/customer/wishlist/guest';        
export const ACCOUNTORDERS = '/customer/orders';  
export const ACCOUNTORDERSDETAILS = '/customer/orders/details/:id';  
export const EDITADDRESS = '/customer/addresses/:id'


// ROUTES OF THE CART PAGE AND CHECKOUT PAGE
export const CART = '/cart';   // cart page
export const CHECKOUTPAYMENT = '/checkout/payment';   // checkout page (payment)
export const CHECKOUTDELIVERY = '/checkout/delivery';   // checkout page (delivery)
export const ORDER_CONFIRMATION = '/checkout/order-confirmation';   // order confirmation page
export const ORDER_PLACED = '/checkout/order-placed';   // order placed page
export const CHECKOUTNEWADDRESS = '/checkout/delivery/newAdd';   // checkout page add new address
export const CHECKOUTEDITADDRESS = '/checkout/delivery/editAdd/:id';   // checkout page edit address



// ROUTES OF THE STATIC PAGES
export const REFUNDPOLICY = '/static-pages/refund-policy';   // refund Policy page
export const COOKIES = '/static-pages/cookie-policy';   // cookies page
export const PRIVACY = '/static-pages/privacy-policy';   // privacy page
export const TERMSANDCONDITIONS = '/static-pages/terms-and-conditions';   // termsAndConditions page


// ROUTES OF THE USER PREFERENCES IN THE NAVBAR 
export const COMPAREPRODUCTS = "/compare"

