import {
  errorFetching,
  startFetching,
  successFetching,
  getTotal,
} from "../store/products/productsSlice";
import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import authHeader from "./auth-header";

export const fetchProducts =
  (
    pageNumber,
    searchInput,
    category_id,
    sub_categories,
    group_id,
    in_stock,
    page_size,
    price_from,
    price_to,
    promotions,
    sort,
    brand_ids,
  ) =>
  async (dispatch) => {
    try {
      dispatch(startFetching());
      const response = await axios.post(
        ` ${baseUrl}/products/search`,
        {
          page: pageNumber,
          q: searchInput,
          category_id: category_id,
          sub_categories: sub_categories,
          group_id: group_id,
          page_size: page_size,
          in_stock: in_stock,
          price_from: price_from,
          price_to: price_to,
          promotions: promotions,
          sort: sort,
          brand_ids: brand_ids,
        },
        {
          headers: authHeader(),
        }
      );

      dispatch(successFetching(response.data.data));
      dispatch(getTotal(response.data.data.total_products));
    } catch (e) {
      dispatch(errorFetching(e.message));
    }
  };

// export const fetchProducts =
//   (
//     pageNumber,
//     searchInput,
//     attributes_ids,
//     brand_ids,
//     categories,
//     category_id,
//     group_id,
//     in_stock,
//     list_ids,
//     options_values_ids,
//     page_size,
//     price_from,
//     price_to,
//     promotions,
//     sort,
//     sub_categories
//   ) =>
//   async (dispatch) => {
//     try {
//       dispatch(startFetching());
//       const response = await axios.post(`${baseUrl}/products/search`, {
//         page: pageNumber,
//         q: searchInput,
//         attributes_ids: attributes_ids,
//         brand_ids: brand_ids,
//         categories: categories,
//         category_id: category_id,
//         group_id: group_id,
//         in_stock: in_stock,
//         list_ids: list_ids,
//         options_values_ids: options_values_ids,
//         page_size: page_size,
//         price_from: price_from,
//         price_to: price_to,
//         promotions: promotions,
//         sort: sort,
//         sub_categories: sub_categories,
//       });
//       console.log(response.data);
//       dispatch(successFetching(response.data.data.products));
//     } catch (e) {
//       dispatch(errorFetching(e.message));
//     }
//   };
