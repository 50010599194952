import React from "react";
import { Link } from "react-router-dom"
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  Navbarr,
  AccountMenu,
  Footer,
  PaymentBox,
  EmptyCart,
} from "../../components";
import styles from "../../styles/Account.module.css";

import emptyCredit from "../../assets/emptyCredit.svg";

const AccountPayment = () => {
  useDocumentTitle("Account Payment");
  const payments = [
    {
      name: "John Doe",
      cardNumber: "**** **** **** ****",
      expiryDate: "12/20",
      address: "123 Main St, New York, NY 10001",
    },
    {
      name: "John Doe",
      cardNumber: "**** **** **** ****",
      expiryDate: "12/20",
      address: "123 Main St, New York, NY 10001",
    },
  ];

  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.account_main}>
        <AccountMenu active={"Payment"} />
        <div className={styles.account_body} style={{width:"100%"}}>
          <div className={styles.account_body_title}>
            <h1>My Wallet</h1>
          </div>
          {payments.length > 0 ? (
            <div className={styles.addresses_body_content} style={{display:"block"}}>
              {payments.map((payment, index) => (
                <div className={styles.addresses_body_content_address}>
                  <PaymentBox
                    name={payment.name}
                    cardNumber={payment.cardNumber}
                    address={payment.address}
                    key={index}
                    expiryDate={payment.expiryDate}
                  />
                </div>
              ))}
            </div>
          ) : (
            <EmptyCart
              imgSrc={emptyCredit}
              link="/customer/payment/create"
              linkTxt="Add New Payment Method"
            />
          )}
          <Link to="addnew"><button className={styles.addNewCard}>
            <div className={styles.addNewCard_Btn__Text}>Add New Card</div>
          </button>
          </Link>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AccountPayment;
