import React from 'react'

import styles from '../styles/Support.module.css'
import support_1 from '../assets/support/support_1.svg'
import support_2 from '../assets/support/support_2.svg'
import support_3 from '../assets/support/support_3.svg'
import support_4 from '../assets/support/support_4.svg'

const Support = () => {

    const services = [
        {
            id: 1,
            title: '24/7 Support',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa purus gravida.',
            icon: support_1,
        },
        {
            id: 2,
            title: 'Fast Delivery',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa purus gravida.',
            icon: support_2,
        },
        {
            id: 3,
            title: 'Secure Payment',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa purus gravida.',
            icon: support_3,
        },
        {
            id: 4,
            title: 'Secure Payment',
            description: 'We accept all major credit cards',
            icon: support_4,
        }
    ]

  return (
    <div className={styles.Support}>
        {services.map(service => (
            <div className={styles.Service} key={service.id}>
                <div className={styles.IconWrapper}>
                    <img src={service.icon} alt="icon" />
                    </div>
                <div className={styles.Title}>
                    {service.title}
                    </div>
                <div className={styles.Description}>
                    {service.description}
                    </div>
                </div>
        ))}
    </div>
    )
}

export default Support