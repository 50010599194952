import React, { useState, UseFfect } from "react";
import styles from "../styles/Navbar.module.css";
import { useSelector, useDispatch } from "react-redux";
import { placeholderImg } from "../constants/placeholderImg";
import basket from "../assets/basket.svg";
import {
  removeProduct,
  toggleItemRemoved,
  increaseCount,
  decreaseCount,
  getQTYOfSingleProduct,
  toggleItemAdded,
  cartItemsFetching,
  setTotalSum,
} from "../store/cart/cartSlice";
import userPrefService from "../services/user-preferences";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const FloatingCart = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState(1);
  const [loadingCount, setLoadingCount] = useState(null);

  const [isAdded, setIsAdded] = useState(false);

  const { cartItems, totalSum, cartItemQTY } = useSelector(
    (state) => state.cartProducts
  );
  const increase = (product, qty) => {
    setLoadingCount(product.id);
    setCount((qty) => qty + 1);
    dispatch(increaseCount(product, qty));
    userPrefService
      .addCartItems({
        items: [
          {
            id: product.id,
            amount: qty + 1,
          },
        ],
        reset: false,
      })
      .then((res) => {
        dispatch(cartItemsFetching(res));
        setLoadingCount(null);
        dispatch(setTotalSum(res.grand_total));

        console.log(res);
      })
      .catch((err) => {
        setLoadingCount(null);
        console.error(err);
      });
  };

  const decrease = (product, qty) => {
    setLoadingCount(product.id);
    if (qty > 1) {
      setCount((qty) => qty - 1);
      dispatch(decreaseCount(product, qty));
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: qty - 1,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));
          dispatch(setTotalSum(res.grand_total));
          setLoadingCount(null);
          console.log(res);
        })
        .catch((err) => {
          setLoadingCount(null);
          console.error(err);
        });
    } else if (qty === 1) {
      dispatch(removeProduct(product));
      dispatch(toggleItemRemoved(true));
      dispatch(toggleItemAdded(false));
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: 0,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));

          dispatch(setTotalSum(res.grand_total));

          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setIsAdded(false);
    toggleItemAdded(false);
  };

  const handleRemove = (product) => {
    dispatch(removeProduct(product));
    dispatch(toggleItemRemoved(true));
    dispatch(toggleItemAdded(false));
    dispatch(getQTYOfSingleProduct(product));
    userPrefService
      .addCartItems({
        items: [
          {
            id: product?.id,
            amount: 0,
          },
        ],
        reset: false,
      })
      .then((res) => {
        dispatch(cartItemsFetching(res));
        dispatch(setTotalSum(res.grand_total));

        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
    console.log(cartItems);
  };
  const productName = (name) => {
    if (name.length > 20) {
      return name.slice(0, 20) + "...";
    } else {
      return name;
    }
  };

  return (
    <div className={styles.FloatingCart_wrapper}>
      <div
        className={styles.floating_cart_container}
        style={document.body.dir === "rtl" ? { left: "0" } : { right: "8%" }}
      >
        <div className={styles.floating_cart_header}>
          <h3>{t("My Cart")}</h3>
          <p>
            {t("You have")}{" "}
            {cartItemQTY
              ? cartItemQTY === 1
                ? null
                : cartItemQTY === 2
                ? null
                : cartItemQTY
              : 0}{" "}
            {cartItemQTY === 1
              ? t("items in your cart == 1")
              : cartItemQTY === 2
              ? t("items in your cart == 2")
              : cartItemQTY > 2 && cartItemQTY < 11
              ? t("items in your cart > 2 && < 10")
              : t("items in your cart > 10")}
          </p>
        </div>
        <div className={styles.floating_cart_body}>
          <div className={styles.cart_body__left}>
            {cartItems?.items?.length > 0 ? (
              cartItems?.items?.map((item) => (
                <div className={styles.cart_body__left__wrapper}>
                  <div key={item.id} className={styles.cart_body__left_items}>
                    <div className={styles.cart_body__left_product}>
                      <img
                        src={item.image}
                        alt={item.name}
                        className={styles.cart_body__left_product_image}
                        onError={(e) => {
                          e.target.src = placeholderImg;
                        }}
                        onClick={() =>
                          navigate(
                            `/products/${item.slug}/${item.parent_id}/?variant=${item.id}`
                          )
                        }
                      />
                      <div className={styles.cart_body__left_product_info}>
                        <div>
                          <h5>
                            {productName(
                              document.body.dir === "rtl"
                                ? item?.name_ar
                                : item.name
                            )}
                          </h5>{" "}
                        </div>
                        <div>
                          <div className={styles.addRemove}>
                            <button
                              onClick={() => decrease(item, item.amount)}
                              className={styles.removeProduct}
                              disabled={loadingCount === item.id ? true : false}
                              style={
                                loadingCount === item.id
                                  ? { cursor: "not-allowed", opacity: "0.5" }
                                  : null
                              }
                            >
                              <RemoveIcon />
                            </button>
                            <div className={styles.productCount}>
                              {loadingCount === item.id ? (
                                <span className="spinner-border spinner-border-sm"></span>
                              ) : (
                                <span>{item?.amount}</span>
                              )}
                            </div>
                            <button
                              onClick={() =>
                                item.amount === item.stock ||
                                item.amount > item.stock
                                  ? null
                                  : increase(item, item.amount)
                              }
                              className={styles.addProduct}
                              style={
                                item.amount === item.stock ||
                                item.amount > item.stock ||
                                loadingCount === item.id
                                  ? { cursor: "not-allowed", opacity: "0.5" }
                                  : null
                              }
                              disabled={
                                item.amount === item.stock ||
                                loadingCount === item.id ||
                                item.amount > item.stock
                                  ? true
                                  : false
                              }
                            >
                              <AddIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.cart_body__left_priceAndRemove}>
                      <div
                        className={styles.cart_body__left_priceAndRemove_remove}
                      >
                        <img
                          src={basket}
                          alt="remove"
                          className={
                            styles.cart_body__left_priceAndRemove_remove_btn
                          }
                          onClick={() => handleRemove(item)}
                        />
                      </div>
                      <div
                        className={styles.cart_body__left_priceAndRemove_price}
                      >
                        <h5>
                          {item?.discount_price !== null
                            ? item?.discount_price?.toFixed(2)
                            : item.price?.toFixed(2)}
                          <span>{t("EUR")}</span>
                        </h5>
                        {item?.discount_price !== null && (
                          <h3>
                            {item.price?.toFixed(2)} <span>{t("EUR")}</span>
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ margin: "2vh auto", color: "#333333" }}>
                {t("There are no items in your cart")}
              </div>
            )}
          </div>
          {cartItems?.items?.length > 0 && (
            <div
              style={{
                width: "100%",
                backgroundColor: "white",
                position: "sticky",
                zIndex: "10",
                bottom: "0",
                left: "0",
                right: "0",
                top: "-1px",
                margin: "1rem auto",
                height: "4rem",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className={styles.checkout_btns_group_btn}
                onClick={
                  cartItems?.items?.length > 0
                    ? () => navigate("/checkout/delivery")
                    : null
                }
                disabled={cartItems?.items?.length > 0 ? false : true}
              >
                {t("Checkout")}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FloatingCart;
