import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import style from "./../styles/orderRecipt.module.css";

import { placeholderImg } from "../constants/placeholderImg";

import deliveryTruck from "../assets/deliveryTruck.svg";
import reorder from "../assets/reorder.svg";
import basket from "../assets/basket.svg";
import userPrefService from "../services/user-preferences";

import { CancelOrderModal } from ".";

const OrderRecipt = ({
  id,
  product_id,
  productImg,
  date,
  orderNumber,
  customer,
  productTitle,
  price,
  sku,
  color,
  size,
  qty,
  status,
  status_id,
  canceled,
  SingleOrder,
}) => {
  const [open, setOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const config = JSON.parse(localStorage.getItem("config"));

  const actions = [
    {
      name: "Track Order",
      name_ar: "تتبع الطلب",
      link: `/customer/orders/details/${id}`,
      icon: deliveryTruck,
      action_id: 1,
    },
    {
      name: "Reorder",
      name_ar: "إعادة الطلب",
      link: `/products/${product_id}`,
      icon: reorder,
      action_id: 2,
    },
    {
      name: "Remove",
      name_ar: "إزالة",
      link: handleOpen,
      icon: basket,
      action_id: 3,
    },
  ];
  const [orderActions, setOrderActions] = React.useState(actions);
  //on click change the whole website language to arabic
  const checkLanguage = () => {
    if (localStorage.getItem("lang") === "ar") {
      return "ar";
    } else {
      return "en";
    }
  };
  //if status_id is 6 then slice object that has name "Remove" from actions array
  const removeObject = () => {
    if ((status_id = 6)) {
      console.log("removed");
      return setOrderActions(orderActions.slice(2.1));
    } else {
      return actions;
    }
  };
  //remove element from array with specific index

  // React.useEffect(() => {
  //   removeObject();
  //   console.log(actions);
  // } , []);

  return (
    <>
      <div className={style.orderRecipt_main}>
        <div className={style.orderRecipt_mainDetails}>
          <div className={style.orderRecipt_mainDetails__data}>
            <div className={style.orderRecipt_mainDetails__data_title}>
              {t("Order")} {status}
            </div>
            <div className={style.orderRecipt_mainDetails__data_res}>
              {date}
            </div>
          </div>
          <div className={style.orderRecipt_mainDetails__orderNum}>
            {t("Order")} # {orderNumber}
          </div>
          <div className={style.orderRecipt_mainDetails__data}>
            <div className={style.orderRecipt_mainDetails__data_title}>
              {t("SHIP TO")}
            </div>
            <div className={style.orderRecipt_mainDetails__data_res}>
              {customer}
            </div>
          </div>
        </div>
        <div className={style.orderRecipt_mainOrders}>
          <div className={style.orderRecipt_mainOrders_summary}>
            <div className={style.orderRecipt_mainOrders__details}>
              {SingleOrder?.map((item) => {
                return (
                  <div
                    className={
                      SingleOrder?.length > 1
                        ? style.orderRecipt_singleItem
                        : style.orderRecipt_singleItemWithoutDivider
                    }
                  >
                    <div className={style.orderRecipt_mainOrders__img_div}>
                      <img
                        src={item?.image}
                        className={style.orderRecipt_mainOrders__img}
                        onError={(e) => {
                          e.target.src = placeholderImg;
                        }}
                        alt="product"
                      />
                    </div>
                    <div className={style.orderRecipt_singleOrder__data}>
                      <div
                        className={style.orderRecipt_mainOrders__details__title}
                      >
                        {document.body.dir === "rtl"
                          ? item?.name_ar
                          : item?.name}
                      </div>
                      <div
                        className={style.orderRecipt_mainOrders__details__desc}
                      >
                        <div
                          className={
                            style.orderRecipt_mainOrders__details__desc__sku
                          }
                        >
                          {!sku ? `${t("SKU")}: ${item?.sku}` : ""}
                        </div>
                        {item?.color && (
                          <div
                            className={
                              style.orderRecipt_mainOrders__details__desc__color
                            }
                          >
                            {`${t("Color")}: ${item?.color}`}
                          </div>
                        )}
                        {item?.size && (
                          <div
                            className={
                              style.orderRecipt_mainOrders__details__desc__size
                            }
                          >
                            {`${t("Size")}: ${item?.size}`}
                          </div>
                        )}
                        {item?.price && (
                          <div
                            className={
                              style.orderRecipt_mainOrders__details__desc__color
                            }
                          >
                            {`${t("Price")}: ${item?.price} ${
                              document.body.dir === "rtl"
                                ? config?.CURRENCY_CODE_AR
                                : config?.CURRENCY_CODE_EN
                            }
                            `}
                          </div>
                        )}
                        <div
                          className={
                            style.orderRecipt_mainOrders__details__desc__qty
                          }
                        >
                          {t("Quantity")}: {item?.amount}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className={style.orderRecipt_main__actions_flex}>
                <div className={style.orderRecipt_mainOrders__details__price}>
                  {`${t("Total Price")} : ${price} ${
                    document.body.dir === "rtl"
                      ? config?.CURRENCY_CODE_AR
                      : config?.CURRENCY_CODE_EN
                  }`}
                </div>
                {status_id != 6 ? (
                  <div className={style.orderRecipt_mainOrders__actions}>
                    {actions.map((action, index) => (
                      <div
                        key={index}
                        className={
                          style.orderRecipt_mainOrders__actions__action
                        }
                      >
                        <img
                          src={action.icon}
                          alt={action.name}
                          className={
                            style.orderRecipt_mainOrders__actions__action__icon
                          }
                        />
                        {action.link === handleOpen ? (
                          <>
                            <Button
                              text
                              onClick={action.link}
                              className={
                                style.orderRecipt_mainOrders__actions__action__name
                              }
                              style={{
                                color: "#425466",
                                fontSize: "0.8rem",
                                padding: 0,
                                textTransform: "none",
                                margin: 0,
                                textDecoration: "underline",
                                fontFamily: "unset",
                              }}
                            >
                              {document.body.dir === "rtl"
                                ? action.name_ar
                                : action.name}
                            </Button>
                            <CancelOrderModal
                              openState={open}
                              handleClose={handleClose}
                              orderId={id}
                              canceled={canceled}
                            />
                          </>
                        ) : (
                          <Link
                            to={action.link}
                            className={
                              style.orderRecipt_mainOrders__actions__action__name
                            }
                          >
                            {document.body.dir === "rtl"
                              ? action.name_ar
                              : action.name}
                          </Link>
                        )}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderRecipt;
