import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import authHeader from "./auth-header";
import {
  startFetching,
  categoriesFetching,
  profileFetching,
  addressesFetching,
  cartFetching,
  compareFetching,
  favouritesFetching,
  giftsFetching,
  localizationFetching,
  notificationsFetching,
  unreadNotificationsFetching,
  user_activeFetching,
  errorFetching,
} from "../store/init/initSlice";
import { favouritesProductsFetching } from "../store/wishlist/wishlistSlice";

// const user = JSON.parse(localStorage.getItem("user"));

////////// Initialize
export const initialize = () => async (dispatch) => {
  try {
    dispatch(startFetching());
    const response = await axios.get(baseUrl + "/home/initialize", {
      headers: authHeader(),
    });
    console.log(response);
    if (response?.data?.data?.profile?.token)
      localStorage.setItem("init_token", response?.data?.data?.profile?.token);
    dispatch(categoriesFetching(response?.data?.data?.categories));
    dispatch(profileFetching(response?.data?.data?.profile));
    dispatch(addressesFetching(response?.data?.data?.profile.addresses));
    dispatch(cartFetching(response?.data?.data?.cart));
    dispatch(compareFetching(response?.data?.data?.compare));
    dispatch(favouritesFetching(response?.data?.data?.favourites));
    dispatch(favouritesProductsFetching(response?.data?.data?.favourites));
    dispatch(giftsFetching(response?.data?.data?.gifts));
    dispatch(localizationFetching(response?.data?.data?.localization));
    dispatch(notificationsFetching(response?.data?.data?.notifications));
    dispatch(
      unreadNotificationsFetching(response?.data?.data?.unreadNotifications)
    );
    dispatch(user_activeFetching(response?.data?.data?.user_active));
  } catch (e) {
    dispatch(errorFetching(e.message));
  }
};
//   console.log(localStorage.getItem('init'));
