import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "../auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import profile from "../assets/profile.svg";
import orders from "../assets/orders.svg";
import wishList from "../assets/heart.svg";
import payment from "../assets/payment.svg";
import address from "../assets/address.svg";
import setting from "../assets/setting.svg";

import styles from "./../styles/AccountMenu.module.css";

const AccountMenu = ({ active }) => {
  const { user } = useSelector((state) => state.auth);
  const { t, i18n } = useTranslation();
  const menuItems = [
    {
      name: "Overview",
      name_ar: "نظرة عامة",
      link: "/customer/account",
      icon: profile,
      id: 1,
    },
    {
      name: "My Orders",
      name_ar: "طلباتي",
      link: "/customer/orders",
      icon: orders,
      id: 2,
    },
    {
      name: "My Wishlist",
      name_ar: "قائمة الرغبات الخاصة بي",
      link: "/customer/wishlist",
      icon: wishList,
      id: 3,
    },
    // {
    //   name: "Payment",
    //   link: "/customer/payment",
    //   icon: payment,
    // },
    {
      name: "My Address Book",
      name_ar: "دفتر عناويني",
      link: "/customer/addresses",
      icon: address,
      id: 4,
    },
    {
      name: "Account Settings",
      name_ar: "إعدادات الحساب",
      link: "/customer/account/settings",
      icon: setting,
      id: 5,
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // function to logout user
  const logoutUser = () => {
    dispatch(logout());
    navigate("/");
  };

  //onclick on AccountAsideMenu_link change the color of the link

  // set active class to the link that is clicked
  const handleActive = (e) => {
    const links = document.querySelectorAll(`.${styles.AccountMenuItem}`);
    for (let i = 0; i < links.length; i++) {
      links[i].classList.remove(styles.AccountMenuItem_active);
    }
    e.target.classList.add(styles.AccountMenuItem_active);
  };

  // const handleClick = (e) => {
  //   const links = document.querySelectorAll(`.${styles.AccountMenuItem}`)
  //     links.forEach((link) => {
  //     link.classList.remove(`.${styles.AccountAsideMenu_link_active}`);
  //   }),
  //     e.target.classList.add(`.${styles.AccountAsideMenu_link_active}`);
  // }
  return (
    <>
      <div className={styles.accountMenu}>
        <aside className={styles.AccountAsideMenu}>
          <ul>
            {menuItems.map((item, index) => (
              <Link
                className={
                  active === item.name
                    ? styles.AccountAsideMenu_link__active
                    : styles.AccountAsideMenu_link
                }
                to={item.link}
              >
                <li key={index} className={styles.AccountMenuItem}>
                  <img
                    className={
                      active === item.name
                        ? styles.AccountMenuItem_img__active
                        : styles.AccountMenuItem_img
                    }
                    src={item.icon}
                    alt={item.name}
                  />
                  <span>
                    {document.body.dir === "rtl" ? item.name_ar : item.name}
                  </span>
                </li>
              </Link>
            ))}
            <li className={styles.AccountMenuItem}>
              <div onClick={logoutUser}>{t("Logout")}</div>
            </li>
          </ul>
        </aside>
      </div>
    </>
  );
};

export default AccountMenu;
