import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import userPrefService from "../services/user-preferences";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "500px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
const baseCOlor = "#FF1D52";
export default function CancelOrderModal({
  openState,
  handleClose,
  orderId,
  canceled,
}) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [reasons, setReasons] = React.useState([]);
  const [cancelOrder, setCancelOrder] = React.useState(!canceled);
  const [checkedReason, setCheckedReason] = React.useState(false);
  const { t, i18n } = useTranslation();
  const handleOpen = () => setOpen(true);
  const handleChange = (event) => {
    setReason(Number(event.target.value) || "");
    setCheckedReason(true);
  };
  const navigate = useNavigate();
  React.useEffect(() => {
    userPrefService
      .orderCancellationReason()
      .then((res) => {
        setReasons(res);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //   React.useEffect(() => {
  //     cancellationReasons();
  //   }, []);
  const handleCancelOrder = () => {
    setLoading(true);
    userPrefService
      .cancelOrder(orderId, reason)
      .then((res) => {
        console.log(res);
        setLoading(false);
        setOpen(false);
        handleClose();
        setCancelOrder(canceled);
        navigate("/customer/orders");
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
      });
  };
  return (
    <div>
      <Modal
        open={openState}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              fontFamily: "inherit",
            }}
          >
            {t("Are you sure you want to cancel this order?")}
          </Typography>
          <FormControl
            sx={{ minWidth: 120, marginTop: 3 }}
            style={{ width: "100%" }}
            variant="standard"
          >
            <InputLabel
              id="demo-dialog-select-label"
              style={{
                width: "100%",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                fontFamily: "inherit",
              }}
            >
              {t("Cancellation Reason")}
            </InputLabel>
            <Select
              labelId="demo-dialog-select-label"
              id="demo-dialog-select"
              value={reason}
              onChange={handleChange}
              label={t("Cancellation Reason")}
              style={{
                width: "100%",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                fontFamily: "inherit",
              }}
            >
              {reasons.map((reason) => (
                <MenuItem value={reason.id} style={{ fontFamily: "unset" }}>
                  {reason.text}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <DialogActions
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "fit-content",
              margin: "1vh auto",
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                backgroundColor: baseCOlor,
                color: "#fff",
                borderRadius: "20px",
                width: "80%",
                margin: "1rem",
                fontFamily: "inherit",
              }}
            >
              {t("Back")}
            </Button>
            <Button
              onClick={() => handleCancelOrder()}
              style={
                loading || !checkedReason
                  ? {
                      opacity: 0.5,
                      backgroundColor: baseCOlor,
                      color: "#fff",
                      borderRadius: "20px",
                      width: "100%",
                      margin: "1rem",
                      fontFamily: "inherit",
                      whiteSpace: "nowrap",
                      padding: "1vh 2vw",
                      fontWeight: "bold",
                    }
                  : {
                      opacity: 1,
                      backgroundColor: baseCOlor,
                      color: "#fff",
                      borderRadius: "20px",
                      width: "100%",
                      margin: "1rem",
                      fontFamily: "inherit",
                      whiteSpace: "nowrap",
                      padding: "1vh 2vw",
                      fontWeight: "bold",
                    }
              }
              disabled={loading || !checkedReason}
            >
              {loading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                <span>{t("Cancel Order")}</span>
              )}
            </Button>
          </DialogActions>
        </Box>
      </Modal>
    </div>
  );
}
