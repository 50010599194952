import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../styles/Checkout.module.css";

const TimelineBar = ({ page }) => {
  const deliveryRef = useRef(null);
  const paymentRef = useRef(null);
  const { t } = useTranslation();
  const location = useLocation();
  const handleStatus = () => {
    if (window.location.href.includes("delivery")) {
      deliveryRef.current.classList.add(styles.active);
      paymentRef.current.classList.remove(styles.active);
    }
    if (window.location.href.includes("payment")) {
      deliveryRef.current.classList.add(styles.active);
      paymentRef.current.classList.add(styles.active);
    }
    if (window.location.href.includes("order-confirmation")) {
      deliveryRef.current.classList.remove(styles.active);
      paymentRef.current.classList.remove(styles.active);
    }
    if (window.location.href.includes("order-placed")) {
      deliveryRef.current.classList.add(styles.active);
      paymentRef.current.classList.add(styles.active);
    }
  };
  useEffect(() => {
    handleStatus();
  }, [location.pathname]);
  return (
    <ul className={styles.delivery_header_list}>
      <li ref={deliveryRef} className={styles.delivery_header_list_item}>
        {document.body.dir === "rtl" ? "تفاصيلي" : "My Details"}
      </li>
      <li
        ref={paymentRef}
        className={page ? styles.divider_active : styles.divider}
      ></li>
      <li ref={paymentRef} className={styles.delivery_header_list_item}>
        {t("Payment")}
      </li>
    </ul>
  );
};

export default TimelineBar;
