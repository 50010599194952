import React from "react";
import { Signup_social } from "../../components";
import  useDocumentTitle  from "../../hooks/useDocumentTitle";



const SignupSocial = () => {
   
  useDocumentTitle('Completing sign up')
  return (
    <>
      {/* <Navbarr /> */}
      <div className='signup-styles' style={{backgroundColor:"#f8f8f6",padding:"2vh 20vw"}}>
        <Signup_social />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default SignupSocial;





//  initialValues: {
//   name_social: user?.name ? user?.name : "",
//   last_name_social: user?.last_name ? user?.last_name : "",
//   email_social: user?.email ? user?.email : "",
//   phone_social: user?.phone ? user?.phone : "",
// },
// validationSchema: userValidationSignUpSchema,

// onSubmit: (values) => {
//   const { name, last_name, email, password, confirm_password, phone } =
//     values;
//   setLoading(true);

//   dispatch(
//     register({
//       name,
//       last_name,
//       phone,
//       email,
//       password,
//       confirm_password,
//     })
//   )
//     .unwrap()
//     .then(() => {
//       console.log("Registered");
//       setLoading(false);
//       console.log(message);

//       // empty the form
//     })
//     .catch(() => {
//       setLoading(false);
//       console.log("error");
//       console.log(message);
//       formikSignup.resetForm();
//     });
// },