import axios from "axios";
import { baseUrl } from "../constants/apiURL";
import authHeader from "./auth-header";

//////// cart items
const getCart = async () => {
  const response = await axios.get(baseUrl + "/carts", {
    headers: authHeader() ? authHeader() : 
    "Bearer " + localStorage.getItem("init_token")
  });
  return response.data.data;
};
const addCartItems = async (data) => {
  const response = await axios.post(baseUrl + "/carts", data, {
    headers: authHeader(),
  });
  console.log(response.data , authHeader());
  return response.data.data;
};

////////// Favourites
const getFavourites = async () => {
  const response = await axios.get(baseUrl + "/products/favourites", {
    headers: authHeader(),
  });
  return response.data.data;
};
const addToFavourites = async (favourites) => {
  const response = await axios.post(
    baseUrl + `/products/favourite/${favourites}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data.data;
};
const removeFromFavourites = async (parent_id) => {
  const response = await axios.post(
    baseUrl + `/products/unfavourite/${parent_id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data.data;
};

////////// Addresses
const addAddress = async (address) => {
  const response = await axios.post(baseUrl + "/profile/addresses", address, {
    headers: authHeader(),
  });
  return response.data;
};
const getAddress = async () => {
  const response = await axios.get(baseUrl + "/profile", {
    headers: authHeader(),
  });
  return response.data.data;
};
const editAddress = async (id, data) => {
  const response = await axios.post(
    baseUrl + `/profile/addresses/${id}`,
    data,
    {
      headers: authHeader(),
    }
  );
  // localStorage.setItem("user", (response.data));
  return response.data;
};
const deleteAddress = async (id) => {
  const response = await axios.post(
    baseUrl + `/profile/addresses/remove/${id}`,
    {},
    {
      headers: authHeader(),
    }
  );
  return response.data;
};
const cities = async () => {
  const response = await axios.get("https://yallafoodapi.witheldokan.com/api/cities", {
    headers: authHeader(),
  });
  return response.data.data;
};

const set_area = async (data) => {
  const response = await axios.post(
    baseUrl + "/profile/set_area",
    data,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

////////// Orders
const getOrders = async (page, phone) => {
  const response = await axios.get(
    baseUrl + `/orders?page=${page}&phone=${phone ? phone : ""}`,
    { headers: authHeader() }
  );
  return response.data.data;
};
const addOrder = async (order) => {
  const response = await axios.post(baseUrl + "/orders", order, {
    headers: authHeader(),
  });
  return response.data.data;
};

const cancelOrder = async (id, cancellation_id) => {
  const response = await axios.post(
    baseUrl + `/orders/cancel/${id}`,
    cancellation_id,
    { headers: authHeader() }
  );
  return response.data.data;
};
const orderDetails = async (id) => {
  const response = await axios.get(baseUrl + `/orders/${id}`, {
    headers: authHeader(),
  });
  return response.data.data;
};
const orderCancellationReason = async () => {
  const response = await axios.get(baseUrl + "/order_cancellation_reasons", {
    headers: authHeader(),
  });
  return response.data.data;
};
const getDeliveryFees = async (amount, address_id, is_guest) => {
  const response = await axios.post(
    baseUrl + "/orders/delivery_fees",
    {
      amount,
      address_id,
      is_guest,
    },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};
const promoCode = async (address_id, amount, promo, verification_code) => {
  const response = await axios.post(
    baseUrl + "/orders/check_promo_v2",
    {
      address_id,
      amount,
      promo,
      verification_code,
    },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

const placeOrder = async (
  accept_payment_discount_dialog,
  address_id,
  device_type,
  notes,
  payment_method,
  payment_method_phone,
  promo,
  verification_code,
  plan_id,
) => {
  const response = await axios.post(
    baseUrl + "/orders",
    {
      accept_payment_discount_dialog,
      address_id,
      device_type,
      notes,
      payment_method,
      payment_method_phone,
      promo,
      verification_code,
      plan_id,
    },
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

////////// Profile
const editProfile = async (data) => {
  const response = await axios.post(baseUrl + "/profile/edit", data, {
    headers: authHeader(),
  });
  return response.data;
};

const editEmail = async (data) => {
  const response = await axios.post(baseUrl + "/profile/edit/email", data, {
    headers: authHeader(),
  });
  return response.data;
};

const changePassword = async (data) => {
  const response = await axios.post(
    baseUrl + "/profile/change_password",
    data,
    {
      headers: authHeader(),
    }
  );
  return response.data;
};

// ////// update guest profile
// const updateGuestProfile = async (data) => {
//   const response = await axios.post(baseUrl + "/profile/full-edit", data, {
//     headers: authHeader(),
//   });
//   console.log(response.data);
//   return response.data;
// };


/////////// Products
const NotifyMe = async (id,data) => {
  const response = await axios.post(baseUrl + `/products/notify/${id}`, data, {
    headers: authHeader(),
  });
  return response.data;
};



////// Footer
const GetFooter = async () => {
  const response = await axios.get(baseUrl + "/pages", {
    headers: authHeader(),
  });
  return response.data.data;
};
const newsLetter = async (data) => {
  const response = await axios.post(baseUrl + "/newsletter", data, {
    headers: authHeader(),
  });
  return response.data;
};


const userPrefService = {
  getCart,
  addCartItems,
  getFavourites,
  addToFavourites,
  removeFromFavourites,
  addAddress,
  getAddress,
  editAddress,
  deleteAddress,
  getOrders,
  addOrder,
  cancelOrder,
  editProfile,
  changePassword,
  orderDetails,
  orderCancellationReason,
  cities,
  getDeliveryFees,
  promoCode,
  placeOrder,
  NotifyMe,
  set_area,
  GetFooter,
  editEmail,
  newsLetter,
  // updateGuestProfile
};
export default userPrefService;
