import React, { useEffect, useState } from "react";
import userPrefService from "../../services/user-preferences";
import { Skeleton } from "@mui/material";

const Cookies = () => {
  const [page, setPage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    userPrefService.GetFooter().then((res) => {
      console.log(res);
      setIsLoading(false);
      setPage(res.find((item) => item.id === 3));
    });
  }, []);
  console.log(page);

  return (
    <>
      {isLoading ? (
        [0, 1, 2, 3].map((item) => (
          <Skeleton
            variant="rectangular"
            width={300}
            height={50}
            style={{
              margin: "2rem auto",
              fontSize: "20px",
              textAlign: "center",
              fontWeight: "bold",
              borderRadius: "20px",
            }}
            key={item}
          />
        ))
      ) : (
        <div
          style={{
            margin: "10rem auto",
            fontSize: "20px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {document.body.dir === "rtl" ? page.title_ar : page.title_en}
        </div>
      )}
      ;
    </>
  );
};

export default Cookies;
