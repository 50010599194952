import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import arrow from "../assets/arrow.svg";

import {
  removeProduct,
  toggleItemRemoved,
  getQTYOfSingleProduct,
  toggleItemAdded,
  cartItemsFetching,
  increaseCount,
  decreaseCount,
  setTotalSum,
} from "../store/cart/cartSlice";
import { useTranslation } from "react-i18next";
import { placeholderImg } from "../constants/placeholderImg";
import useScrollTop from "../hooks/useScrollTop";

import { EmptyCart, ProductsCarousal } from "../components";
import useDocumentTitle from "../hooks/useDocumentTitle";
import styles from "../styles/Cart.module.css";
import basket from "../assets/basket.svg";
import emptyCart from "../assets/shop-bag-yallaFood.svg";
import visa from "../assets/visa.svg";
import mastercard from "../assets/masterCard.svg";
import product from "../assets/product.svg";
import userPrefService from "../services/user-preferences";
import { Skeleton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Cart = () => {
  useDocumentTitle("Cart");
  const navigate = useNavigate();
  useScrollTop();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const [cartProducts, setCartProducts] = useState([]);
  const [total, setTotal] = useState("");
  const [loadingCount, setLoadingCount] = useState(null);

  const { cartItems, totalSum, cartItemQTY } = useSelector(
    (state) => state.cartProducts
  );
  console.log(cartItems);
  const dispatch = useDispatch();

  const [count, setCount] = useState(1);
  const [isAdded, setIsAdded] = useState(false);

  const increase = (product, qty) => {
    setLoadingCount(product.id);
    setCount((qty) => qty + 1);
    dispatch(increaseCount(product, qty));
    userPrefService
      .addCartItems({
        items: [
          {
            id: product.id,
            amount: qty + 1,
          },
        ],
        reset: false,
      })
      .then((res) => {
        dispatch(cartItemsFetching(res));
        setLoadingCount(null);
        dispatch(setTotalSum(res.grand_total));

        console.log(res);
      })
      .catch((err) => {
        setLoadingCount(null);
        console.error(err);
      });
  };

  const decrease = (product, qty) => {
    setLoadingCount(product.id);
    if (qty > 1) {
      setCount((qty) => qty - 1);
      dispatch(decreaseCount(product, qty));
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: qty - 1,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));
          dispatch(setTotalSum(res.grand_total));
          setLoadingCount(null);
          console.log(res);
        })
        .catch((err) => {
          setLoadingCount(null);
          console.error(err);
        });
    } else if (qty === 1) {
      dispatch(removeProduct(product));
      dispatch(toggleItemRemoved(true));
      dispatch(toggleItemAdded(false));
      userPrefService
        .addCartItems({
          items: [
            {
              id: product?.id,
              amount: 0,
            },
          ],
          reset: false,
        })
        .then((res) => {
          dispatch(cartItemsFetching(res));

          dispatch(setTotalSum(res.grand_total));

          console.log(res);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    setIsAdded(false);
    toggleItemAdded(false);
  };

  useEffect(() => {
    setIsLoading(true);
    userPrefService.getCart().then((res) => {
      console.log(res);
      setIsLoading(false);
      setTotal(res?.grand_total);
      setCartProducts(res);
      dispatch(cartItemsFetching(res));
    });
    setCartProducts(JSON.parse(localStorage.getItem("cart")));
  }, [dispatch]);

  console.log(cartItems);

  // const handleSubmit = () => {
  //   dispatch(clearCart());
  // };
  const handleRemove = (product) => {
    dispatch(removeProduct(product));
    dispatch(toggleItemRemoved(true));
    dispatch(toggleItemAdded(false));
    dispatch(getQTYOfSingleProduct(product));
    userPrefService
      .addCartItems({
        items: [
          {
            id: product?.id,
            amount: 0,
          },
        ],
        reset: false,
      })
      .then((res) => {
        dispatch(cartItemsFetching(res));
        dispatch(setTotalSum(res.grand_total));

        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
    console.log(cartItems);
  };
  // const getQTY = (item) => {
  //   dispatch(getCartItemQTY(item));
  // };
  const TotalFees = ({ label, value, id }) => {
    return (
      <div className={styles.totalFees}>
        <div className={styles.totalFees__title}>
          <div>{label}</div>
        </div>
        <div
          className={
            id === "shipping"
              ? styles.totalFees__price_value__disabled
              : styles.totalFees__price
          }
          key={id}
        >
          {loadingCount ? (
            <Skeleton
              variant="text"
              width={100}
              style={{ height: "1.5rem", borderRadius: "0.5rem" }}
              animation="wave"
            />
          ) : (
            <span>{value}</span>
          )}
        </div>
      </div>
    );
  };

  console.log(cartItemQTY);

  console.log(total);
  return (
    <>
      {/* <Navbarr /> */}

      <div
        style={{ backgroundColor: "rgb(248, 248, 246)", padding: "2vh 7vw" }}
      >
        <div className={styles.cart_container}>
          <div className={styles.cart_header}>
            <h3>{t("Your Cart")}</h3>
            <p>
              {t("You have")}{" "}
              {cartItemQTY
                ? cartItemQTY === 1
                  ? document.body.dir === "rtl"
                    ? null
                    : cartItemQTY
                  : cartItemQTY === 2
                  ? document.body.dir === "rtl"
                    ? null
                    : cartItemQTY
                  : cartItemQTY
                : 0}{" "}
              {cartItemQTY === 1
                ? t("items in your cart == 1")
                : cartItemQTY === 2
                ? t("items in your cart == 2")
                : cartItemQTY > 2 && cartItemQTY < 11
                ? t("items in your cart > 2 && < 10")
                : t("items in your cart > 10")}
            </p>
          </div>
          <div className={styles.cart_body}>
            <div
              className={
                cartItems?.items?.length > 0
                  ? styles.cart_body__left
                  : styles.cart_body__left_empty
              }
            >
              {isLoading ? (
                [1, 2, 3, 4].map((item) => (
                  <Skeleton
                    variant="rectangular"
                    width={500}
                    height={100}
                    animation="wave"
                    key={item}
                    style={{ marginBottom: "1rem", borderRadius: "20px" }}
                  />
                ))
              ) : cartItems?.items?.length > 0 ? (
                cartItems?.items?.map((item) => (
                  <div key={item.id} className={styles.cart_body__left_items}>
                    <div className={styles.cart_body__left_product}>
                      <img
                        src={item.image}
                        alt={item.name}
                        className={styles.cart_body__left_product_image}
                        onError={(e) => {
                          e.target.src = placeholderImg;
                        }}
                        onClick={() => {
                          navigate(
                            `/products/${item.slug}/${item.parent_id}/?variant=${item.id}`
                          );
                        }}
                      />
                      <div className={styles.cart_body__left_product_info}>
                        <h5
                          onClick={() => {
                            navigate(
                              `/products/${item.slug}/${item.parent_id}/?variant=${item.id}`
                            );
                          }}
                        >
                          {document.body.dir === "rtl"
                            ? item.name_ar
                            : item.name}
                        </h5>
                        <div className={styles.prices_container}>
                          <div className={styles.addRemove}>
                            <button
                              onClick={() => decrease(item, item.amount)}
                              className={styles.removeProduct}
                              disabled={loadingCount === item.id ? true : false}
                              style={
                                loadingCount === item.id
                                  ? { cursor: "not-allowed", opacity: "0.5" }
                                  : null
                              }
                            >
                              <RemoveIcon />
                            </button>
                            <div className={styles.productCount}>
                              {loadingCount === item.id ? (
                                <span className="spinner-border spinner-border-sm"></span>
                              ) : (
                                <span>{item?.amount}</span>
                              )}
                            </div>
                            <button
                              onClick={() =>
                                item.max_per_order
                                  ? item.amount === item.max_per_order ||
                                    item.amount > item.max_per_order
                                  : item.amount === item.stock ||
                                    item.amount > item.stock
                                  ? null
                                  : increase(item, item.amount)
                              }
                              className={styles.addProduct}
                              style={
                                item.max_per_order
                                  ? item.amount === item.max_per_order ||
                                    item.amount > item.max_per_order
                                  : item.amount === item.stock ||
                                    item.amount > item.stock ||
                                    loadingCount === item.id
                                  ? { cursor: "not-allowed", opacity: "0.5" }
                                  : null
                              }
                              disabled={
                                item.max_per_order
                                  ? item.amount === item.max_per_order ||
                                    item.amount > item.max_per_order
                                  : item.amount === item.stock ||
                                    loadingCount === item.id ||
                                    item.amount > item.stock
                                  ? true
                                  : false
                              }
                            >
                              <AddIcon />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.cart_body__left_priceAndRemove}>
                      <div
                        className={styles.cart_body__left_priceAndRemove_remove}
                      >
                        <img
                          src={basket}
                          alt="remove"
                          className={
                            styles.cart_body__left_priceAndRemove_remove_btn
                          }
                          onClick={() => handleRemove(item)}
                        />
                      </div>
                      <div
                        className={styles.cart_body__left_priceAndRemove_price}
                      >
                        <h5>
                          {item?.discount_price !== null
                            ? item?.discount_price?.toFixed(2)
                            : item.price?.toFixed(2)}
                          <span>{t("EUR")}</span>
                        </h5>
                        {item?.discount_price !== null && (
                          <h3>
                            {item.price?.toFixed(2)} <span>{t("EUR")}</span>
                          </h3>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <EmptyCart
                  imgSrc={emptyCart}
                  link="/"
                  linkTxt={t("Continue Shopping")}
                  style={{ margin: "auto" }}
                />
              )}
            </div>
            {isLoading ? (
              <div style={{ flexDirection: "column", display: "flex" }}>
                {[1, 2, 3].map((item) => (
                  <Skeleton
                    variant="rectangular"
                    width={300}
                    height={50}
                    animation="wave"
                    style={{ borderRadius: "20px", marginBottom: "1rem" }}
                    key={item}
                  />
                ))}
              </div>
            ) : cartItems?.items?.length > 0 ? (
              <div className={styles.cart_body__right}>
                <div className={styles.cart_body__right_total}>
                  <div className={styles.cart_body__right_total__header}>
                    {t("Payment Summary")}
                  </div>
                  <div className={styles.cart_body__right_total__fees}>
                    {isLoading ? (
                      <Skeleton
                        variant="rectangular"
                        width={300}
                        height={50}
                        animation="wave"
                        style={{ borderRadius: "20px", marginBottom: "1rem" }}
                      />
                    ) : (
                      <TotalFees
                        label={t("Subtotal")}
                        value={totalSum?.toFixed(2) + t(" EUR")}
                      />
                    )}
                    <TotalFees
                      label={t("Shipping Fees")}
                      value={t("To be calculated in the next steps")}
                      id="shipping"
                    />

                    <div className={styles.cart_divider}></div>
                    {isLoading ? (
                      <Skeleton
                        variant="rectangular"
                        width={300}
                        height={50}
                        animation="wave"
                        style={{ borderRadius: "20px", marginBottom: "1rem" }}
                      />
                    ) : (
                      <TotalFees
                        label={t("Total Taxes included")}
                        value={totalSum?.toFixed(2) + t(" EUR")}
                      />
                    )}
                    <div className={styles.cart_proceed}>
                      <Link to="/checkout/delivery">
                        <button className={styles.cart_proceed_btn}>
                          {t("Proceed")}
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className={styles.cart_credit}>
                    <img src={mastercard} alt="masterCard" />
                    <img src={visa} alt="visa" />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {cartItems?.items?.length > 0 ? (
            <Link
              to="/"
              className={styles.AccountFavourite_empty__linkToShopping}
            >
              <div
                className={styles.AccountFavourite_empty__linkToShopping_icon}
              >
                <img
                  src={arrow}
                  alt="arrow"
                  style={
                    document.body.dir === "rtl"
                      ? { transform: "rotate(180deg)" }
                      : {}
                  }
                />
              </div>
              <div
                className={styles.AccountFavourite_empty__linkToShopping_txt}
              >
                {t("Continue Shopping")}
              </div>
            </Link>
          ) : null}
          <div className={styles.featured_products}>
            <div className={styles.featured_products_body}>
              <ProductsCarousal title={t("YOU MAY ALSO LIKE THESE")} />
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Cart;
