import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import userPrefService from "../../../services/user-preferences";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./../../../styles/Account.module.css";

import {
  AccountMenu,
  CancelOrderModal,
  OrderStatus,
} from "../../../components";
import { Skeleton } from "@mui/material";

import PlacedImg from "./../../../assets/placedimg.svg";
import ConfirmedImg from "./../../../assets/confirmedimg.svg";
import PreparedImg from "./../../../assets/preparedimg.svg";
import ShippedImg from "./../../../assets/shippedimg.svg";
import DeliveredImg from "./../../../assets/deliveredimg.svg";
import canceledOrder from "./../../../assets/canceledOrder.svg";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import { placeholderImg } from "../../../constants/placeholderImg";

const AccountOrdersDetails = () => {
  useDocumentTitle("Account Orders Details");
  const { t, i18n } = useTranslation();
  const [order, setOrder] = useState([]);
  const [address, setAddress] = useState([]);
  const [status, setStatus] = useState([]);
  const [payment, setPayment] = useState([]);
  const [products, setProducts] = useState([]);
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loadingOrder, setLoadingOrder] = useState(true);

  console.log(id);

  useEffect(() => {
    userPrefService
      .orderDetails(id)
      .then((res) => {
        setLoadingOrder(false);
        console.log(res);
        setOrder(res);
        setAddress(res.address);
        setStatus(res.state);
        setPayment(res.payment_method_object);
        setProducts(res.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  console.log(order);
  console.log(address);
  console.log(status.name);

  const orderFields = [
    {
      label: "Contact :  ",
      label_ar: "الاتصال :  ",
      value: order.phone,
    },
    {
      label: "Ship To :  ",
      label_ar: "الشحن إلى :  ",
      value:
        document.body.dir === "rtl"
          ? address.formatted_address_ar
          : address.formatted_address,
    },
    {
      label: "Payment Method :  ",
      label_ar: "طريقة الدفع :  ",
      value: payment.name,
    },
  ];
  //product with order status
  const product = {
    name: "Product 1",
    price: "100",
    quantity: "1",
    status: "Placed",
    img: PlacedImg,
  };

  const orderStatusDetails = [
    {
      title:
        "Thank You For Placing Order With Us! Your Order In Confirmed And Will Be Prepared And Shipped To You.",
      title_ar:
        "شكرا لك على وضع الطلب معنا! سيتم تأكيد طلبك وإعداده وشحنه إليك.",
      desc: "You'll Receive an Email. Once Your Order Is Conformed",
      desc_ar: "سوف تتلقى رسالة بريد إلكتروني  بمجرد تأكيد طلبك",
    },
    {
      title: "Thank you for shopping with US!",
      title_ar: "شكرا لك على التسوق معنا!",
      desc: "Your Packages Are On It's Way",
      desc_ar: "طلبك في الطريق",
    },
  ];

  return (
    <>
      {/* <Navbarr /> */}
      <div style={{ backgroundColor: "#f9f9f9" }}>
        <div className={styles.account_main}>
          <AccountMenu active={"My Orders"} />
          <div className={styles.account_body}>
            <OrderStatus status={status.id} status_name={status.name} />
            <div className={styles.account_body_details}>
              <div className={styles.order_status_body}>
                <div className={styles.order_status_body_imgDiv}>
                  <img
                    src={
                      status.id === 1
                        ? PlacedImg
                        : status.id === 2
                        ? ConfirmedImg
                        : status.id === 8
                        ? PreparedImg
                        : status.id === 4
                        ? DeliveredImg
                        : status.id === 3
                        ? ShippedImg
                        : status.id === 6
                        ? canceledOrder
                        : ""
                    }
                    alt={status.name}
                    className={styles.order_status_body__img}
                  />
                </div>
                <div className={styles.order_status_body__txt}>
                  <div className={styles.order_status_body__txt__title}>
                    {status.name === "Placed"
                      ? document.body.dir === "rtl"
                        ? orderStatusDetails[0].title_ar
                        : orderStatusDetails[0].title
                      : status.name === ","
                      ? t("Order is Canceled")
                      : document.body.dir === "rtl"
                      ? orderStatusDetails[1].title_ar
                      : orderStatusDetails[1].title}
                  </div>
                  <div className={styles.order_status_body__txt__desc}>
                    {status.name === "Placed"
                      ? document.body.dir === "rtl"
                        ? orderStatusDetails[0].desc_ar
                        : orderStatusDetails[0].desc
                      : status.name === ","
                      ? "The cancellation reason is : " +
                        order.cancellation_text
                      : document.body.dir === "rtl"
                      ? orderStatusDetails[1].desc_ar
                      : orderStatusDetails[1].desc}
                  </div>
                </div>
                {status.name === "Delivered" && (
                  <Link
                    to={`/products/${id}`}
                    className={styles.order_status_body__link}
                  >
                    <div className={styles.order_status_body__btn}>Reorder</div>
                  </Link>
                )}
                <div className={styles.order_status_body__orderCard}>
                  <div className={styles.order_status_body__orderCard__numebr}>
                    {t("Order No")} {order.id}
                  </div>
                  <div className={styles.order_status_body__orderCard__title}>
                    {t("Shipping Details")}
                  </div>
                  {orderFields.map((field, index) => (
                    <div
                      key={index}
                      className={styles.order_status_body__orderCard__field}
                    >
                      <div
                        className={
                          styles.order_status_body__orderCard__field__label
                        }
                      >
                        {document.body.dir === "rtl"
                          ? field.label_ar
                          : field.label}
                      </div>
                      <span
                        className={
                          styles.order_status_body__orderCard__field__value
                        }
                      >
                        {" "}
                        {field.value}
                      </span>
                    </div>
                  ))}
                </div>
                {status.id !== 3 &&
                status.id !== 4 &&
                status.id !== 2 &&
                status.id !== 8 ? (
                  <div className={styles.orderCancel_div}>
                    <button
                      className={styles.addNewCardCancel}
                      onClick={handleOpen}
                    >
                      {t("Cancel Order")}
                    </button>
                  </div>
                ) : null}
                <CancelOrderModal
                  openState={open}
                  handleClose={handleClose}
                  orderId={id}
                />
              </div>
              <div className={styles.order_status_body__product_summary}>
                <div className={styles.cart_body__right_products__summary}>
                  <div className={styles.cart_body__right_total__header}>
                    {t("Products Summary")}
                  </div>
                  {loadingOrder
                    ? [1, 2, 3].map((item) => (
                        <Skeleton
                          variant="rectangular"
                          key={item}
                          width={400}
                          height={100}
                          style={{ borderRadius: "20px", marginBottom: "20px" }}
                        />
                      ))
                    : order?.items?.map((item) => (
                        <>
                          <div
                            className={styles.cart_body__right_products_product}
                          >
                            <div className={styles.products__img}>
                              <img
                                src={item.image}
                                alt={item.name}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = placeholderImg;
                                }}
                              />
                            </div>
                            <div className={styles.products__details}>
                              <div className={styles.products__details_title}>
                                {document.body.dir === "rtl"
                                  ? item.name_ar
                                  : item.name}
                              </div>
                              <div className={styles.products__details_qty}>
                                {t("QTY:")} {item.amount}
                              </div>
                              <div className={styles.products__details_prices}>
                                <div
                                  className={
                                    styles.products__details_prices_newPrice
                                  }
                                >
                                  {item.discount_price
                                    ? item.discount_price
                                    : item.price}{" "}
                                  {t("EUR")}
                                </div>
                                <div
                                  className={
                                    styles.products__details_prices_oldPrice
                                  }
                                >
                                  {item.discount_price
                                    ? item.price + t(" EUR")
                                    : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={styles.cart_divider}></div>
                        </>
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AccountOrdersDetails;
