import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { Modal, Skeleton } from "@mui/material";
import { editProfileData } from "../../../store/init/initSlice";
import { AccountMenu } from "../../../components";
import styles from "../../../styles/Account.module.css";
import edit from "../../../assets/edit.svg";
import useDocumentTitle from "../../../hooks/useDocumentTitle";
import userPrefService from "../../../services/user-preferences";
import { toast, ToastContainer } from "react-toastify";

const AccountSettings = () => {
  useDocumentTitle("Account Settings");
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.init);
  const [Loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [resCode, setResCode] = useState(null);
  const [messageEmail, setMessageEmail] = useState("");
  const { localization, isLoading } = useSelector((state) => state.init);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const formik = useFormik({
    initialValues: {
      name: profile?.is_guest === false ? profile?.name : "",
      last_name: profile?.is_guest === false ? profile?.last_name : "",
      email: profile?.is_guest === false ? profile?.email : "",
      phone: profile?.is_guest === false ? profile?.phone : "",
    },
    onSubmit: (values) => {
      const { name, last_name, phone } = formik.values;
      setLoading(true);
      console.log(values);

      userPrefService
        .editProfile({ name, last_name, phone })
        .then((res) => {
          if (res?.code !== 200) {
            setResCode(res?.code);
            toast.error(res?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              containerId: "editProfile",
              theme: "colored",
            });
          } else if (res?.code === 200) {
            toast.success(res?.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              containerId: "editProfile",
              theme: "colored",
            });
          }
          setLoading(false);
          setResCode(res?.code);
          console.log(res);
          setMessage(res.message);
          console.log(res.message);
          dispatch(editProfileData(res.data));
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .max(
          20,
          document.body.dir === "rtl"
            ? "الحد الأقصى 20 حرف"
            : "Max 20 characters"
        )
        // don't accept empty spaces
        .matches(/^[^\s]+$/, {
          message:
            document.body.dir === "rtl" ? "الاسم غير صحيح" : "Name is invalid",
        })

        .required(
          document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is required"
        ),
      last_name: Yup.string()
        .required(
          document.body.dir === "rtl"
            ? "الاسم الأخير مطلوب"
            : "Last name is required"
        )
        .max(
          20,
          document.body.dir === "rtl"
            ? "الحد الأقصى 20 حرف"
            : "Max 20 characters"
        ),

      phone: Yup.string()
        .typeError(
          document.body.dir === "rtl"
            ? "رقم الهاتف غير صحيح"
            : "Phone number is invalid"
        )
        //matches regex for phone 10 digits or 11 digits
        .matches(/^\d+$/, {
          message:
            document.body.dir === "rtl"
              ? "رقم الهاتف غير صحيح"
              : "Phone number is invalid",
        })
        .min(
          10,
          document.body.dir === "rtl"
            ? "رقم الهاتف 10 على الأقل"
            : "Phone number is 10 at least"
        )
        .max(
          11,
          document.body.dir === "rtl"
            ? "رقم الهاتف 11 على الأكثر"
            : "Phone number is 11 at most"
        )
        .required(
          document.body.dir === "rtl"
            ? "رقم الهاتف مطلوب"
            : "Phone number is required"
        ),
    }),

    enableReinitialize: true,
  });
  const { name, last_name, email } = formik.values;

  const emailFormik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: (values) => {
      const { email } = emailFormik.values;
      setLoadingEmail(true);
      console.log(values);
      userPrefService
        .editEmail({ email })
        .then((res) => {
          setLoadingEmail(false);
          console.log(res);
          setMessageEmail(res);
          console.log(res);
        })
        .catch((err) => {
          setLoadingEmail(false);
          console.log(err);
        });
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required(
          document.body.dir === "rtl"
            ? "البريد الإلكتروني مطلوب"
            : "Email is required"
        )
        //matches regex for email
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          document.body.dir === "rtl"
            ? "البريد الإلكتروني غير صحيح"
            : "Email is invalid"
        )
        .email(
          document.body.dir === "rtl"
            ? "البريد الإلكتروني غير صحيح"
            : "Email is invalid"
        ),
    }),
  });

  return (
    <>
      {/* <Navbarr /> */}
      <div className={styles.account_main}>
        <AccountMenu active={"Account Settings"} />
        <form onSubmit={formik.handleSubmit} className={styles.account_body}>
          <div className={styles.account_body}>
            <div className={styles.account_body_title}>
              <h1>{t("Account Information")}</h1>
            </div>
            <div
              className={styles.account_body_content}
              style={{ width: "unset" }}
            >
              <div className={styles.settings_content}>
                <h5 className={styles.settings_content__title}>
                  {t("Personal Information")}
                </h5>
                <div className={styles.settings_content__fields}>
                  <div className={styles.addNew_input}>
                    <div className={styles.addNew_label} htmlFor="name">
                      {t("First Name")}:
                    </div>
                    <input
                      placeholder={profile?.name}
                      type="text"
                      id="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={name}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className={styles.error}>{formik.errors.name}</div>
                    )}
                  </div>

                  <div className={styles.addNew_input}>
                    <div className={styles.addNew_label} htmlFor="last_name">
                      {t("Last Name")}:
                    </div>
                    <input
                      placeholder={profile?.last_name}
                      type="text"
                      id="last_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={last_name}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <div className={styles.error}>
                        {formik.errors.last_name}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.SignForm_input}>
                <div className={styles.addNew_label} htmlFor="phone">
                  {t("Phone Number")}
                </div>
                <div className={styles.signForm_phone}>
                  {Loading ? (
                    <Skeleton
                      variant="rectangular"
                      width={50}
                      height={20}
                      style={{
                        borderRadius: "20px",
                        alignSelf: "center",
                        marginInlineEnd: "1rem",
                      }}
                    />
                  ) : (
                    <div className={styles.countryCode}>
                      <span>{localization?.phone_prefix}</span>
                      <img
                        src={localization?.flag}
                        alt="flag"
                        width="30px"
                        height="20px"
                      />
                    </div>
                  )}
                  <input
                    name="phone"
                    placeholder="01234567890"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    id="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    key="phone"
                  />
                </div>
                {formik.touched.phone && formik.errors.phone && (
                  <div style={{ color: "red" }}>{formik.errors.phone}</div>
                )}
              </div>
              <div className={styles.settings_content_divider}></div>
              <div className={styles.settings_content}>
                <h5 className={styles.settings_content__title}>
                  {t("Login Details")}
                </h5>
                <div className={styles.settings_content__fields}>
                  <div className={styles.addNew_input}>
                    <div
                      className={styles.addNew_label}
                      htmlFor="email"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{t("Email")}</span>
                      <div className={styles.settings_content__field_edit}>
                        <div
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontSize: "12px",
                            color: "#ff1d52",
                          }}
                          onClick={handleShow}
                        >
                          <img
                            src={edit}
                            alt="change password"
                            className={styles.passwordEdit}
                          />
                          <span>{t("Edit")}</span>
                        </div>
                        <Modal
                          open={show}
                          onClose={handleClose}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              bgcolor: "background.paper",
                              boxShadow: 24,
                              borderRadius: "20px",
                              p: 4,
                              width: "fit-content",
                              margin: "2rem auto",
                              border: "none",
                            }}
                          >
                            <h3>{t("Change Your Email")}</h3>
                            <div className={styles.NavLocationModal}>
                              <div className={styles.NavLocationModalDesc}>
                                {t("edit_email_desc")}
                              </div>
                              <form onSubmit={emailFormik.handleSubmit}>
                                <div className={styles.NavLocationModalDetails}>
                                  <div className={styles.addNew_input}>
                                    <div className={styles.addNew_label}>
                                      {t("Email")}
                                    </div>
                                    <input
                                      type="email"
                                      placeholder={profile?.email}
                                      id="email"
                                      onBlur={emailFormik.handleBlur}
                                      onChange={emailFormik.handleChange}
                                    />
                                    {emailFormik.touched.email &&
                                      emailFormik.errors.email && (
                                        <div className={styles.error}>
                                          {emailFormik.errors.email}
                                        </div>
                                      )}
                                    {messageEmail?.message ? (
                                      <Box
                                        sx={{
                                          width: "100%",
                                          borderRadius: "20px",
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <Collapse in={open}>
                                          <Alert
                                            severity={messageEmail?.type}
                                            sx={{ mb: 2 }}
                                          >
                                            {messageEmail?.message}
                                          </Alert>
                                        </Collapse>
                                      </Box>
                                    ) : null}
                                  </div>
                                </div>
                              </form>
                            </div>
                            {!messageEmail?.message && (
                              <div className={styles.ModalFooter}>
                                <button
                                  className={styles.addNewCard}
                                  type="submit"
                                  onClick={() => {
                                    emailFormik.handleSubmit();
                                  }}
                                  disabled={loadingEmail}
                                >
                                  {loadingEmail ? (
                                    <span className="spinner-border spinner-border-sm"></span>
                                  ) : (
                                    <span>{t("Submit")}</span>
                                  )}
                                </button>
                              </div>
                            )}
                          </Box>
                        </Modal>
                      </div>
                    </div>
                    <input
                      placeholder={profile?.email}
                      type="email"
                      id="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={email}
                      disabled
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className={styles.error}>{formik.errors.email}</div>
                    )}
                  </div>
                  <div className={styles.addNew_input}>
                    <div
                      className={styles.addNew_label}
                      htmlFor="password"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>{t("Password")}</span>
                      <div className={styles.settings_content__field_edit}>
                        <Link to="changepass">
                          <img
                            src={edit}
                            alt="change password"
                            className={styles.passwordEdit}
                          />
                          <span>{t("Edit")}</span>
                        </Link>
                      </div>
                    </div>
                    <input
                      placeholder="********"
                      type="password"
                      id="password"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className={styles.addNewCard}
                disabled={Loading}
              >
                {Loading ? (
                  <span className="spinner-border spinner-border-sm"></span>
                ) : (
                  <div className={styles.addNewCard_Btn__Text}>
                    {t("Save Changes")}
                  </div>
                )}
              </button>
              <ToastContainer containerId={"editProfile"} />
            </div>
          </div>
        </form>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AccountSettings;
