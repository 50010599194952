import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  resetTotalFilteredProducts,
  searchFilter,
} from "../store/products/productsSlice";
import { fetchProducts } from "../services/products.service";
import { useDispatch, useSelector } from "react-redux";
import cx from "classnames";
import { CategoryMenu, FloatingCart } from "../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "../styles/Navbar.module.css";
import Logo from "./../assets/logo-yallaFood.svg";
import SearchIcon from "../assets/searchIcon.svg";
import locationIcon from "../assets/location-icon.svg";
import dropMenu from "../assets/dropMenu.svg";
import SignIn from "../assets/SignIn.svg";
import Wish from "../assets/wishList.svg";
import cartIcon from "../assets/cart.svg";

import { useStateContext } from "../contexts/contextProvider";
import OffCanvasExample from "../constants/offCanvasMenu";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import Button from "react-bootstrap/Button";
import userPrefService from "../services/user-preferences";
import { profileFetching, startFetching } from "../store/init/initSlice";
import { Skeleton } from "@mui/material";

const Navbarr = () => {
  const [cities, setCities] = useState([]);
  const [chosenArea, setChosenArea] = useState([]);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [messageLocation, setMessageLocation] = useState("");
  const [selectedLoc, setSelectedLoc] = useState("");

  const config = JSON?.parse(localStorage.getItem("config"));

  const { profile, cart, isLoading, favourites } = useSelector(
    (state) => state.init
  );
  const { page_number } = useSelector((state) => state.products);
  const { favouritesCount } = useSelector((state) => state.wishlist);
  console.log(profile?.area, profile?.area?.active);
  const { cartItemQTY } = useSelector((state) => state.cartProducts);
  const { t, i18n } = useTranslation();

  //get the number of cartItems and its amounts

  const [userInfo, setUserInfo] = useState({});
  const { user } = useSelector((state) => state.auth);
  console.log(user);

  const stickyMsg = t("stickyMsg");

  // "LIMITED TIME LAUNCH OFFER - FREE SHIPPING for orders over 199 EUR - عرض لفترة محدودة - شحن مجاني للطلبات التي تزيد عن 199 جنيهًا مصريًا";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const handleSearch = (e) => {
    setSearchInput(e.target.value);
    //after submiting the search input, reset the search Input
  };
  const submitSearch = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      dispatch(searchFilter(searchInput));
      dispatch(fetchProducts({}, searchInput, {}, {}, {}, {}, 12));
      console.log(searchInput);
      navigate({
        pathname: "/products",
        search: `?q=${searchInput}`,
      });
      setSearchInput("");
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  /// Multi Inventory Feature

  useEffect(() => {
    if (
      (profile?.area === null || profile?.area?.active === false) &&
      config?.ENABLE_MULTI_INVENTORY
    ) {
      handleShow();
    }
  }, [config?.ENABLE_MULTI_INVENTORY, profile?.area, profile?.area?.active]);

  useEffect(() => {
    userPrefService.cities().then((res) => {
      console.log(res);
      setCities(res);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      city: "",
      area: "",
      city_id: "",
      area_id: "",
    },
    validationSchema: Yup.object({
      city: Yup.string().required(
        document.body.dir === "rtl" ? "مطلوب" : "Required"
      ),
      area: Yup.string().required(
        document.body.dir === "rtl" ? "مطلوب" : "Required"
      ),
    }),
    onSubmit: (values) => {
      const { city, area, city_id, area_id } = values;
      setIsLoadingLocation(true);
      console.log(values);
      userPrefService
        .set_area({ city_id: city_id, area_id: area_id })
        .then((res) => {
          setIsLoadingLocation(false);
          setSelectedLoc(res?.data?.area?.name);
          localStorage.setItem("area=location", JSON.stringify(res));
          setMessageLocation(res.message);
          console.log(res);
          if (res.code === 200) {
            dispatch(startFetching());
            dispatch(profileFetching(res.data));
            handleClose();
          }
        });
    },
  });

  const handleCityChange = (e) => {
    const selectedCity = cities.find((city) => city.name === e.target.value);
    setChosenArea(selectedCity.areas);
    const selectedGovernate = cities.find(
      (governate) => governate.name === e.target.value
    );
    formik.setFieldValue("city_id", selectedGovernate.id);
    formik.handleChange(e);
  };
  const handleAreaChange = (e) => {
    const selectedArea = chosenArea.find(
      (area) => area.name === e.target.value
    );
    formik.setFieldValue("area_id", selectedArea.id);
    formik.handleChange(e);
  };
  ////////////////////////////////////////////////////////// multi inventory feature
  // const { user } = useSelector((state) => state.auth);

  const getFirstWord = (str) => {
    // return the first word of the string uppercased
    return str.split(" ")[0].toUpperCase();

    // return str.upperCase().split(" ")[0];
  };
  const { setScreenSize, screenSize, activeMenuMobile, setActiveMenuMobile } =
    useStateContext();

  useEffect(() => {
    const handleScreenSize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleScreenSize);
    handleScreenSize();
    return () => window.removeEventListener("resize", handleScreenSize);
  }, []);

  useEffect(() => {
    screenSize < 576 && setActiveMenuMobile(true);
    screenSize >= 576 && setActiveMenuMobile(false);
  }, [screenSize, setActiveMenuMobile]);

  // const [searchResults, setSearchResults] = useState(
  //   filteredProducts.length > 0 && (
  //     <ul className={styles.searchResult}>
  //       {filteredProducts.map((product,index) => (
  //         <li>
  //           <Link
  //             className={styles.searchResultItem}
  //             to={`/product/${product.id}`}
  //             key={index}
  //           >
  //             <img
  //               className={styles.searchResultItemImg}
  //               width="100px"
  //               height="100px"
  //               src={product.image}
  //               alt="product"
  //             />
  //             <div className={styles.searchResultItemTxt}>
  //               <div className={styles.searchResultItemTxtTitle}>
  //                 {product.name}
  //               </div>
  //               <div className={styles.searchResultItemTxtPrice}>
  //                 {product.price}
  //                 {""}EUR
  //               </div>
  //             </div>
  //           </Link>
  //         </li>
  //       ))}
  //     </ul>
  //   )
  // );
  // // on click outside of the search results close the search results
  // const handleClickOutside = (e) => {
  //   if (searchResults && !searchResults.current.contains(e.target)) {
  //     setSearchResults(null);
  //   }
  // };
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);
  //   return () => document.removeEventListener("click", handleClickOutside);
  // }, [searchResults]);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <>
      <div className={styles.navbar}>
        {config?.ENABLE_SOFTBAR && (
          <div className={styles.stickyNav}>
            <div className={styles.stickyNavtxt}>{stickyMsg}</div>
            <ul className={styles.stickyNavls}>
              <li>
                <a href="/#" alt="customer_Support">
                  {t("customer_support")}
                </a>
              </li>

              <li>
                {" "}
                <button
                  onClick={() => {
                    document.body.dir = i18n.dir() === "rtl" ? "ltr" : "rtl";
                    //if the language is arabic change it to english and vice versa
                    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
                    localStorage.setItem(
                      "lang",
                      i18n.language === "ar" ? "en" : "ar"
                    );
                    // toggle document.body.id from "en" to "ar" and vice versa
                    document.body.id = i18n.language === "ar" ? "en" : "ar";
                  }}
                  className={styles.langBtn}
                  style={
                    document.body.dir === "ltr"
                      ? { fontFamily: "Vazirmatn, sans-serif" }
                      : { fontFamily: "CircularStd-Black" }
                  }
                >
                  {t("lang")}
                </button>
              </li>
            </ul>
          </div>
        )}

        <div className={styles.Container}>
          <nav color="green" className={styles.HeaderNav}>
            {activeMenuMobile && (
              <OffCanvasExample className={styles.mobileMenuIcon} />
            )}

            <Link to="/">
              <div className={styles.logo}>
                <img src={Logo} alt="logo" />
              </div>
            </Link>
            <div className={styles.searchBar}>
              <div className={styles.searchIcon}>
                <img
                  className={styles.searchIconSvg}
                  src={SearchIcon}
                  alt="search"
                />
              </div>
              <input
                color="inherit"
                className={styles.searchInput}
                type="text"
                placeholder={t("search_placeholder")}
                // "Search for product .."
                value={searchInput}
                onChange={handleSearch}
                onKeyDown={submitSearch}
                on
              />
              {/* {activeMenuMobile && (
                <div className={styles.searchIcon}>
                  <img
                    className={styles.searchIconSvg}
                    src={SearchIcon}
                    alt="search"
                  />
                </div>
              )} */}

              {/* {searchResults && (
                <div className={styles.searchResults} ref={searchResults}>
                  {searchResults}
                </div>
              )} */}
              {/* {filteredProducts.length > 0 && (
                <ul className={styles.searchResult}>
                  {filteredProducts.map((product) => (
                    <li>
                      <Link
                        className={styles.searchResultItem}
                        to={`/product/${product.id}`}
                      >
                        <img
                          className={styles.searchResultItemImg}
                          width="100px"
                          height="100px"
                          src={product.image}
                          alt="product"
                        />
                        <div className={styles.searchResultItemTxt}>
                          <div className={styles.searchResultItemTxtTitle}>
                            {product.name}
                          </div>
                          <div className={styles.searchResultItemTxtPrice}>
                            {product.price}{""}EUR
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              )} */}
            </div>

            <ul className={styles.navItems}>
              {config?.ENABLE_MULTI_INVENTORY && (
                <li>
                  <div className={cx(styles.navItem, styles.location)}>
                    <div className={styles.locationIcon}>
                      <img
                        className={styles.locationSvg}
                        src={locationIcon}
                        alt="location"
                      />
                    </div>
                    <div className={styles.locationTxt}>{t("Deliver to")}</div>
                    <span className={styles.areaAnchor}>
                      <button
                        style={{
                          color: "#FF1D52",
                          border: "none",
                          background: "none",
                          textDecoration: "underline",
                          fontWeight: "600",
                        }}
                        href="/#"
                        onClick={
                          config?.ENABLE_MULTI_INVENTORY ? handleShow : null
                        }
                      >
                        {profile?.area?.name
                          ? document.body.dir === "rtl"
                            ? profile?.area?.name_ar
                            : profile?.area?.name
                          : t("select_area_textBtn")}
                      </button>
                    </span>
                    <Modal
                      className={styles.ModalLocationAll}
                      open={show}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box
                        sx={{
                          bgcolor: "background.paper",
                          boxShadow: 24,
                          borderRadius: "20px",
                          p: 4,
                          width: "fit-content",
                          margin: "2rem auto",
                        }}
                      >
                        <h3 style={{ fontWeight: "600" }}>
                          {t("Select Area")}
                        </h3>
                        <div
                          style={{
                            borderRaduis: "30px",
                          }}
                        >
                          {cart?.length > 0 ? (
                            <div className={styles.modalCart}>
                              {t("Changing your area will clear your cart")}
                            </div>
                          ) : null}
                          <form
                            onSubmit={formik.handleSubmit}
                            style={{ borderRadius: "30px" }}
                          >
                            <div className={styles.NavLocationModal}>
                              <div className={styles.NavLocationModalDesc}>
                                {/* Choose your area so that we can easily estimate the
                          delivery time and cost for you */}
                                {t("select_area_info")}
                              </div>
                              <div className={styles.NavLocationModalDetails}>
                                <div className={styles.GovernLocation}>
                                  <div className={styles.GovernLocationTitle}>
                                    {t("Government*")}
                                  </div>
                                  <div className={styles.addNew_input}>
                                    <select
                                      name="city"
                                      id="city"
                                      onChange={handleCityChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.city}
                                    >
                                      <option value="" disabled selected>
                                        {t("Select Governate")}
                                      </option>
                                      {cities.map((item) => (
                                        <option value={item.name} id={item.id}>
                                          {document.body.dir === "rtl"
                                            ? item.name_ar
                                            : item.name}
                                        </option>
                                      ))}
                                    </select>
                                    {formik.touched.city &&
                                      formik.errors.city && (
                                        <div className={styles.error}>
                                          {formik.errors.city}
                                        </div>
                                      )}
                                  </div>
                                  <div className={styles.addNew_input}>
                                    <div
                                      className={styles.addNew_label}
                                      htmlFor="areas"
                                    >
                                      Areas
                                    </div>

                                    <select
                                      name="area"
                                      id="area"
                                      onChange={handleAreaChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.area}
                                    >
                                      <option value="" disabled selected>
                                        {t("Select Area")}
                                      </option>
                                      {chosenArea.map((city) => (
                                        <option value={city.name}>
                                          {document.body.dir === "rtl"
                                            ? city.name_ar
                                            : city.name}
                                        </option>
                                      ))}
                                    </select>
                                    {formik.touched.area &&
                                      formik.errors.area && (
                                        <div className={styles.error}>
                                          {formik.errors.area}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Button
                              type="submit"
                              className={styles.ModalLocationBtn}
                              disabled={formik.isSubmitting}
                              // onClick={handleClose}
                            >
                              {isLoadingLocation ? (
                                <span className="spinner-border spinner-border-sm"></span>
                              ) : (
                                <span>{t("confirm_location_textBtn")}</span>
                              )}
                            </Button>
                          </form>
                        </div>
                        <div className={styles.ModalFooter}></div>
                      </Box>
                    </Modal>
                    <span>
                      <img
                        src={dropMenu}
                        alt="dropDown"
                        className={styles.dropMenu}
                      />
                    </span>
                  </div>
                </li>
              )}
              <li>
                {" "}
                <button
                  onClick={() => {
                    document.body.dir = i18n.dir() === "rtl" ? "ltr" : "rtl";
                    //if the language is arabic change it to english and vice versa
                    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar");
                    localStorage.setItem(
                      "lang",
                      i18n.language === "ar" ? "en" : "ar"
                    );
                    // toggle document.body.id from "en" to "ar" and vice versa
                    document.body.id = i18n.language === "ar" ? "en" : "ar";
                  }}
                  className={styles.langBtn}
                  style={
                    document.body.dir === "ltr"
                      ? { fontFamily: "Vazirmatn, sans-serif" }
                      : { fontFamily: "CircularStd-Black" }
                  }
                >
                  {t("lang")}
                </button>
              </li>
              <li>
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={window.innerWidth > 600 ? 100 : null}
                    height={20}
                    style={{
                      borderRadius: "20px",
                    }}
                  />
                ) : (
                  <div className={cx(styles.navItem, styles.sign)}>
                    <div className={styles.signInIcon}>
                      <Link
                        to={
                          profile?.is_guest === false
                            ? "/customer/account"
                            : "/customer/account/signin"
                        }
                      >
                        <img
                          src={SignIn}
                          alt="sign"
                          className={styles.SignInSvg}
                        />
                        {/* if there is a user logged in then it will show the name of the user */}
                        <div className={styles.signTxt}>
                          {profile?.is_guest !== true
                            ? `${t("hi")}
                        , ${profile?.name}`
                            : ` ${t("sign_in")}`}
                        </div>
                        {/* <div className={styles.signTxt}>
                        {profile?.is_guest !== true || user
                          ? `${t("hi")}
                        , ${profile?.name} || ${user?.name}`
                          : ` ${t("sign_in")}`}
                      </div> */}
                      </Link>
                    </div>
                  </div>
                )}
              </li>
              <li>
                <Link
                  className={styles.wishlist}
                  to={
                    profile?.is_guest !== true
                      ? "/customer/wishlist"
                      : "/customer/wishlist/guest"
                  }
                >
                  <div className={cx(styles.navItem, styles.wishlist)}>
                    <img src={Wish} alt="wishList" className={styles.WishSvg} />
                  </div>
                  {favouritesCount > 0 ? (
                    <span className={styles.cartMiniCount}>
                      {favouritesCount}
                    </span>
                  ) : null}
                </Link>
              </li>
              <li onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <Link className={styles.cartMini} to="/cart">
                  <div className={styles.navItem}>
                    <img src={cartIcon} alt="cart" className={styles.cartSvg} />
                  </div>
                  {cartItemQTY > 0 ? (
                    <span className={styles.cartMiniCount}>{cartItemQTY}</span>
                  ) : null}
                </Link>
                {isHovering && !activeMenuMobile ? <FloatingCart /> : null}
              </li>
            </ul>
          </nav>
          {activeMenuMobile && (
            <div className={styles.searchBar_mobile}>
              <div className={styles.searchIcon}>
                <img
                  className={styles.searchIconSvg}
                  src={SearchIcon}
                  alt="search"
                />
              </div>
              <input
                color="inherit"
                className={styles.searchInput}
                type="text"
                placeholder={t("search_placeholder")}
                // "Search for product .."
                value={searchInput}
                onChange={handleSearch}
                onKeyDown={submitSearch}
                style={{ fontSize: "12px" }}
              />
            </div>
          )}
          <div className={styles.divider}></div>
          <div className={styles.categoriesMenu}>
            <CategoryMenu />
            {/* <div className={styles.categoriesDeals}>
              <div
                className={styles.newAriv}
                onClick={() => {
                  dispatch(resetTotalFilteredProducts());
                  dispatch(
                    fetchProducts(
                      page_number,
                      {},
                      {},
                      [],
                      {},
                      {},
                      12,
                      {},
                      {},
                      {},
                      "recent"
                    )
                  );
                  navigate("/products?sort=recent");
                }}
              >
                <div className={styles.newArivImg}>
                  <img src={newArrivals} alt="newArrivals" />
                </div>
                <div className={styles.newArrivalsTxt}>{t("new_arrivals")}</div>
              </div>
              <div
                style={{ marginInlineEnd: "0" }}
                className={styles.newDealsProd}
                onClick={() => {
                  dispatch(
                    fetchProducts(
                      page_number,
                      {},
                      {},
                      {},
                      {},
                      {},
                      12,
                      {},
                      {},
                      true,
                      {}
                    )
                  );
                  navigate("/products?promotions=true");
                }}
              >
                <div className={styles.newDealsImg}>
                  <img src={newDeals} alt="newDeals" />
                </div>
                <div className={styles.newDealsTxt}>{t("new_deals")}</div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbarr;
