import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const { profile } = useSelector((state) => state.init);

  if(profile?.is_guest === true) {
   return  <Navigate to="/customer/account/signin"/>
  }
  return children;
};

export default RequireAuth;
