import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import cx from "classnames";
import { initialize } from "../services/initialize";
import { useNavigate } from "react-router-dom";
import { filterByCategoryId } from "../store/products/productsSlice";
import { placeholderImg } from "../constants/placeholderImg";
import { fetchProducts } from "../services/products.service";
import { useTranslation } from "react-i18next";

import styles from "../styles/CategoriesCarousel.module.css";
import category_1 from "../assets/categories/category_1.svg";
import category_2 from "../assets/categories/category_2.svg";
import category_3 from "../assets/categories/category_3.svg";
import category_4 from "../assets/categories/category_4.svg";
import { Skeleton } from "@mui/material";

const CategoriesCarousel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cat, setCat] = useState([]);
  const { promotionsSearch, sort } = useSelector((state) => state.products);
  const { t, i18n } = useTranslation();

  // if (localStorage.getItem("init")) {
  //   let cat = JSON.parse(localStorage.getItem("init")).data.categories;
  // } else {
  //   let cat = [];
  // }
  // const categoriesInit = JSON.parse(localStorage.getItem("init")).data.categories;
  // console.log(cat);

  const { categories, isLoading } = useSelector((state) => state.init);
  console.log(categories);
  useEffect(() => {
    dispatch(initialize());
    setCat(categories);
    console.log(categories);
    console.log(JSON.stringify(cat));
  }, []);

  console.log(cat);

  const handleClickCategory = (id) => {
    dispatch(
      fetchProducts(1, {}, id, {}, {}, {}, 12, {}, {}, promotionsSearch, sort)
    );
    navigate(`/products?category_id=${id}`);
  };
  return (
    <div className={styles.CategoriesCarousal}>
      <div className={styles.CategoriesTitle}>
        {t("categoriesCarousel.categoriesTitle")}
      </div>
      <div className={styles.CategoriesProductsContainer}> 
      
        {isLoading
          ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
              <Skeleton
                key={item}
                variant="rectangular"
                width={150}
                height={150}
                animation="pulse"
                style={{ marginInlineEnd: "10px", borderRadius: "20px" }}
              />
            ))
          : categories
          ? categories.map((cat, index) => (
              <div
                key={index}
                className={cx(styles.CategoriesItem)}
                onClick={() => handleClickCategory(cat.id)}
              >
                <img
                  className={styles.categoryImg}
                  src={cat.image}
                  alt="category"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = placeholderImg;
                  }}
                />
                <div className={styles.CategoriesName}>
                  <div>
                    {document.body.dir === "rtl" ? cat.name_ar : cat.name}
                  </div>
                </div>
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default CategoriesCarousel;
