import { createSlice } from "@reduxjs/toolkit";
import userPrefService from "../../services/user-preferences";

// const {user} = JSON.parse(localStorage.getItem("user"));

//variant_id is for checking if the product that we interact with is in product details page or in product card
const variant_id = window.location.search.split("variant=")[1];
console.log(variant_id);

const initialState = {
  cartItems: [],
  isOpen: false,
  isEmpty: true,
  totalSum: 0,
  itemAdded: false,
  itemRemoved: false,
  cartItemQTY: 0,
  singleCartItem: [],
  loadingCart: true,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    cartItemsFetching(state, action) {
      state.cartItems = action.payload;
      //get the amount  of items in the cart
      state.cartItemQTY = action.payload?.items?.reduce((acc, item) => {
        return acc + item.amount;
      }, 0);
      //get the total sum of the cart
      state.totalSum = action.payload?.grand_total;
    },

    addProduct(state, action) {
      // state.loadingCart = true;
      if (action.payload.parent_id === null) {
        state.cartItems?.items?.push({
          ...action.payload.product_variants[0],
          amount: 1,
        });
        // add the amount for the product that we just added to the cart
        state.cartItems = {
          ...state.cartItems,
          grand_total:
            state.cartItems?.grand_total +
              action.payload.product_variants[0]?.discount_price ||
            action.payload.product_variants[0]?.price,
          items: state.cartItems?.items,
          amount: 1,
        };
      } else {
        state.cartItems?.items?.push({ ...action.payload, amount: 1 });
      }

      if (state.isEmpty) state.isEmpty = false;
      state.totalSum += action.payload.grand_total;
      state.itemAdded = true;
      console.log(action.payload.amount);
      state.cartItemQTY += 1;
      // userPrefService
      //   .addCartItems({
      //     items: [
      //       {
      //         id:
      //           Number(window.location.search.split("variant=")[1]) ||
      //           action.payload.id,
      //         amount: 1,
      //       },
      //     ],
      //     reset: false,
      //   })
      //   .then((res) => {
      //     state.loadingCart = false;
      //     state.totalSum = res.grand_total;

      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //   });
      console.log(state.totalSum);
    },
    setTotalSum(state, action) {
      state.totalSum = action.payload;
    },

    removeProduct(state, action) {
      // state.loadingCart = true;
      let count;
      state.cartItems.items = state.cartItems?.items?.map((item) => {
        if (
          item.id ===
          (Number(window.location.search.split("variant=")[1]) ||
            action.payload.id)
        ) {
          state.cartItemQTY -= item.amount;
          count = item.amount;
          console.log(count);
        }
        return item;
      });

      console.log(action.payload.amount);
      // state.totalSum -=
      //   count * action.payload.discount_price || action.payload.price;
      state.cartItems.items = state.cartItems.items.filter(
        (item) =>
          item.id !==
          (Number(window.location.search.split("variant=")[1]) ||
            action.payload.id)
      );
      console.log(
        (state.cartItems.items = state.cartItems.items.filter(
          (item) =>
            item.id !==
            (Number(window.location.search.split("variant=")[1]) ||
              action.payload.id)
        ))
      );
      if (state.cartItems?.items?.length === 0) state.isEmpty = true;
      console.log(action.payload);
      state.itemRemoved = true;
      state.itemAdded = false;
      // userPrefService.addCartItems({
      //   items: [
      //     {
      //       id:
      //         Number(window.location.search.split("variant=")[1]) ||
      //         action.payload.id,
      //       amount: 0,
      //     },
      //   ],
      //   reset: false,
      // })
      // .then((res) => {
      //   state.loadingCart = false;
      //   state.totalSum = res.grand_total;

      //   console.log(res);
      // })
      // .catch((err) => {
      //   console.error(err);
      // });
    },

    increaseCount(state, action) {
      // state.loadingCart = true;
      let count;
      state.cartItems.items = state.cartItems?.items?.map((item) => {
        if (
          item.id ===
          (Number(window.location.search.split("variant=")[1]) ||
            action.payload.id)
        ) {
          item.amount += 1;
          count = item.amount;
          console.log(count);
        }
        return item;
      });
      console.log(count);
      state.totalSum += action.payload.grand_total;
      state.cartItemQTY += 1;

      // userPrefService.addCartItems({
      //   items: [
      //     {
      //       id:
      //         Number(window.location.search.split("variant=")[1]) ||
      //         action.payload.id,

      //       amount: count,
      //     },
      //   ],
      //   reset: false,
      // })
      // .then((res) => {
      //   state.loadingCart = false;
      //   state.totalSum = res.grand_total;

      //   console.log(res);
      // })
      // .catch((err) => {
      //   console.error(err);
      // });
    },

    decreaseCount(state, action) {
      let count;
      // state.loadingCart = true;
      state.cartItems.items = state.cartItems.items.map((item) => {
        if (
          item.id ===
          (Number(window.location.search.split("variant=")[1]) ||
            action.payload.id)
        ) {
          item.amount -= 1;
          count = item.amount;
          console.log(count);
        }
        return item;
      });
      state.totalSum -= action.payload.grand_total;
      state.cartItemQTY -= 1;

      //if product amount is 0 remove it from the cart

      // userPrefService.addCartItems({
      //   items: [
      //     {
      //       id:
      //         Number(window.location.search.split("variant=")[1]) ||
      //         action.payload.id,
      //       amount: count,
      //     },
      //   ],
      //   reset: false,
      // })
      // .then((res) => {
      //   state.loadingCart = false;
      //   state.totalSum = res.grand_total;

      //   console.log(res);
      // })
      // .catch((err) => {
      //   console.error(err);
      // });
    },
    clearCart(state) {
      state.cartItemQTY = 0;
      state.cartItems = [];
    },
    toggleCart(state, action) {
      state.isOpen = action.payload;
    },
    toggleItemAdded(state, action) {
      state.itemAdded = action.payload;
    },
    toggleItemRemoved(state, action) {
      state.itemRemoved = action.payload;
    },
    getCartItemQTY(state, action) {
      state.cartItems.reduce((acc, item) => {
        acc += item.quantity;
        return acc;
      }, 0);
      state.cartItemQTY = action.payload;
    },
    getQTYOfSingleProduct(state, action) {
      state.cartItems.items.reduce((acc, item) => {
        if (JSON.stringify(item.product) === JSON.stringify(action.payload)) {
          acc = item.quantity;
        }
        return acc;
      }, 0);
      //add the quantity of the product to the state on every click

      state.singleCartItem.push(action.payload);
    },
  },
});

export const {
  addProduct,
  toggleCart,
  toggleItemAdded,
  toggleItemRemoved,
  removeProduct,
  increaseCount,
  decreaseCount,
  clearCart,
  getCartItemQTY,
  getQTYOfSingleProduct,
  cartItemsFetching,
  setTotalSum,
} = cartSlice.actions;

export default cartSlice.reducer;
