import React from "react";
import { Link } from "react-router-dom";

import { Navbarr, Footer, TimelineBar, AddNewAddress } from "../../components";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import styles from "../../styles/Checkout.module.css";

const CheckoutNewAddress = () => {
  const config = JSON?.parse(localStorage.getItem("config"));
  useDocumentTitle(
    document.body.dir === "rtl"
      ? ` إضافة عنوان جديد | ${config?.APP_NAME_AR}`
      : `Add New Address | ${config?.APP_NAME}`
  );
  return (
    <>
      <div className={styles.all_container}>
        <div className={styles.newAdd_Container}>
          <TimelineBar />
          <div className={styles.newAdd_Fields}>
            <AddNewAddress />
          </div>
        </div>
      </div>
    </>
  );
};

export default CheckoutNewAddress;
