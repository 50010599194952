import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import { ProductCard } from "../components";
import styles from "../styles/ProductsList.module.css";
import { Skeleton } from "@mui/material";
import { fetchProducts } from "../services/products.service";
import { useDispatch } from "react-redux";
import useDocumentTitle from "../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useScrollTop from "../hooks/useScrollTop";
import AddIcon from "@mui/icons-material/Add";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import NoProducts from "../assets/No_products.svg";

import {
  filterOrSortProducts,
  resetTotalFilteredProducts,
  increasePageNumber,
  resetPageNumber,
} from "../store/products/productsSlice";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";

const ProductsList = () => {
  const config = JSON?.parse(localStorage.getItem("config"));
  useDocumentTitle(
    document.body.dir === "rtl"
      ? `البحث | ${config?.APP_NAME_AR}`
      : `Search | ${config?.APP_NAME}`
  );
  const { t } = useTranslation();
  useScrollTop();
  const { localization } = useSelector((state) => state.init);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [categorySearch, setCategorySearch] = useState("");
  const [filterPrice, setFilterPrice] = useState("");
  const [priceSearch, setPriceSearch] = useState("");
  const [filterSorting, setFilterSorting] = useState("");
  const [sortingSearch, setSortingSearch] = useState("");
  const [filterSub, setFilterSub] = useState("");
  const [subSearch, setSubSearch] = useState("");
  const [filterBrand, setFilterBrand] = useState("");
  const [searchBrand, setSearchBrand] = useState("");
  const [pageSize, setPageSize] = useState(12);
  const [showMoreEnable, setShowMoreEnable] = useState(false);
  const [subCat_id, setSubCat_id] = useState([]);
  // const [sub_array, setSub_array] = useState([]);
  let sub_array = [];

  const {
    isLoading,
    filteredProducts,
    search,
    totalNumber,
    products,
    categoriesSearch,
    subCategoriesSearch,
    brandsSearch,
    promotionSearch,
    page_number,
    maxPriceSearch,
    minPriceSearch,
    totalFilteredProducts,
    category_id,
    sub_categories,

    sort,
  } = useSelector((state) => state.products);
  const { categories } = useSelector((state) => state.init);

  console.log(totalNumber);
  const [page, setPage] = useState(page_number);
  const searchResult = window.location.search
    .split("=")[1]
    ?.replace(/%20/g, " ");
  const searchInputURL = // get the term that after the q= in the url whatever it is english or arabic
    window.location.search.split("q=")[1]?.split("&")[0]?.replace(/%20/g, " ");

  const categoryId = window.location.search
    .split("category_id=")[1]
    ?.split("&")[0]
    ?.replace(/%20/g, " ");
  const subCategory = window.location.search
    .split("sub_category_id=")[1]
    ?.split("&")[0]
    ?.replace(/%20/g, " ");
  //
  const group = window.location.search
    .split("group_id=")[1]
    ?.split("&")[0]
    ?.replace(/%20/g, " ");
  const promotion = window.location.search
    .split("promotions=")[1]
    ?.split("&")[0]
    ?.replace(/%20/g, " ");
  // const sortFilter = window.location.search.split("sort=")[1].split("&")[0];
  //get the term that after the sort= in the url and split it by the & to get the sort value
  const sortFilter = window.location.search
    .split("sort=")[1]
    ?.split("&")[0]
    ?.replace(/%20/g, " ");

  const fillSubArray = () => {
    categories
      ?.find((x) => x.id === Number(categoryId))
      ?.groups.forEach((x, index) => {
        sub_array.concat((sub_array = [...sub_array, x.sub_categories]));
      });
  };
  fillSubArray();
  console.log(sub_array[0]);
  //remove any %20 from the sort value

  console.log(
    "searchResult",
    searchInputURL,
    "categoryId",
    categoryId,
    "subCategory",
    subCategory,
    "group",
    group,
    "promotion",
    promotion,
    "sortFilter",
    sortFilter
  );

  const loadProductsAfterRefresh = () => {
    if (localization.length === 0) {
      dispatch(
        fetchProducts(
          page,
          searchInputURL ? searchInputURL : {},
          categoryId ? categoryId : {},
          subCategory ? [Number(subCategory)] : [],
          {},
          {},
          pageSize,
          {},
          {},
          // convert promotion to boolean
          promotionSearch,
          sortFilter ? sortFilter : sort ? sort : {}
        )
      );
    }
    if (products.length > 0) {
      return null;
    }
  };

  useEffect(() => {
    loadProductsAfterRefresh();
  }, []);

  //set a state called productsArray and set it to the products array from the redux store
  const [productsArray, setProductsArray] = useState(totalFilteredProducts);
  // console.log(productsArray, products);
  // useEffect(() => {
  //   setProductsArray(products);
  // }, []);

  //if url changes then change showMoreEnable to false
  useEffect(() => {
    setShowMoreEnable(false);
    dispatch(resetTotalFilteredProducts());
  }, [window.location.search]);

  const moreProducts = () => {
    console.log(page_number);
    dispatch(increasePageNumber());

    // setPage(page_number + 1);

    console.log(page_number);
    setShowMoreEnable(true);
    // dispatch(filterOrSortProducts({showMoreEnable:true}));
    dispatch(
      fetchProducts(
        page_number + 1,
        searchInputURL ? searchInputURL : {},
        categoryId ? categoryId : {},
        subCategory ? [Number(subCategory)] : [],
        group ? group : {},
        {},
        pageSize,
        {},
        {},
        promotionSearch,
        sortFilter ? sortFilter : sort ? sort : {}
      )
    );
    //add the new products to the existing products
    setProductsArray([...productsArray, ...products]);
    console.log(productsArray.length);
  };

  const handleChangeFilterCategory = (event) => {
    setFilterCategory(event.target.value);
  };
  const handleChangeFilterPrice = (event) => {
    setFilterPrice(event.target.value);
  };
  const handleChangeFilterSorting = (event) => {
    setFilterSorting(event.target.value);
  };
  const handleChangeFilterSub = (event) => {
    setFilterSub(event.target.value);
  };
  const handleChangeFilterBrand = (event) => {
    setFilterBrand(event.target.value);
  };
  const lowToHigh = () => {
    dispatch(filterOrSortProducts({ sort: "low" }));
    //change the url to low
    window.history.pushState(
      {},
      "",
      `/products?q=${searchInputURL ? searchInputURL : ""}&category_id=${
        categoryId ? categoryId : ""
      }&sub_category_id=${subCategory ? subCategory : []}&group_id=${
        group ? group : ""
      }&promotions=${promotion ? promotion : ""}&sort=low`
    );

    // dispatch(filterOrSortProducts({showMoreEnable:false}));
    // setShowMoreEnable(false)
    console.log(promotionSearch);
    dispatch(
      fetchProducts(
        {},
        searchInputURL ? searchInputURL : {},
        categoryId ? categoryId : {},
        subCategory ? [Number(subCategory)] : [],
        group ? group : {},
        {},
        showMoreEnable ? totalFilteredProducts.length : products.length,
        {},
        {},
        promotionSearch,
        "low"
      )
    );
    // dispatch(filterOrSortProducts({products: products}));
    console.log("low to high");
    setSortingSearch("low");
    console.log(filteredProducts);
  };
  const highToLow = () => {
    dispatch(filterOrSortProducts({ sort: "high" }));
    //change the url to high
    window.history.pushState(
      {},
      "",
      `/products?q=${searchInputURL ? searchInputURL : ""}&category_id=${
        categoryId ? categoryId : ""
      }&sub_category_id=${subCategory ? subCategory : []}&group_id=${
        group ? group : ""
      }&promotions=${promotion ? promotion : ""}&sort=high`
    );

    // dispatch(filterOrSortProducts({showMoreEnable:false}));
    dispatch(
      fetchProducts(
        {},
        searchInputURL ? searchInputURL : {},
        categoryId ? categoryId : {},
        subCategory ? [Number(subCategory)] : [],
        group ? group : {},
        {},
        showMoreEnable ? totalFilteredProducts.length : products.length,
        {},
        {},
        promotionSearch,
        "high"
      )
    );
    setSortingSearch("high");
    console.log("high to low");
  };

  function category(item) {
    dispatch(filterOrSortProducts());
    dispatch(resetPageNumber());

    //change the url to category
    let element = item.item.groups.map((group) => group.sub_categories)[0];

    console.log(item.item.groups.map((group) => group.sub_categories));

    window.history.pushState(
      {},
      "",
      `/products?q=${searchInputURL ? searchInputURL : ""}&category_id=${
        item?.item?.id
      }&sub_category_id=${[]}&group_id=${group ? group : ""}&promotions=${
        promotion ? promotion : ""
      }&sort=${sortFilter ? sortFilter : ""}`
    );
    // dispatch(filterOrSortProducts({showMoreEnable:false}));
    dispatch(
      fetchProducts(
        1,
        searchInputURL ? searchInputURL : {},
        item?.item?.id,
        [],
        group ? group : {},
        {},
        pageSize,
        {},
        promotionSearch,
        sortingSearch
      )
    );
    setCategorySearch(item?.item?.id);
    // let sub = item.item.groups.map((group) => group.sub_categories);
    console.log(element);
    setSubCat_id(element);
  }

  function subCategorySearch(item) {
    dispatch(filterOrSortProducts());
    //change the url to subCategory
    setSubSearch(item);
    window.history.pushState(
      {},
      "",
      `/products?q=${searchInputURL ? searchInputURL : ""}&category_id=${
        categoryId ? categoryId : ""
      }&sub_category_id=${item ? item : []}&group_id=${
        group ? group : ""
      }&promotions=${promotion ? promotion : ""}&sort=${
        sortFilter ? sortFilter : ""
      }`
    );

    // dispatch(filterOrSortProducts({showMoreEnable:false}));
    dispatch(
      fetchProducts(
        {},
        searchInputURL ? searchInputURL : {},
        {},
        [Number(item)],
        {},
        {},
        pageSize,
        {},
        {},
        promotionSearch,
        sortingSearch
      )
    );
    console.log("subCategory" + item);
  }
  function brandSearch(item) {
    dispatch(filterOrSortProducts());
    setFilterBrand(item);
    //change the url to brand
    window.history.pushState(
      {},
      "",
      `/products?q=${searchInputURL ? searchInputURL : ""}&category_id=${
        categoryId ? categoryId : ""
      }&sub_category_id=${subCategory ? subCategory : []}&group_id=${
        group ? group : ""
      }&promotions=${promotion ? promotion : ""}&sort=${
        sortFilter ? sortFilter : ""
      }&brand=${filterBrand ? filterBrand : ""}`
    );

    // dispatch(filterOrSortProducts({showMoreEnable:false}));
    dispatch(fetchProducts({}, {}, {}, {}, item, {}, pageSize));
    console.log("brand" + item);
  }
  const AtoZ = () => {
    dispatch(filterOrSortProducts());
    setFilterSorting("a-z");
    //change the url to AtoZ
    window.history.pushState(
      {},
      "",
      `/products?q=${searchInputURL ? searchInputURL : ""}&category_id=${
        categoryId ? categoryId : ""
      }&sub_category_id=${subCategory ? subCategory : []}&group_id=${
        group ? group : ""
      }&promotions=${promotion ? promotion : ""}&sort=${filterSorting}`
    );
    // dispatch(filterOrSortProducts({showMoreEnable:false}));
    dispatch(
      fetchProducts(
        {},
        searchInputURL ? searchInputURL : {},
        categoryId ? categoryId : {},
        subCategory ? [Number(subCategory)] : [],
        group ? group : {},
        {},
        pageSize,
        {},
        {},
        promotionSearch,
        "a-z"
      )
    );
    console.log("AtoZ" + JSON.stringify(filteredProducts));
    console.log(productsArray);
  };
  const ZtoA = () => {
    dispatch(filterOrSortProducts());
    setFilterSorting("z-a");
    //change the url to z-a
    window.history.pushState(
      {},
      "",
      `/products?q=${searchInputURL ? searchInputURL : ""}&category_id=${
        categoryId ? categoryId : ""
      }&sub_category_id=${subCategory ? subCategory : []}&group_id=${
        group ? group : ""
      }&promotions=${promotion ? promotion : ""}&sort=${filterSorting}`
    );

    // dispatch(filterOrSortProducts({showMoreEnable:false}));
    dispatch(
      fetchProducts(
        {},
        searchInputURL ? searchInputURL : {},
        categoryId ? categoryId : {},
        subCategory ? [Number(subCategory)] : [],
        group ? group : {},
        {},
        showMoreEnable ? totalFilteredProducts?.length : products.length,
        {},
        {},
        promotionSearch,
        "z-a"
      )
    );
    console.log("ZtoA" + filteredProducts);
  };
  const mostPopular = () => {
    dispatch(filterOrSortProducts());
    setFilterSorting("popular");
    //change the url to mostPopular
    window.history.pushState(
      {},
      "",
      `/products?q=${searchInputURL ? searchInputURL : ""}&category_id=${
        categoryId ? categoryId : ""
      }&sub_category_id=${subCategory ? subCategory : []}&group_id=${
        group ? group : ""
      }&promotions=${promotion ? promotion : ""}&sort=${filterSorting}`
    );

    // dispatch(filterOrSortProducts({showMoreEnable:false}));
    dispatch(
      fetchProducts(
        {},
        searchInputURL ? searchInputURL : {},
        categoryId ? categoryId : {},
        subCategory ? [Number(subCategory)] : [],
        group ? group : {},
        {},
        showMoreEnable ? totalFilteredProducts?.length : products.length,
        {},
        {},
        promotionSearch,
        "popular"
      )
    );
    console.log("ZtoA" + filteredProducts);
  };

  console.log(
    categories
      .find((category) => category.id === Number(categoryId))
      ?.groups?.find((group) => group.id === Number(group)),

    categories,
    categoryId
  );

  const MUIFilters = (
    <>
      <FormControl
        sx={{
          m: 1,
          minWidth: 120,
          borderColor: "#FF1D52",
          borderRadius: "20px",
        }}
        size="small"
        variant="outlined"
      >
        <InputLabel
          id="demo-select-small"
          sx={{
            "&.MuiFormLabel-root": {
              color: "#FF1D52",
              left: document.body.dir === "rtl" && "unset",
              textAlign: "start",
              fontFamily: "unset",
              marginInlineStart: document.body.dir === "rtl" ? "30px" : "unset",
            },

            // "&.MuiInputLabel-root": {
            //   transform: "translate(14px, 12px) scale(1)",
            // },
          }}
        >
          {categories.find((x) => x.id === Number(categoryId)) &&
          document.body.dir === "rtl"
            ? categories.find((x) => x.id === Number(categoryId))?.name_ar
            : categories.find((x) => x.id === Number(categoryId))?.name
            ? categories.find((x) => x.id === Number(categoryId))?.name
            : document.body.dir === "rtl"
            ? "الفئة"
            : "Category"}
        </InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={filterCategory}
          label={
            categories.find((x) => x.id === Number(categoryId)) &&
            document.body.dir === "rtl"
              ? categories.find((x) => x.id === Number(categoryId))?.name_ar
              : categories.find((x) => x.id === Number(categoryId))?.name
              ? categories.find((x) => x.id === Number(categoryId))?.name
              : document.body.dir === "rtl"
              ? "الفئة"
              : "Category"
          }
          MenuProps={{
            anchorOrigin: {
              vertical: "left",
              horizontal: "center",
            },
            transformOrigin: {
              horizontal: "center",
              vertical: "left",
            },
            getContentAnchorEl: null,
            transitionDuration: 700,
          }}
          onChange={handleChangeFilterCategory}
          defaultValue={categoryId ? categoryId : ""}
          style={{
            borderRadius: "20px",
            borderColor: "#FF1D52",
            color: "#FF1D52",
            width: "218px",
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FF1D52",
              color: "#FF1D52",
            },

            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FF1D52",
              color: "#FF1D52",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#FF1D52",
              color: "#FF1D52",
            },
          }}
          IconComponent={() => (
            <AddIcon
              style={{
                color: "#FF1D52",
                width: "20px",
                margin: "9px",
                cursor: "pointer",
              }}
              onClick={handleChangeFilterCategory}
            />
          )}
        >
          {categories?.map((item) => (
            <MenuItem
              onClick={() => category({ item })}
              value={item.name}
              style={{ fontFamily: "unset" }}
            >
              {document.body.dir === "rtl" ? item.name_ar : item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {categoryId?.length > 0 ? (
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            borderColor: "#FF1D52",
            borderRadius: "20px",
          }}
          size="small"
        >
          <InputLabel
            id="demo-select-small"
            sx={{
              "&.MuiFormLabel-root": {
                color: "#FF1D52",
                left: document.body.dir === "rtl" && "unset",
                textAlign: "start",
                fontFamily: "unset",
                marginInlineStart:
                  document.body.dir === "rtl" ? "30px" : "unset",
              },
            }}
          >
            {categories?.find((x) => x.id === Number(categoryId)) &&
            categories
              ?.find((x) => x.id === Number(categoryId))
              ?.groups?.find((y) => y.id === Number(group)) &&
            categories
              ?.find((x) => x.id === Number(categoryId))
              ?.groups?.find((y) => y.id === Number(group))
              ?.sub_categories?.find((z) => z.id === Number(subCategory)) &&
            document.body.dir === "rtl"
              ? categories
                  ?.find((x) => x.id === Number(categoryId))
                  ?.groups?.find((y) => y.id === Number(group))
                  ?.sub_categories?.find((z) => z.id === Number(subCategory))
                  ?.name_ar
              : categories
                  ?.find((x) => x.id === Number(categoryId))
                  ?.groups?.find((y) => y.id === Number(group))
                  ?.sub_categories?.find((z) => z.id === Number(subCategory))
                  ?.name
              ? categories
                  ?.find((x) => x.id === Number(categoryId))
                  ?.groups?.find((y) => y.id === Number(group))
                  ?.sub_categories?.find((z) => z.id === Number(subCategory))
                  ?.name
              : subCategory?.length > 0
              ? document.body.dir === "rtl"
                ? subCategoriesSearch.find((x) => x.id === Number(subCategory))
                    ?.name_ar
                : subCategoriesSearch.find((x) => x.id === Number(subCategory))
                    ?.name
              : document.body.dir === "rtl"
              ? "الفئة الفرعية"
              : "Sub Category"}
          </InputLabel>

          <Select
            displayEmpty
            value={filterSub}
            label={
              document.body.dir === "rtl" ? "الفئة الفرعية" : "Sub Category"
            }
            onChange={handleChangeFilterSub}
            //defaultValue={subCat_id}
            style={{
              borderRadius: "20px",
              borderColor: "#FF1D52",
              color: "#FF1D52",
              width: "218px",
              fontFamily: "unset",
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF1D52",
                color: "#FF1D52",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF1D52",
                color: "#FF1D52",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF1D52",
                color: "#FF1D52",
              },
              ".MuiSelect-selectMenu": {
                paddingRight: "32px",
                /* font-family: unset; */
                fontFamily: "Vazirmatn, sans-serif !important",
              },
            }}
            IconComponent={() => (
              <AddIcon
                style={{
                  color: "#FF1D52",
                  width: "20px",
                  margin: "9px",
                }}
              />
            )}
          >
            {sub_array[0]?.map((item) => (
              <MenuItem
                onClick={() => subCategorySearch(item.id)}
                value={document.body.dir === "rtl" ? item.name_ar : item.name}
              >
                {document.body.dir === "rtl" ? item.name_ar : item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null}

      {brandsSearch?.length > 0 && (
        <FormControl
          sx={{
            m: 1,
            minWidth: 120,
            borderColor: "#FF1D52",
            borderRadius: "20px",
            width: "218px",
            fontFamily: "unset",
          }}
          size="small"
        >
          <InputLabel
            id="demo-select-small-2"
            sx={{
              "&.MuiFormLabel-root": {
                color: "#FF1D52",
                marginInlineEnd: "10px",
              },
            }}
          >
            {document.body.dir === "rtl" ? "العلامة التجارية" : "Brand"}
          </InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={filterBrand}
            label={document.body.dir === "rtl" ? "العلامة التجارية" : "Brand"}
            onChange={handleChangeFilterBrand}
            style={{
              borderRadius: "20px",
              //change the default color of the select
              borderColor: "#FF1D52",
              color: "#FF1D52",
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF1D52",
                color: "#FF1D52",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF1D52",
                color: "#FF1D52",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#FF1D52",
                color: "#FF1D52",
              },
              "&.MuiFormLabel-root-MuiInputLabel-root.Mui-focused ": {
                color: "#FF1D52",
              },
            }}
            inputProps={{
              style: {
                color: "#FF1D52",
                borderColor: "#FF1D52",
              },
            }}
            IconComponent={() => (
              <AddIcon
                style={{
                  color: "#FF1D52",
                  width: "20px",
                  margin: "9px",
                }}
              />
            )}
          >
            {brandsSearch?.map((item) => (
              <MenuItem
                onClick={() => brandSearch(item.id)}
                value={document.body.dir === "rtl" ? item.name_ar : item.name}
              >
                {document.body.dir === "rtl" ? item.name_ar : item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
  const priceRef = useRef();
  const categoryRef = useRef();

  // const productsPerRow =
  //   filteredProducts.length < 20 ? filteredProducts.length : 20;
  // const [nextProducts, setNextProducts] = useState(productsPerRow);
  const handleMoreProducts = useCallback(() => {
    if (
      // if the scroll is nearly at the bottom of the page (within 100px) then load more products
      window.innerHeight + document.documentElement.scrollTop >
      document.documentElement.offsetHeight - 10
    ) {
      moreProducts();
    }
  }, [moreProducts]);

  console.log(
    "products",
    products,
    "totalProducts",
    totalFilteredProducts,
    "productsArray",
    productsArray
  );
  console.log(
    "subCategory",
    subCategoriesSearch?.find((x) => x.id === Number(subCategory))
  );
  console.log(showMoreEnable);
  useEffect(() => {
    products.length < totalNumber &&
    totalFilteredProducts.length > 0 &&
    totalNumber !== totalFilteredProducts.length
      ? window.addEventListener("scroll", handleMoreProducts)
      : window.removeEventListener("scroll", handleMoreProducts);
    return () => {
      window.removeEventListener("scroll", handleMoreProducts);
    };
  }, [products, totalNumber, totalFilteredProducts, handleMoreProducts]);

  const scrollElement = useRef(null);
  const scrollToLeft = () => {
    scrollElement.current.scrollLeft -= 200;
  };
  const scrollToRight = () => {
    scrollElement.current.scrollLeft += 200;
  };

  console.log(subCategory, sub_array[0]);
  if (products.length === 0 && totalFilteredProducts.length === 0) {
    return (
      <>
        <div className={styles.products_list}>
          <div className={styles.products_list__header__filtersMain}>
            {categoryId?.length > 0 && (
              <div className={styles.products_list__header__filterBoxes}>
                {sub_array[0]?.map((item) => (
                  <div
                    key={item.id}
                    className={styles.products_list__header__filterBox}
                    onClick={() => subCategorySearch(item.id)}
                    style={
                      //if subcategory id is equal to the id of the subcategory that is clicked then change the style
                      Number(subCategory) === Number(item.id)
                        ? {
                            backgroundColor: "#ff1d5214",
                            color: "#ff1d52",
                            borderRadius: "20px",
                            justifyContent: "center",
                            padding: "10px",
                          }
                        : null
                    }
                  >
                    {document.body.dir === "rtl" ? item?.name_ar : item?.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className={styles.products_list__content__item__card}>
          <div
            className={styles.products_list__content__item__card__noProducts}
          >
            <img src={NoProducts} alt="no products" width={150} height={150} />
            <div>{t("No products found")}</div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {/* <Navbarr /> */}
      <main className={styles.products_list}>
        <div className={styles.products_list__header}>
          <div className={styles.products_list__header__filtersMain}>
            {/* <div className={styles.products_list__header__filterNumberProd}>
              {t("Filter Products")} .{totalNumber > 0 ? totalNumber : 0}
            </div> */}

            {categoryId?.length > 0 && (
              <div className={styles.products_list__header__filterBoxes}>
                {sub_array[0]?.map((item) => (
                  <div
                    key={item.id}
                    className={styles.products_list__header__filterBox}
                    onClick={() => subCategorySearch(item.id)}
                    style={
                      //if subcategory id is equal to the id of the subcategory that is clicked then change the style
                      Number(subCategory) === Number(item.id)
                        ? {
                            backgroundColor: "#ff1d5214",
                            color: "#ff1d52",
                            borderRadius: "20px",
                            justifyContent: "center",
                            padding: "10px",
                          }
                        : null
                    }
                  >
                    {document.body.dir === "rtl" ? item?.name_ar : item?.name}
                  </div>
                ))}
              </div>
            )}
            {searchInputURL !== "" && "undefined" !== typeof searchInputURL ? (
              <div className={styles.Products_list__header__desc}>
                {t("search results for")}{" "}
                <span>{searchInputURL ? search : null}</span>
              </div>
            ) : null}
            <div className={styles.sortProducts}>
              <FormControl
                sx={{
                  m: 1,
                  // minWidth: 120,
                  borderColor: "#707070",
                  borderRadius: "20px",
                  width: "fit-content",
                }}
                size="medium"
                variant="filled"
                color={"primary"}
              >
                <InputLabel
                  id="demo-simple-select-standard-label"
                  sx={
                    document.body.dir === "rtl"
                      ? {
                          "&.MuiFormLabel-root": {
                            left: "30%",
                            textAlign: "start",
                            fontFamily: "unset",
                          },
                          "&.Mui-focused": {
                            color: "#707070",
                          },
                          // on hover on the field remove the bottom border
                          "&:hover": {
                            borderBottom: "none",
                          },
                        }
                      : {
                          "&.MuiFormLabel-root": {
                            left: "unset",
                            textAlign: "start",
                            fontFamily: "unset",
                          },
                          "&.Mui-focused": {
                            color: "#707070",
                          },
                          // on hover on the field remove the bottom border
                          "&:hover": {
                            borderBottom: "none",
                          },
                          "&::after:hover": {
                            borderBottom: "none",
                          },
                        }
                  }
                >
                  {t("Sort")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-select-small"
                  value={filterSorting}
                  onChange={handleChangeFilterSorting}
                  defaultValue={0}
                  sx={{
                    "::before": {
                      borderBottom: "none",
                    },
                    "::before:hover": {
                      borderBottom: "none",
                    },
                    "::after": {
                      borderBottom: "none",
                    },
                  }}
                  style={{
                    borderRadius: "20px",
                    borderColor: "#707070",
                    color: "#707070",
                    width: "fit-content",
                    borderBottom: "none",
                    fontFamily: "unset",

                  }}
                  IconComponent={() => (
                    <ImportExportIcon
                      style={{
                        color: "#707070",
                        width: "20px",
                        margin: "5px",
                      }}
                    />
                  )}
                >
                  <MenuItem disabled selected></MenuItem>
                  <MenuItem
                    onClick={lowToHigh}
                    value={"low to high"}
                    style={{ fontFamily: "unset" }}
                    sx={{
                      "&.Mui-selected": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                      "&.Mui-selected:hover": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                    }}
                  >
                    {t("Price: Low to high")}
                  </MenuItem>
                  <MenuItem
                    onClick={highToLow}
                    value={"high to low"}
                    style={{ fontFamily: "unset" }}
                    sx={{
                      "&.Mui-selected": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                      "&.Mui-selected:hover": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                    }}
                  >
                    {t("Price: High to low")}
                  </MenuItem>
                  <MenuItem
                    onClick={AtoZ}
                    value={"A to Z"}
                    style={{ fontFamily: "unset" }}
                    sx={{
                      "&.Mui-selected": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                      "&.Mui-selected:hover": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                    }}
                  >
                    {t("Title: A to Z")}
                  </MenuItem>
                  <MenuItem
                    onClick={ZtoA}
                    value={"Z to A"}
                    style={{ fontFamily: "unset" }}
                    sx={{
                      "&.Mui-selected": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                      "&.Mui-selected:hover": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                    }}
                  >
                    {t("Title: Z to A")}
                  </MenuItem>
                  <MenuItem
                    onClick={mostPopular}
                    value={"Most Popular"}
                    style={{ fontFamily: "unset" }}
                    sx={{
                      "&.Mui-selected": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                      "&.Mui-selected:hover": {
                        // backgroundColor: "#ff1d5226",
                        color: "#425466",
                      },
                    }}
                  >
                    {t("Most Popular")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className={styles.skeleton_products}>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
              <Skeleton
                key={i}
                variant="rectangular"
                width={window.innerWidth > 600 ? 300 : 200}
                height={window.innerWidth > 600 ? 420 : 280}
                sx={{ borderRadius: "20px", marginBottom: "20px" }}
              />
            ))}
          </div>
        ) : products?.length > 0 ? (
          <div className={styles.products_list__content}>
            <div className={styles.products_list__content__item}>
              {showMoreEnable
                ? totalFilteredProducts.map((product) => (
                    <div className={styles.products_list__content__item__card}>
                      <ProductCard key={product.id} product={product} />
                    </div>
                  ))
                : products.map((product) => (
                    <div className={styles.products_list__content__item__card}>
                      <ProductCard
                        key={product.id}
                        product={product}
                        loading={isLoading}
                      />
                    </div>
                  ))}
            </div>
            {/* <div
              className={styles.products_list__content__item__productsNumber}
            >
              {showMoreEnable ? totalFilteredProducts.length : products.length}{" "}
              {t("out of")} {totalNumber} {t("products")}
            </div> */}
            {/* {products.length < totalNumber &&
            totalFilteredProducts.length > 0 &&
            totalNumber !== totalFilteredProducts.length ? (
              <Button
                className={
                  styles.products_list__content__item__card_loadMoreBtn
                }
                onClick={() => moreProducts()}
              >
                {t("Load more products")}
              </Button>
            ) : products.length < pageSize ? null : null} */}
          </div>
        ) : (
          <div className={styles.products_list__content__item}>
            {totalFilteredProducts.map((product) => (
              <div className={styles.products_list__content__item__card}>
                <ProductCard key={product.id} product={product} />
              </div>
            ))}
          </div>
        )}
      </main>
      {/* <Footer /> */}
    </>
  );
};
export default ProductsList;
