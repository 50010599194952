import * as Yup from "yup";

export const userValidationSignUpSchema = Yup.object().shape({
  //just accept 3 characters on min and doesn't accept symbols except -
  name: Yup.string()
    .min(3)
    .max(20)
    .matches(
      /^[a-zA-Z]+$/,
      document.body.dir === "rtl"
        ? "يجب أن يكون الاسم حروفًا فقط"
        : "Name must be letters only"
    )
    .required(document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is requirhhhhed"),
  last_name: Yup.string()
    .min(3)
    .max(20)
    .matches(
      /^[a-zA-Z]+$/,
      document.body.dir === "rtl"
        ? "يجب أن يكون الاسم حروفًا فقط"
        : "Name must be letters only"
    )
    .required(document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is required"),
  email: Yup.string()
    .required(
      document.body.dir === "rtl"
        ? "البريد الالكتروني مطلوب"
        : "Email is required"
    )
    //matches regex for email
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      document.body.dir === "rtl"
        ? "يجب أن يكون البريد الإلكتروني صالحًا"
        : "Must be a valid email"
    )
    .email(
      document.body.dir === "rtl"
        ? "يجب أن يكون البريد الإلكتروني صالحًا"
        : "Must be a valid email"
    ),
  password: Yup.string()
    .required(
      document.body.dir === "rtl"
        ? "كلمة المرور مطلوبة"
        : "Password is required"
    )
    .min(
      6,
      document.body.dir === "rtl"
        ? "يجب أن تكون كلمة المرور 6 أحرف على الأقل"
        : "Password must be at least 6 characters long"
    )
    .max(
      50,
      document.body.dir === "rtl"
        ? "يجب أن تكون كلمة المرور 50 حرفًا كحد أقصى"
        : "Password must be at most 50 characters long"
    ),
  confirm_password: Yup.string()
    .required(
      document.body.dir === "rtl"
        ? "تأكيد كلمة المرور مطلوب"
        : "Confirm password is required"
    )
    .oneOf(
      [Yup.ref("password"), null],
      document.body.dir === "rtl"
        ? "كلمة المرور غير متطابقة"
        : "Passwords do not match"
    ),
  phone: Yup.string()
    .typeError(
      document.body.dir === "rtl"
        ? "يجب أن يكون الهاتف رقمًا"
        : "Phone must be a number"
    )
    //matches regex for phone 10 digits or 11 digits
    .matches(/^\d+$/, {
      message:
        document.body.dir === "rtl"
          ? "يجب أن يكون الهاتف رقمًا"
          : "Phone must be a number",
    })
    .min(
      10,
      document.body.dir === "rtl"
        ? "يجب أن يكون الهاتف 10 أرقام على الأقل"
        : "Phone must be at least 10 numbers long"
    )
    .max(
      11,
      document.body.dir === "rtl"
        ? "يجب أن يكون الهاتف 11 رقمًا كحد أقصى"
        : "Phone must be at most 11 numbers long"
    )
    .required(
      document.body.dir === "rtl" ? "الهاتف مطلوب" : "Phone is required"
    ),
});
