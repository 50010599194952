import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../auth/authSlice";
import { clearMessage } from "../auth/message";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import styles from "./../styles/SignInSignUp.module.css";
import { userValidationSignInSchema } from "../Validations/UserValidationSignIn";

const Signin = () => {
  const userRef = useRef();
  const emailRef = useRef();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [messages, setMessages] = useState("");

  const [open, setOpen] = useState(true);

  const [user, setUser] = useState("");
  const [token, setToken] = useState("");
  const [emailState, setEmailState] = useState("");
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const togglePassWordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // const [password, setPassword] = useState("");

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const { localization } = useSelector((state) => state.init);

  const config = JSON?.parse(localStorage.getItem("config"));

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const formikSignin = useFormik({
    initialValues: {
      email: "",
      password: "",
      phone: "",
    },
    onSubmit: (values) => {
      const { email, phone, password } = values;
      setLoading(true);

      dispatch(login({ email, phone, password }))
        .unwrap()
        .then(() => {
          console.log("logged in");
          setLoading(false);
          console.log(message);

          // empty the form
        })
        .catch(() => {
          setLoading(false);
          console.log("error");
          console.log(message);
          formikSignin.resetForm();
        });
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      // if config is true then phone is required and must be a number and must be 11 digits long
      // else email is required and must be a valid email
      [config?.enablePhoneLogin ? "phone" : "email"]: config?.enablePhoneLogin
        ? yup
            .string()
            .typeError(
              document.body.dir === "rtl"
                ? "يجب أن يكون الهاتف رقمًا"
                : "Phone must be a number"
            )
            //matches regex for phone 10 digits or 11 digits
            .matches(/^\d+$/, {
              message:
                document.body.dir === "rtl"
                  ? "يجب أن يكون الهاتف رقمًا"
                  : "Phone must be a number",
            })
            // the number must be 10 or 11 digits long without hyphens
            .required(
              document.body.dir === "rtl"
                ? "يجب إدخال رقم الهاتف"
                : "Phone is required"
            )
            .min(
              10,
              document.body.dir === "rtl"
                ? "يجب أن يتكون الهاتف من 10 رقمًا"
                : "Phone must be 10 digits long"
            )
            .max(
              11,
              document.body.dir === "rtl"
                ? "يجب أن يتكون الهاتف من 11 رقمًا"
                : "Phone must be 11 digits long"
            )
            .matches(/^[0-9]{10,11}$/, {
              message:
                document.body.dir === "rtl"
                  ? "يجب أن يكون الهاتف رقمًا"
                  : "Phone must be a number",
            })
            .required(
              document.body.dir === "rtl" ? "الهاتف مطلوب" : "Phone is required"
            )
        : yup
            .string()
            .required(
              document.body.dir === "rtl"
                ? "البريد الالكتروني مطلوب"
                : "Email is required"
            )
            //matches regex for email
            .matches(
              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              document.body.dir === "rtl"
                ? "يجب أن يكون البريد الإلكتروني صالحًا"
                : "Must be a valid email"
            )
            .email(
              document.body.dir === "rtl"
                ? "يجب أن يكون البريد الإلكتروني صالحًا"
                : "Must be a valid email"
            ),
      password: yup
        .string()
        .required(
          document.body.dir === "rtl"
            ? "كلمة المرور مطلوبة"
            : "Password is required"
        )
        .min(
          8,
          document.body.dir === "rtl"
            ? "يجب أن تكون كلمة المرور 8 أحرف على الأقل"
            : "Password must be at least 8 characters long"
        ),
    }),
  });
  const { email, phone, password } = formikSignin.values;

  const addHyphen = (e) => {
    let phone = e.target.value;
    if (phone.length === 3) {
      phone = phone + "-";
      e.target.value = phone;
    }
    if (phone.length === 7) {
      phone = phone + "-";
      e.target.value = phone;
    }
  };

  return (
    <>
      <form onSubmit={formikSignin.handleSubmit} className={styles.SignInForm}>
        <div className={styles.SignForm_input}>
          <div
            className={styles.SignForm_label}
            htmlFor={config?.enablePhoneLogin ? "phone" : "email"}
          >
            {config?.enablePhoneLogin ? t("Phone") : t("Email")}
          </div>
          <input
            ref={emailRef}
            name={config?.enablePhoneLogin ? "phone" : "email"}
            placeholder={config?.enablePhoneLogin ? "0123456789" : "username@gmail.com"}
            inputMode={config?.enablePhoneLogin ? "number" : "email"}
            id={config?.enablePhoneLogin ? "phone" : "email"}
            // onKeyUp={config?.enablePhoneLogin ? addHyphen : null}
            type={config?.enablePhoneLogin ? "text" : "email"}
            maxlength={
              config?.enablePhoneLogin ? localization?.phone_length : 50
            }
            onChange={formikSignin.handleChange}
            onBlur={formikSignin.handleBlur}
            value={formikSignin.values.phone}
            key={config?.enablePhoneLogin ? "phone" : "email"}
          />
          {config?.enablePhoneLogin
            ? formikSignin.touched.phone &&
              formikSignin.errors.phone && (
                <div className={styles.error} style={{ color: "red" }}>
                  {formikSignin.errors.phone}
                </div>
              )
            : formikSignin.touched.email &&
              formikSignin.errors.email && (
                <div className={styles.error} style={{ color: "red" }}>
                  {formikSignin.errors.email}
                </div>
              )}
        </div>
        <div className={styles.SignForm_input}>
          <div className={styles.SignForm_label} htmlFor="password">
            {t("Password")}
          </div>
          <div style={{ display: "flex", position: "relative" }}>
            <input
              placeholder="********"
              type={showPassword ? "text" : "password"}
              id="password"
              onChange={formikSignin.handleChange}
              onBlur={formikSignin.handleBlur}
              value={password}
            />
            {showPassword ? (
              <VisibilityIcon
                onClick={togglePassWordVisibility}
                className={styles.SignForm_password_icon}
                color="disabled"
                style={
                  document.body.dir === "rtl" ? { left: "3%" } : { right: "3%" }
                }
              />
            ) : (
              <VisibilityOffIcon
                onClick={togglePassWordVisibility}
                className={styles.SignForm_password_icon}
                color="disabled"
                style={
                  document.body.dir === "rtl" ? { left: "3%" } : { right: "3%" }
                }
              />
            )}
          </div>
          {formikSignin.touched.password && formikSignin.errors.password && (
            <div className={styles.error} style={{ color: "red" }}>
              {formikSignin.errors.password}
            </div>
          )}
          <div style={{ textAlign: "end", marginTop: "1rem" }}>
            <Link
              to="/customer/account/forgotpassword"
              className={styles.SignInForm_forgotPassword}
            >
              {t("Forgot password?")}
            </Link>
          </div>
        </div>
        <div className={styles.SignForm_button}>
          <button
            type="submit"
            disabled={loading}
            style={{ padding: "2vh 10vw" }}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              <span>{t("Sign In")}</span>
            )}
          </button>
        </div>
        <div className={styles.SignInForm_signUp}>
          <p>
            {t("Don't have an account?")}
            <a href="/customer/account/signup">{t("Sign Up")}</a>
          </p>
        </div>
      </form>
      {message ? (
        message !== "Success" ? (
          <Box sx={{ width: "100%" }} className={styles.errorMsg}>
            <Collapse in={open}>
              <Alert severity="error" sx={{ mb: 2 }}>
                {message}
              </Alert>
            </Collapse>
          </Box>
        ) : (
          isLoggedIn && navigate("/", { replace: true })
        )
      ) : null}
    </>
  );
};
export default Signin;
