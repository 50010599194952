import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { TimelineBar } from "../../components";
import { useTranslation } from "react-i18next";
import styles from "./../../styles/Checkout.module.css";

import userPrefService from "../../services/user-preferences";
import { useSelector } from "react-redux";

const CheckoutEditAddress = () => {
  const config = JSON?.parse(localStorage.getItem("config"));
  useDocumentTitle(
    document.body.dir === "rtl"
      ? `تعديل العنوان | ${config?.APP_NAME_AR}`
      : `Edit Checkout Address | ${config?.APP_NAME}`
  );
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(id + " ");

  const [cities, setCities] = useState([]);
  // const [ addressEdit , setAddressEdit] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(true);
  // const addressesFromInit = JSON.parse(localStorage.getItem("init")).data.profile.addresses;
  const { addresses, profile } = useSelector((state) => state.init);
  //   const addressesData = addresses.filter((address) => address.id === id);

  //map over addresses and git the address that has the same id as the id in the url
  const addressesData = addresses.filter(
    (address) => address.id === Number(id)
  )[0];
  console.log(addressesData);
  //get the output of the filter into an object

  // console.log(addressesFromInit);
  const [chosenArea, setChosenArea] = useState([]);

  useEffect(() => {
    userPrefService
      .cities()
      .then((res) => {
        console.log(res);
        setCities(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(governates);
  // console.log(cities);

  const formik = useFormik({
    initialValues: {
      name: addressesData ? addressesData?.name : "",
      phone: addressesData ? addressesData?.phone : "",
      governate: addressesData?.city?.name ? addressesData?.city?.name : "",
      area: addressesData?.area?.name ? addressesData?.area?.name : "",
      street: addressesData?.street ? addressesData?.street : "",
      building: addressesData?.building ? addressesData?.building : "",
      floor: addressesData?.floor ? addressesData?.floor : "",
      apartment: addressesData?.apartment ? addressesData?.apartment : "",
      landmark: addressesData?.landmark ? addressesData?.landmark : "",
      address: addressesData?.address ? addressesData?.address : "",
      city_id: addressesData?.city_id ? addressesData?.city_id : "",
      area_id: addressesData?.area_id ? addressesData?.area_id : "",
      primary: addressesData?.primary ? true : false,
    },

    onSubmit: () => {
      const {
        name,
        phone,
        governate,
        area,
        street,
        building,
        floor,
        apartment,
        landmark,
        address,
        city_id,
        area_id,
        primary,
      } = formik.values;

      setIsLoading(true);
      userPrefService
        .editAddress(id, {
          name: profile?.is_guest ? name : profile?.name,
          phone: phone,
          governate: governate,
          area: area,
          street: street,
          building: building,
          floor: floor,
          apartment: apartment,
          landmark: landmark,
          address: address,
          city_id: city_id,
          area_id: area_id,
          primary: primary,
        })
        .then((res) => {
          setIsLoading(false);
          console.log(res);
          setMessage(res.message);
          navigate(-1);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(
          /^[a-zA-Z ]*$/,
          document.body.dir === "rtl" ? "الاسم غير صحيح" : "Name is not valid"
        )
        .required(
          document.body.dir === "rtl" ? "الاسم مطلوب" : "Name is required"
        ),
      phone: Yup.string()
        .typeError(
          document.body.dir === "rtl"
            ? "رقم الهاتف غير صحيح"
            : "Phone number is not valid"
        )
        //matches regex for phone 10 digits or 11 digits
        .matches(/^\d+$/, {
          message:
            document.body.dir === "rtl"
              ? "رقم الهاتف غير صحيح"
              : "Phone number is not valid",
          excludeEmptyString: true,
        })
        .min(
          10,
          document.body.dir === "rtl"
            ? "رقم الهاتف 10 على الأقل"
            : "Phone number is 10 at least"
        )
        .max(
          11,
          document.body.dir === "rtl"
            ? "رقم الهاتف 11 على الأكثر"
            : "Phone number is 11 at most"
        )
        .required(
          document.body.dir === "rtl"
            ? "رقم الهاتف مطلوب"
            : "Phone number is required"
        ),
      address: Yup.string().required(
        document.body.dir === "rtl" ? "العنوان مطلوب" : "Address is required"
      ),
      // validate select fields
      governate: Yup.string().required(
        document.body.dir === "rtl"
          ? "المحافظة مطلوبة"
          : "Governorate is required"
      ),
      apartment: Yup.string().required(
        document.body.dir === "rtl" ? "الشقة مطلوبة" : "Apartment is required"
      ),
    }),
  });
  // generate array contains numbers from 1 to 100

  // const Field = ({ label, type, placeholder, htmlFor }) => (
  //   <div className={styles.addNew_input}>
  //     <div className={styles.addNew_label} htmlFor={htmlFor}>
  //       {label}
  //     </div>

  //     <input
  //       placeholder={placeholder}
  //       type={type}
  //       id={type}
  //       className={` ${
  //         formik.touched.htmlFor && formik.errors.htmlFor ? styles.error : ""
  //       }`}
  //     />
  //   </div>
  // );
  console.log(formik.values);

  //when user select a city, the governates will be updated
  const handleCityChange = (e) => {
    //if there is inital value, then set it to the formik
    if (formik.values.governate?.length > 0) {
      formik.setFieldValue("governate", formik.values.governate);
    }
    const selectedCity = cities.find((city) => city.name === e.target.value);
    setChosenArea(selectedCity?.areas);
    const selectedGovernate = cities.find(
      (governate) => governate.name === e.target.value
    );
    formik.setFieldValue("city_id", selectedGovernate.id);
    formik.handleChange(e);
    console.log(chosenArea);
  };
  const handleAreaChange = (e) => {
    const selectedArea = chosenArea.find(
      (area) => area.name === e.target.value
    );
    formik.setFieldValue("area_id", selectedArea?.id);
    formik.handleChange(e);
  };

  useEffect(() => {
    if (addressesData) {
      formik.setFieldValue("name", addressesData?.customer_first_name);
      formik.setFieldValue("phone", addressesData?.phone);
      formik.setFieldValue("governate", addressesData?.city?.name);
      formik.setFieldValue("area", addressesData?.area?.name);
      formik.setFieldValue("street", addressesData?.street);
      formik.setFieldValue("building", addressesData?.building);
      formik.setFieldValue("floor", addressesData?.floor);
      formik.setFieldValue("apartment", addressesData?.apartment);
      formik.setFieldValue("landmark", addressesData?.landmark);
      formik.setFieldValue("address", addressesData?.address);
      formik.setFieldValue("city_id", addressesData?.city_id);
      formik.setFieldValue("area_id", addressesData?.area_id);
      formik.setFieldValue("primary", addressesData?.primary);
    }
  }, [addressesData]);

  return (
    <>
      <div className={styles.account_main}>
        <div className={styles.delivery_header}>
          <TimelineBar />
        </div>
        <form onSubmit={formik.handleSubmit} className={styles.account_body}>
          <div className={styles.account_body}>
            {/* <div className={styles.account_body_title}>
              <h1>Add New Address</h1>
            </div> */}

            <div className={styles.addNew_fields__content}>
              {profile?.is_guest && (
                <>
                  <div className={styles.addNew_input}>
                    <div className={styles.addNew_label} htmlFor="name">
                      {t("Full Name*")}
                    </div>

                    <input
                      placeholder={t("Full Name*")}
                      type="text"
                      pattern="[a-zA-Z ]{2,30}"
                      id="name"
                      className={styles.addNew_label}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className={styles.error}>{formik.errors.name}</div>
                    )}
                  </div>

                  <div className={styles.addNew_input}>
                    <div className={styles.addNew_label} htmlFor="phoneNumber">
                      {t("Phone Number*")}
                    </div>

                    <input
                      placeholder={t("Phone Number*")}
                      type="phone"
                      id="phone"
                      //accept only numbers
                      pattern="[0-9]*"
                      className={styles.addNew_label}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone}
                    />
                    {formik.touched.phone && formik.errors.phone && (
                      <div className={styles.error}>{formik.errors.phone}</div>
                    )}
                  </div>
                </>
              )}

              <div className={styles.addNew_fields__content_multipleFields}>
                <div className={styles.addNew_input}>
                  <div className={styles.addNew_label} htmlFor="governates">
                    {t("City*")}
                  </div>

                  <select
                    name="governate"
                    id="governate"
                    onChange={handleCityChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.governate}
                  >
                    <option value="" disabled selected>
                      {t("Select City")}
                    </option>
                    {cities.map((item) => (
                      <option value={item.name} id={item.id}>
                        {document.body.dir === "rtl" ? item.name_ar : item.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.governate && formik.errors.governate && (
                    <div className={styles.error}>
                      {formik.errors.governate}
                    </div>
                  )}
                </div>
                <div className={styles.addNew_input}>
                  <div className={styles.addNew_label} htmlFor="areas">
                    {t("Area")}
                  </div>

                  <select
                    name="area"
                    id="area"
                    onChange={handleAreaChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.area}
                  >
                    <option value="" disabled selected>
                      {t("Select Area")}
                    </option>
                    {cities
                      ?.find((city) => city.name === formik.values.governate)
                      ?.areas?.map((city) => (
                        <option value={city.name}>
                          {document.body.dir === "rtl"
                            ? city.name_ar
                            : city.name}
                        </option>
                      ))}
                  </select>
                  {formik.touched.area && formik.errors.area && (
                    <div className={styles.error}>{formik.errors.area}</div>
                  )}
                </div>

                <div
                  className={
                    styles.addNew_fields__content_multipleFields_select
                  }
                ></div>
              </div>

              <div className={styles.addNew_input}>
                <div className={styles.addNew_label} htmlFor="street">
                  {t("Street Name")}
                </div>

                <input
                  placeholder={t("Street Name")}
                  type="text"
                  id="street"
                  className={styles.addNew_label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.street}
                />
                {formik.touched.street && formik.errors.street && (
                  <div className={styles.error}>{formik.errors.street}</div>
                )}
              </div>
              <div className={styles.addNew_fields__content_multipleFields}>
                <div className={styles.addNew_input}>
                  <div className={styles.addNew_label} htmlFor="building">
                    {t("Building Number")}
                  </div>

                  <input
                    placeholder={t("Building Number")}
                    type="text"
                    id="building"
                    className={styles.addNew_label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.building}
                  />
                  {formik.touched.building && formik.errors.building && (
                    <div className={styles.error}>{formik.errors.building}</div>
                  )}
                </div>

                <div className={styles.addNew_input}>
                  <div className={styles.addNew_label} htmlFor="apartment">
                    {t("Apartment*")}
                  </div>

                  <input
                    placeholder={t("Apartment*")}
                    type="text"
                    id="apartment"
                    className={styles.addNew_label}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.apartment}
                  />
                  {formik.touched.apartment && formik.errors.apartment && (
                    <div className={styles.error}>
                      {formik.errors.apartment}
                    </div>
                  )}
                </div>
              </div>

              <div className={styles.addNew_input}>
                <div className={styles.addNew_label} htmlFor="landmark">
                  {t("Name on the doorbell")}
                </div>

                <input
                  placeholder={t("Name on the doorbell")}
                  type="text"
                  id="landmark"
                  className={styles.addNew_label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.landmark}
                />
                {formik.touched.landmark && formik.errors.landmark && (
                  <div className={styles.error}>{formik.errors.landmark}</div>
                )}
              </div>

              <div className={styles.addNew_input}>
                <div className={styles.addNew_label} htmlFor="address">
                  {t("Postal Code*")}
                </div>

                <input
                  placeholder={t("Postal Code*")}
                  type="text"
                  id="address"
                  className={styles.addNew_label}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className={styles.error}>{formik.errors.address}</div>
                )}
              </div>

              <div className={styles.Payment_box__content__checkbox}>
                <input
                  type="checkbox"
                  //onClick change primary to true
                  id="primary"
                  value={formik.values.primary}
                  checked={formik.values.primary}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <span>{t("Set as default Address")}</span>
              </div>
            </div>
            <button type="submit" className={styles.addNewCard}>
              {isLoading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                <div className={styles.addNewCard_Btn__Text}>
                  {t("Save Changes")}
                </div>
              )}
            </button>
            {message ? (
              message.includes("Success") ? (
                <Box sx={{ width: "50%", marginTop: "1rem" }}>
                  <Collapse in={open}>
                    <Alert
                      severity={
                        message.includes("Success") ? "success" : "error"
                      }
                      sx={{ mb: 2 }}
                    >
                      {message}
                    </Alert>
                  </Collapse>
                </Box>
              ) : null
            ) : null}
          </div>
        </form>{" "}
      </div>
    </>
  );
};

export default CheckoutEditAddress;
