import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import { cartItemsFetching, clearCart } from "../../store/cart/cartSlice";
import { TimelineBar } from "../../components";
import mastercard from "../../assets/masterCard.svg";
import visa from "../../assets/visa.svg";

import useDocumentTitle from "../../hooks/useDocumentTitle";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import styles from "../../styles/Checkout.module.css";

import userPrefService from "../../services/user-preferences";
import { useSelector, useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";
import { auto } from "@popperjs/core";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import { initialize } from "../../services/initialize";
import { toast, ToastContainer } from "react-toastify";

const CheckoutPayment = () => {
  const placeholderImg =
    "https://nasijagaltangsel.websites.co.in/dummytemplate/img/product-placeholder.png";

  useDocumentTitle("Checkout Payment");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.init);
  console.log(user);
  const { isLoading, cartItems } = useSelector((state) => state.cartProducts);
  const dispatch = useDispatch();

  const [cardType, setCardType] = useState("");
  const [loading, setLoading] = useState(true);
  const [loadingPromo, setLoadingPromo] = useState(false);
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState("");

  const [cartProducts, setCartProducts] = useState([]);
  const [deliveryFees, setDeliveryFees] = useState([]);
  const [promo, setPromo] = useState("");
  const [promoError, setPromoError] = useState("");
  const [deliveryError, setDeliveryError] = useState("");
  const [orderPlacedError, setOrderPlacedError] = useState("");
  const [orderPlacedRes, setOrderPlacedRes] = useState([]);
  const [verificationCode, setVerificationCode] = useState("");
  const [message, setMessage] = useState("");
  const [showPlan, setShowPlan] = useState(false);
  const [planId, setPlanId] = useState("");
  const [activePayment, setActivePayment] = useState(false);
  const [promoResponse, setPromoResponse] = useState([]);
  const [promoCode, setPromoCode] = useState(true);
  const [orderError, setOrderError] = useState(false);

  const deliveryAdd = localStorage.getItem("deliveryAddress");

  console.log(deliveryAdd);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleClosePayment = () => {
    setShowPayment(false);
  };
  const handleShowPayment = () => setShowPayment(true);

  useEffect(() => {
    cartItems.length === 0 &&
      navigate("/", { replace: true, state: { from: "payment" } });
  }, [cartItems, navigate]);

  ///payment methods plans dropdown
  const [plan, setPlan] = useState("");

  const handleChange = (event) => {
    setPlan(event.target.value);
  };
  //////////////////////////////////////////
  useEffect(() => {
    userPrefService.getCart().then((res) => {
      setLoading(false);
      dispatch(cartItemsFetching(res));
      dispatch(initialize());
      setCartProducts(res);
    });
    //  else {
    //   setCartProducts(JSON.parse(localStorage.getItem("cart")));
    // }
  }, [dispatch]);

  useEffect(() => {
    userPrefService
      .getDeliveryFees(
        cartItems.grand_total,
        JSON.parse(deliveryAdd)?.id,
        profile?.is_guest ? true : false
      )
      .then((res) => {
        setLoading(false);
        setDeliveryFees(res);
        console.log(res);
        setDeliveryError(res.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [cartItems.grand_total, deliveryAdd, profile?.is_guest]);

  const addPromoAPI = () => {
    userPrefService
      .promoCode(JSON.parse(deliveryAdd).id, cartItems.grand_total, promo)
      .then((res) => {
        if (res.code !== 200 || res.code !== 201) {
          setPromoCode(false);
        }

        setLoadingPromo(false);
        setPromoResponse(res);
        setPromoCode(true);
        console.log(res);
        setPromoError(res.message);
      });
  };

  const onSubmitPromo = async () => {
    setLoadingPromo(true);
    addPromoAPI();
    console.log(promoResponse);
  };

  const onChangePromo = (e) => {
    setPromo(e.target.value);
    localStorage.setItem("promo", e.target.value);
  };

  const TotalFees = ({ label, value }) => {
    return (
      <div className={styles.totalFees}>
        <div className={styles.totalFees__title}>
          <div>{label}</div>
        </div>
        <div className={styles.totalFees__price}>
          <div>{value}</div>
        </div>
      </div>
    );
  };

  const handleCheck = (payment_type) => {
    setCardType(payment_type);
    setActivePayment(true);
    localStorage.setItem("payment_type", JSON.stringify(payment_type));
    console.log("cardType", payment_type);
  };

  const placeOrder = () => {
    setLoadingOrder(true);
    userPrefService
      .placeOrder(
        false,
        JSON.parse(deliveryAdd).id,
        1,
        "",
        JSON.parse(localStorage.getItem("payment_type")),
        profile?.phone,
        promoCode ? promo : "",
        verificationCode,
        planId
      )
      .then((res) => {
        setLoadingOrder(false);
        if (res.code === 400 || res.code === 423) {
          setOrderError(true);
          setOrderPlacedError(res.message);
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          console.log(res.message);
        } else if (res.code === 409 || res.code === 422) {
          handleShow();
        } else if (res.code === 202) {
          setPaymentUrl(res.data.pay_url);
          handleShowPayment();
        } else if (res.code === 203) {
          // redirect to payment website
          window.location.href = res.data.pay_url;
        } else if (res.code === 200 || res.code === 201) {
          localStorage.setItem("order_placed", JSON.stringify(res));
          navigate("/checkout/order-placed", {
            replace: true,
            state: { from: "payment" },
          });
        }
      });
  };

  //on click on radio button show the payment plan for that payment method uniquly
  const handleShowPlan = (payment_type) => {
    setShowPlan(true);
    setCardType(payment_type);
    console.log("cardType", payment_type);
  };

  // on click show modal with iframe
  const placeOrderAfterVerify = () => {
    setLoadingVerify(true);
    userPrefService
      .placeOrder(
        false,
        JSON.parse(deliveryAdd).id,
        1,
        "",
        JSON.parse(localStorage.getItem("payment_type")),
        profile?.phone,
        promoCode ? promo : "",
        verificationCode
      )
      .then((res) => {
        setLoadingVerify(false);
        setMessage(res.message);
        if (
          res.code === 409 ||
          res.code === 423 ||
          res.code === 422 ||
          res.code === 400
        ) {
          setOrderPlacedError(res.message);
          toast.error(res.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else if (res.code === 200 || 201) {
          handleClose();
          window.location.href = "/checkout/order-placed";
          localStorage.setItem("order_placed", JSON.stringify(res));
          dispatch(clearCart());
          console.log(res);
          setOrderPlacedRes(res);
        }
      });
  };

  return (
    <>
      {/* <Navbarr /> */}
      <div
        style={{ backgroundColor: "rgb(248, 248, 246)", padding: "2vh 7vw" }}
        className={styles.checkout_all}
      >
        <div className={styles.all_container}>
          <div className={styles.checkout_container}>
            <div className={styles.payment_header}>
              <TimelineBar page={"payment"} />
            </div>

            <div className={styles.checkout}>
              <div className={styles.checkout__header}>
                <div className={styles.checkout__header__title}>
                  {t("Payment Method")}
                </div>
              </div>
              <div className={styles.checkout__body}>
                <div>
                  {loading
                    ? [1, 2, 3, 4, 5, 6].map((item) => (
                        <Skeleton
                          variant="rectangular"
                          key={item}
                          width={400}
                          height={100}
                          style={{ borderRadius: "20px", marginBottom: "20px" }}
                        />
                      ))
                    : deliveryFees?.data?.payment_methods?.map((payment) => (
                        <>
                          <div className={styles.cart_divider}></div>

                          <div
                            className={
                              styles.checkout__body__payment__method__cod
                            }
                          >
                            <Typography sx={{ color: "text.primary" }}>
                              <div style={{ display: "flex" }}>
                                <input
                                  type="radio"
                                  style={{
                                    marginInlineEnd: "1vw",
                                    cursor: "pointer",
                                  }}
                                  name="paymentMethod"
                                  value={payment.name}
                                  onChange={() => {
                                    handleCheck(payment.id);
                                    handleShowPlan(payment.id);
                                  }}
                                  id={payment.id}
                                  // onClick={() => {
                                  //   setShowPlan(true);
                                  // }}
                                />
                                <img
                                  width={50}
                                  height={auto}
                                  src={payment.icon}
                                  alt="payment_icon"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = placeholderImg;
                                  }}
                                />
                                <span
                                  style={
                                    document.body.dir === "rtl"
                                      ? { fontFamily: "Vazirmatn, sans-serif" }
                                      : {}
                                  }
                                >
                                  {document.body.dir === "rtl"
                                    ? payment.name_ar
                                    : payment.name}
                                </span>
                              </div>
                            </Typography>
                          </div>

                          {
                            //show plan for that payment method when radio button is clicked
                            showPlan && cardType === payment.id
                              ? payment.plans?.map((plan) => (
                                  //show dropdown menu if payment method has plans
                                  <FormControl
                                    variant="outlined"
                                    sx={{ width: "100%", marginTop: "1vh" }}
                                  >
                                    <InputLabel id="demo-simple-select-outlined-label">
                                      {t("Select Plan")}
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-outlined-label"
                                      id="demo-simple-select-outlined"
                                      value={plan.id}
                                      label="Select Plan"
                                      onChange={(e) => {
                                        setPlanId(e.target.value);
                                        localStorage.setItem(
                                          "plan_id",
                                          JSON.stringify(e.target.value)
                                        );
                                      }}
                                    >
                                      <MenuItem
                                        value={plan.id}
                                        style={
                                          document.body.dir === "rtl"
                                            ? {
                                                fontFamily:
                                                  "Vazirmatn, sans-serif",
                                              }
                                            : {}
                                        }
                                      >
                                        {document.body.dir === "rtl"
                                          ? plan.name_ar
                                          : plan.name_en}
                                      </MenuItem>
                                    </Select>
                                  </FormControl>
                                ))
                              : null
                          }
                        </>
                      ))}
                </div>
                <div className={styles.checkout_btns_group}>
                  <Link
                    to="/checkout/delivery"
                    className={styles.checkout_btns_group_btn_none}
                  >
                    &#60; {t("Back to shipping")}
                  </Link>
                  <button
                    className={styles.checkout_btns_group_btn}
                    onClick={
                      activePayment
                        ? () => {
                            placeOrder();
                          }
                        : null
                    }
                    disabled={activePayment ? false : true}
                    style={{
                      backgroundColor: activePayment ? "#FF1D52" : "#e0e0e0",
                      cursor: activePayment ? "pointer" : "not-allowed",
                    }}
                  >
                    {loadingOrder ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <span>{t("Confirm Order")}</span>
                    )}
                  </button>

                  <Modal
                    open={show}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        borderRadius: "20px",
                        p: 4,
                        width: "fit-content",
                        margin: "2rem auto",
                      }}
                    >
                      <h3>{t("Enter Your Verification Code")}</h3>
                      <div className={styles.NavLocationModal}>
                        <div className={styles.NavLocationModalDesc}>
                          {t(
                            "Please enter verifcation code sent to you by sms to"
                          )}{" "}
                          {profile?.phone}
                        </div>
                        <div className={styles.NavLocationModalDetails}>
                          <div className={styles.GovernLocation}>
                            <div className={styles.GovernLocationTitle}>
                              {t("Enter Code")}
                            </div>
                            <input
                              className={styles.locationInput}
                              type="text"
                              onChange={(e) => {
                                setVerificationCode(e.target.value);
                                localStorage.setItem(
                                  "verification_code",
                                  JSON.stringify(e.target.value)
                                );
                              }}
                            />
                            {orderError ? (
                              orderPlacedError ? (
                                <Box
                                  sx={{
                                    width: "100%",
                                    borderRadius: "20px",
                                    marginTop: "1rem",
                                  }}
                                >
                                  <Collapse in={open}>
                                    <Alert
                                      severity={
                                        message === "Success"
                                          ? "success"
                                          : "error"
                                      }
                                      sx={{ mb: 2 }}
                                    >
                                      {orderPlacedError}
                                    </Alert>
                                  </Collapse>
                                </Box>
                              ) : (
                                <Box
                                  sx={{
                                    width: "100%",
                                    borderRadius: "20px",
                                    marginTop: "1rem",
                                  }}
                                >
                                  <Collapse in={open}>
                                    <Alert
                                      severity={
                                        message === "Success"
                                          ? "success"
                                          : "error"
                                      }
                                      sx={{ mb: 2 }}
                                    >
                                      {orderPlacedError}
                                    </Alert>
                                  </Collapse>
                                </Box>
                              )
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className={styles.ModalFooter}>
                        <button
                          className={styles.checkout_btns_group_btn}
                          onClick={() => {
                            placeOrderAfterVerify();
                          }}
                          disabled={loadingVerify}
                        >
                          {loadingVerify ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>{t("Submit")}</span>
                          )}
                        </button>
                      </div>
                    </Box>
                  </Modal>
                  <Modal
                    className={styles.ModalPayment}
                    show={showPayment}
                    onHide={handleClosePayment}
                  >
                    <iframe
                      src={paymentUrl}
                      width={500}
                      height={900}
                      style={{ border: "none", borderRadius: "20px" }}
                      title="payment"
                    ></iframe>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.cart_body__right_products}>
            <div className={styles.cart_body__right_products__payment}>
              <div className={styles.cart_body__right_total}>
                <div className={styles.cart_body__right_total__header}>
                  {t("Payment Summary")}
                </div>
                <div className={styles.cart_divider}></div>

                <div className={styles.cart_body__right_total__fees}>
                  <div className={styles.cart_body__right_promocode}>
                    <input
                      type="text"
                      placeholder={t("Enter Promo Code")}
                      onChange={onChangePromo}
                      className={
                        styles.cart_body__right_promocode__input__field
                      }
                    />
                    <button
                      className={styles.cart_body__right_promocode__input__btn}
                      onClick={() => onSubmitPromo()}
                      disabled={loadingPromo}
                    >
                      {loadingPromo ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <span>{t("Add")}</span>
                      )}
                    </button>
                  </div>
                  <div className={styles.cart_divider}></div>
                  {loading ? (
                    [1, 2, 3].map((item) => (
                      <Skeleton
                        variant="rectangular"
                        key={item}
                        width={300}
                        height={50}
                        style={{ borderRadius: "20px", marginBottom: "20px" }}
                      />
                    ))
                  ) : (
                    <>
                      <TotalFees
                        label={t("Subtotal")}
                        value={`${
                          promoResponse?.data
                            ? Math.round(
                                promoResponse?.data?.total_amount * 100
                              ) / 100
                            : Math.round(
                                (deliveryFees?.data?.total_amount -
                                  deliveryFees?.data?.delivery_fees) *
                                  100
                              ) / 100
                        } ${t(" EUR")}`}
                      />
                      <TotalFees
                        label={t("Shipping Fees")}
                        value={`${
                          promoResponse?.data?.free_delivery
                            ? `${t("Free")}`
                            : deliveryFees?.data?.delivery_fees
                        } ${t(" EUR")}`}
                      />
                      <div className={styles.cart_divider}></div>
                      {/* {!promoError ? () : <p>{promoError}</p>} */}
                      <TotalFees
                        label={t("Total Taxes included")}
                        value={`${
                          promoResponse?.data
                            ? Math.round(promoResponse?.data?.amount * 100) /
                              100
                            : Math.round(
                                deliveryFees?.data?.total_amount * 100
                              ) / 100
                        } ${t(" EUR")}`}
                      />
                      {promoResponse ? (
                        promoResponse?.data?.discount ? (
                          <>
                            <div className={styles.cart_divider}></div>

                            <TotalFees
                              label={t("Promo Code")}
                              value={`- ${promoResponse?.data?.discount} ${t(
                                " EUR"
                              )}`}
                            />
                          </>
                        ) : promoError ? (
                          promoError !== "Success" ? (
                            <Box
                              sx={{
                                width: "100%",
                                borderRadius: "20px",
                                fontFamily: "unset",
                              }}
                             
                            >
                              <Collapse in={open}>
                                <Alert
                                  severity={
                                    promoError === "Success"
                                      ? "success"
                                      : "error"
                                  }
                                  sx={{ mb: 2 }}
                                  style={{
                                    fontFamily: "unset",
                                  }}
                                >
                                  {promoError}
                                </Alert>
                              </Collapse>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                borderRadius: "20px",
                                fontFamily: "unset",
                                ".MuiAlert-message": {
                                  fontFamily: "unset !important",
                                },
                              }}
                              style={{
                                fontFamily: "unset",
                              }}
                            >
                              <Collapse
                                in={open}
                                sx={{
                                  fontFamily: "unset",
                                }}
                                style={{
                                  fontFamily: "unset",
                                }}
                              >
                                <Alert
                                  severity={
                                    promoError === "Success"
                                      ? "success"
                                      : "error"
                                  }
                                  sx={{
                                    mb: 2,
                                    fontFamily: "unset",
                                  }}
                                  style={{
                                    fontFamily: "unset",
                                  }}
                                >
                                  {promoError === "Success"
                                    ? "Promo code applied successfully"
                                    : promoError}
                                </Alert>
                              </Collapse>
                            </Box>
                          )
                        ) : null
                      ) : null}
                    </>
                  )}
                  <ToastContainer />
                </div>
                <div className={styles.cart_credit}>
                  <img src={mastercard} alt="masterCard" />
                  <img src={visa} alt="visa" />
                </div>
              </div>
            </div>
            <div className={styles.cart_body__right_products__summary}>
              <div className={styles.cart_body__right_total__header}>
                {t("Products Summary")}
              </div>
              {loading
                ? [1, 2, 3].map((item) => (
                    <Skeleton
                      variant="rectangular"
                      key={item}
                      width={400}
                      height={100}
                      style={{ borderRadius: "20px", marginBottom: "20px" }}
                    />
                  ))
                : cartItems?.items?.map((item) => (
                    <>
                      <div className={styles.cart_body__right_products_product}>
                        <div className={styles.products__img}>
                          <img
                            src={item.image}
                            alt={item.name}
                            onError={(e) => {
                              e.target.src = placeholderImg;
                            }}
                          />
                        </div>
                        <div className={styles.products__details}>
                          <div className={styles.products__details_title}>
                            {document.body.dir === "rtl"
                              ? item.name_ar
                              : item.name}
                          </div>
                          <div className={styles.products__details_qty}>
                            {t("QTY")}
                            {item.amount}
                          </div>
                          <div className={styles.products__details_prices}>
                            <div
                              className={
                                styles.products__details_prices_newPrice
                              }
                            >
                              {item?.discount_price !== null || undefined
                                ? t("Exclusive Price") +
                                  ": " +
                                  item?.discount_price
                                : item.price + t("EUR")}
                            </div>
                            <div
                              className={
                                styles.products__details_prices_oldPrice
                              }
                            >
                              {item?.discount_price !== null || undefined
                                ? item?.price + t(" EUR")
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.cart_divider}></div>
                    </>
                  ))}
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default CheckoutPayment;
